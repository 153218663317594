import React, { useEffect, useState } from "react";
import { Navbar, Container } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import logo from "../../media/logo.png";
import dropDownIcon from "../../media/dropdown-icon.png";
import Footer from "../Pages/Footer";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { useDispatch, useSelector } from "react-redux";
import { waitingPatientList } from "../../redux/actions/doctorActions";
import { getUserDetails, logout } from "../../redux/actions/userActions";
import cross from "../../media/close.png";
import moment from "moment";
import ScrollButton from "../ScrollButton/ScrollButton";
import { jsPDF } from "jspdf";
import Swal from "sweetalert2";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 100,
    backgroundColor: "#D2D2D2",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      paddingTop: "7px",
      paddingBottom: "0px",
    },
  },
}));

const PatientWaitingList = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const history = useHistory();

  const [sessionId, setSessionId] = useState(null);
  console.log("sessionId", sessionId);

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { error, user } = userDetails;
  console.log("user", user);
  console.log("errorrrr", error);

  const locationset = useLocation();

  useEffect(() => {
    if (error === "Request failed with status code 401") {
      history.push("/login");
      localStorage.setItem("url", locationset.pathname);
      window.location.reload();
    } else {
    }
  }, [error]);

  useEffect(() => {
    dispatch(getUserDetails());
  }, []);

  if (user) {
    localStorage.setItem("name", user.Name);
  }

  const patientList = useSelector((state) => state.waitingPatientList);
  const { loading, waitingPatient } = patientList;
  console.log("waitingPatient", waitingPatient);

  useEffect(() => {
    dispatch(waitingPatientList);
  }, []);

  const checkDoctor = useSelector((state) => state.userLogin);
  const { userInfo } = checkDoctor;
  console.log("isDoctor", userInfo.isDoctor);

  const handleSebaClick = () => {
    console.log("clicked****", sessionId);
    localStorage.setItem("service", "doctor_service");
  };

  const logoutHandler = () => {
    console.log("logout");
    dispatch(logout());
    history.push("/");
  };

  const handleDelete = (id, e) => {
    console.log("delete", id);
    let formData = new FormData();
    formData.append("Meeting_Status", "Canceled");
    fetch(
      `https://api-care-box.click/api/v2/Online_Doctor_Booking/book_doctor/put_view/${id}/`,
      {
        method: "PUT",
        body: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data) {
          e.target.parentNode.parentNode.parentNode.parentNode.parentNode.style.display =
            "none";
          Swal.fire({
            position: "top",
            icon: "success",
            title: "Deleted Successfully",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        if (err) {
          Swal.fire({
            position: "top",
            icon: "error",
            title: "Something went wrong!",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      });
  };

  // useEffect(()=>{
  //   fetch('https://api-care-box.click/api/v2/Online_Doctor_Booking/doctorAppointments/',{
  //     headers: {
  //       // "Content-Type": "application/json",
  //       Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  //       "Custom-User-Agent":
  //         "15!@ejh46)(*%#!@s4h68a4rgsagH&^%%$#@!JKFKVYRDTgjsgakjzghfjJ%$#@#%HFYD32434",
  //     },
  //   }).then(response=>response.json()).then(data=>console.log("patient info", data))
  // },[])

  const handleDownload = (id) => {
    console.log(id);
    let data = waitingPatient.filter((item) => item.id === id)[0];
    console.log("data", data);
    // waitingPatient.map((item)=>(
    //   item.Report_and_Prescriptions[0].Report_or_Prescription
    // ))

    const pres = data.Report_and_Prescriptions[0].Report_or_Prescription;
    console.log(pres);

    // const pres2 = pres.slice(0, 43);
    // console.log(pres2);

    const pdf = new jsPDF(
      "p",
      "pt"
      //   {
      //   orientation: "vertical",
      //   unit: "in",
      //   format: [8, 4],
      // }
    );
    let pageWidth = pdf.internal.pageSize.getWidth();
    let pageHeight = pdf.internal.pageSize.getHeight();

    // pdf.addImage(pres, "PNG", pageWidth/2.5, 20, 120, 80,)

    if (pres === null) {
      Swal.fire({
        position: "top",
        icon: "error",
        title: "Oops...",
        text: "No Prescription",
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      pdf.addImage(pres, "JPEG", 20, 20, pageWidth / 1.08, pageHeight);
      pdf.save("prescription.pdf");
    }

    // if (data.Report_and_Prescriptions) {
    //   pdf.addImage(
    //     data.Report_and_Prescriptions[0].Report_or_Prescription,
    //     "JPEG",
    //     20,
    //     20,
    //     pageWidth / 1.08,
    //     pageHeight
    //   );
    //   pdf.save("prescription.pdf");
    // } else {
    //   Swal.fire({
    //     position: "top",
    //     icon: "error",
    //     title: "Oops...",
    //     text: "No Prescription",
    //     showConfirmButton: false,
    //     timer: 2000,
    //   });
    // }
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      {userInfo.isDoctor === true ? (
        <>
          <div className="headerBox2">
            <Navbar>
              <Container>
                <Navbar.Brand>
                  <img
                    style={{ cursor: "pointer" }}
                    src={logo}
                    onClick={() => history.push("/")}
                    alt="logo"
                    fluid
                    id="logo"
                  />
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                  <Navbar.Text>
                    <p style={{ fontWeight: "bold" }}>
                      স্বাগতম, {localStorage.getItem("name")}
                      {/* স্বাগতম, সিফাত{" "} */}
                      <img
                        src={dropDownIcon}
                        alt="dropdown"
                        // style={{ height: "17px", marginTop: "-5px" }}
                        fluid
                        id="drowdownImg"
                        // id="demo-customized-button"
                        // aria-controls="demo-customized-menu"
                        // aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        // variant="contained"
                        // disableElevation
                        onClick={handleClick}
                      />
                      <StyledMenu
                        className="dropdown_container"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                      >
                        {/* <MenuItem onClick={handleClose} disableRipple>
                          <button className="menu_button">প্রোফাইল</button>
                        </MenuItem>
                        <MenuItem onClick={handleClose} disableRipple>
                          <button className="menu_button">ড্যাশবোর্ড</button>
                        </MenuItem> */}
                        <MenuItem onClick={handleClose} disableRipple>
                          <button className="menu_button">
                            অপেক্ষমান রোগী
                          </button>
                        </MenuItem>
                        <MenuItem onClick={handleClose} disableRipple>
                          <button
                            onClick={() =>
                              history.push("/treatmentDescription")
                            }
                            className="menu_button"
                          >
                            প্রদত্ত সেবার ডাটা
                          </button>
                        </MenuItem>
                        <MenuItem onClick={handleClose} disableRipple>
                          <button
                            onClick={logoutHandler}
                            className="menu_button"
                          >
                            লগ আউট
                          </button>
                        </MenuItem>
                      </StyledMenu>
                    </p>
                  </Navbar.Text>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </div>

          <br></br>
          <br></br>

          <div className="doctor_portal_login">
            <h3>ডাক্তার পোর্টাল</h3>
          </div>

          <br></br>
          <br></br>
          <br></br>

          <div className="d-flex justify-content-center">
            <div className="col-md-9 waiting_patient_text">
              <h6 style={{ textAlign: "left", fontWeight: "bold" }}>
                অপেক্ষমান রোগীর তালিকা
              </h6>
              <div className="patient_waitingList_card_container">
                <div className="patient_waitingList_card_content">
                  <div className="col-12">
                    <div className="d-flex justify-content-between align-items-center treatment_desc_header">
                      <div id="id" className="col-1">
                        <h6>আইডি</h6>
                      </div>
                      <div id="name" className="col-2">
                        <h6>রোগীর নাম</h6>
                      </div>
                      <div id="remove" className="col-2">
                        <h6>সমস্যার বিবরণ</h6>
                      </div>
                      <div id="date" className="col-2">
                        <h6>তারিখ এবং সময়</h6>
                      </div>
                      <div id="action" className="col-4">
                        <h6>অ্যাকশন</h6>
                      </div>
                    </div>
                  </div>

                  {waitingPatient.message ? (
                    <div className="waitingList_info_container">
                      <div className="col-12">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="col-12 mt-2">
                            <h3>আপাতত রোগীর সংখ্যা শূন্য. . .</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {waitingPatient.map((item) => (
                        <div className="waitingList_info_container">
                          <div className="col-12">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="col-1">
                                <h6>{item.id}</h6>
                              </div>
                              <div className="col-2">
                                <h6>{item.Patient_Name}</h6>
                              </div>
                              <div id="remove" className="col-2">
                                <h6>{item.Health_Issue}</h6>
                                <button
                                  className="download_button"
                                  onClick={() => handleDownload(item.id)}
                                >
                                  Download
                                </button>
                              </div>
                              <div className="col-2">
                                <h6>
                                  {moment(item.Appointment_Date).format(
                                    "MMM Do YYYY, h:mm a"
                                  )}
                                </h6>
                              </div>
                              <div className="col-4">
                                <div className="d-flex justify-content-evenly">
                                  <button
                                    // onClick={handleSebaClick}
                                    value={sessionId}
                                    onClick={() =>
                                      setSessionId(item.id) ||
                                      localStorage.setItem("service", item.id)
                                    }
                                  >
                                    <a
                                      style={{
                                        textDecoration: "none",
                                        color: "black",
                                      }}
                                      href="/videochat"
                                    >
                                      সেবা দিন
                                    </a>
                                  </button>
                                  <button
                                    onClick={(e) => handleDelete(item.id, e)}
                                  >
                                    বাতিল করুন
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <Footer></Footer>
          <ScrollButton />
        </>
      ) : (
        <>
          <Navbar>
            <Container>
              <Navbar.Brand>
                <img
                  src={logo}
                  onClick={() => history.push("/")}
                  alt="logo"
                  fluid
                  style={{ height: "80px" }}
                />
              </Navbar.Brand>
              <Navbar.Toggle />
              <Navbar.Collapse className="justify-content-end">
                <Navbar.Text>
                  <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                    স্বাগতম, {localStorage.getItem("name")}
                    <img
                      src={dropDownIcon}
                      alt="dropdown"
                      style={{ height: "17px", marginTop: "-5px" }}
                      fluid
                      onClick={handleClick}
                    />
                    <StyledMenu
                      className="dropdown_container"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={handleClose} disableRipple>
                        <button onClick={logoutHandler} className="menu_button">
                          লগ আউট
                        </button>
                      </MenuItem>
                    </StyledMenu>
                  </p>
                </Navbar.Text>
              </Navbar.Collapse>
            </Container>
          </Navbar>

          <br />
          <br />
          <div style={{ marginTop: "50px" }}>
            <img
              src={cross}
              alt="cross"
              style={{ height: "100px", marginBottom: "20px" }}
            />
            <h1>
              আপনি নিবন্ধিত ডাক্তার না
              <br /> দয়া করে ডাক্তার হিসেবে{" "}
              <span
                style={{ color: "blue", cursor: "pointer" }}
                onClick={() => history.push("/doctorPortalHome")}
              >
                নিবন্ধন
              </span>{" "}
              করুন
            </h1>
          </div>
        </>
      )}
    </div>
  );
};

export default PatientWaitingList;

import React, { useRef, useEffect, useState } from "react";
import AgoraRTC from "agora-rtc-sdk";
import "./videoChat.css";
import { connect, useDispatch, useSelector } from "react-redux";
// import { setMainStream, updateUser } from "../../store/actioncreator";
import {
  setMainStream,
  updateUser,
} from "../../redux/actions/OnlineAppoinmentDoctorAction";
import { getPatientView } from "../../redux/actions/patientDetailAction";
import OrderSuccessModal from "../PatientPortal/OrderSuccessModal";
import PresSuccessModal from "../MainScreen/PresSuccessModal";
import Swal from "sweetalert2";
import MeetingFooter from "../MeetingFooter/MeetingFooter.component";
import VideochatFooter from "./VideochatFooter";
import roomId from "../../server/firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophone,
  faVideo,
  faDesktop,
  faVideoSlash,
  faMicrophoneSlash,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import ReviewModal from "../Pages/ReviewModal";

const VideoChat = () => {
  const dispatch = useDispatch();
  const patientList = useSelector((state) => state);
  const { loading, waitingPatient } = patientList;
  console.log("waitingPatient****", patientList);

  const [reviewModalIsOpen, setReviewIsOpen] = useState(false);

  function openReviewModal() {
    setReviewIsOpen(true);
  }

  function closeReviewModal() {
    setReviewIsOpen(false);
  }

  let formData = new FormData();
  formData.append("Book_Doctor", localStorage.getItem("service"));
  formData.append("Access_Id", window.location.href);

  useEffect(() => {
    fetch(
      "https://api-care-box.click/api/v2/Patient_profile/PatientQueueUpdateAccessId/",
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Custom-User-Agent":
            "15!@ejh46)(*%#!@s4h68a4rgsagH&^%%$#@!JKFKVYRDTgjsgakjzghfjJ%$#@#%HFYD32434",
        },
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((data) => console.log(data));
  }, []);

  const [vitals, setVitals] = useState("");
  const [investigation, setInvestigation] = useState("");
  const [pres, setPres] = useState("");
  console.log(vitals, investigation, pres);

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const [presLoading, setPresLoading] = useState(false);
  const [presInfo, setPresInfo] = useState("");

  const handleSubmit = (e) => {
    // setPresLoading(true);
    let form_data = new FormData();
    form_data.append("Book_Doctor", localStorage.getItem("service"));
    form_data.append("Patient_Vitals", vitals);
    form_data.append("Investigations", investigation);
    form_data.append("Prescribed_Medicines", pres);

    fetch(
      `https://api-care-box.click/api/v2/Online_Doctor_Booking/Prescription_Details/Doctor/`,
      {
        method: "POST",
        body: form_data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.mesaage === "Prescription posted successfully") {
          console.log("Prescription data", data);
          setPresLoading(false);
          setPresInfo(data.mesaage);
          localStorage.setItem("prescription_id", data.prescription_id);
          // alert("PRESCRIPTION SUBMITTED!!!");
          Swal.fire({
            position: "top",
            icon: "success",
            title: "Prescription Submitted",
            showConfirmButton: false,
            timer: 2000,
          });

          // openModal();
        } else {
          setPresLoading(false);
          console.log("error", data);
          // alert("PRESCRIPTION NOT SUBMITTED!!!");
          Swal.fire({
            position: "top",
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      });
    e.preventDefault();
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("Patient_Vitals", vitals);
    formData.append("Investigations", investigation);
    formData.append("Prescribed_Medicines", pres);
    fetch(
      `https://api-care-box.click/api/v2/Online_Doctor_Booking/prescription_update/${localStorage.getItem(
        "prescription_id"
      )}/`,
      {
        method: "PUT",
        body: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    )
      .then((response) => {
        response.json();
        // alert("PRESCRIPTION UPDATED!!!");
        Swal.fire({
          position: "top",
          icon: "success",
          title: "Prescription Updated",
          showConfirmButton: false,
          timer: 2000,
        });
      })
      .then((data) => console.log("data", data));
  };

  //agora video chat
  //agora video chat
  //agora video chat

  const [audio, setAudio] = useState(false);
  console.log("audio", audio);

  const [isVideoMuted, setVideoMute] = useState(false);
  const [isAudioMuted, setAudioMute] = useState(false);

  // Handle errors.
  let handleError = function (err) {
    console.log("Error: ", err);
  };

  // Remove the video stream from the container.
  function removeVideoStream(elementId) {
    let remoteDiv = document.getElementById(elementId);
    if (remoteDiv) remoteDiv.parentNode.removeChild(remoteDiv);
  }

  let client = AgoraRTC.createClient({
    mode: "rtc",
    codec: "vp8",
  });

  client.init(
    // "308ff2c093a2435ba892fa16034d7728", //care-box
    // "81b6832337aa4f8c899ff49be135c6fb", //carebox app-id
    "880495af6ff344cda435975beb81d916", //carebox production
    function () {
      console.log("client initialized");
    },
    function (err) {
      console.log("client init failed ", err);
    }
  );

  // const urlparams = new URLSearchParams(window.location.search);
  // const roomId = urlparams.get("id");
  let channel = window.location.search;

  const [localStream, setStreamState] = useState(
    AgoraRTC.createStream({
      audio: true,
      video: true,
    })
  );

  useEffect(() => {
    // Join a channel
    client.join(
      null, //token
      // "carebox", //channel must need to be same
      channel, //dynamic channel for particular patient
      null, //UID
      (uid) => {
        // Create a local stream
        // let localStream = AgoraRTC.createStream({
        //   audio: true,
        //   video: true,
        // });

        // Initialize the local stream
        localStream.init(() => {
          // Play the local stream
          localStream.play("me");
          // Publish the local stream
          client.publish(localStream, handleError);
        }, handleError);
      },
      handleError
    );

    // Subscribe to the remote stream when it is published
    client.on("stream-added", function (evt) {
      client.subscribe(evt.stream, handleError);
    });

    // Add video streams to the container.
    function addVideoStream(elementId) {
      // Creates a new div for every stream
      let streamDiv = document.createElement("div");
      // Assigns the elementId to the div.
      streamDiv.id = elementId;
      // Takes care of the lateral inversion
      streamDiv.style.transform = "rotateY(180deg)";
      //   Query the container to which the remote stream belong.
      const remoteContainer = document.getElementById("remote-container");
      // console.log("remote containerrrr", remoteContainer);
      // Adds the div to the container.
      remoteContainer.appendChild(streamDiv);
    }

    // Play the remote stream when it is subsribed
    client.on("stream-subscribed", function (evt) {
      let stream = evt.stream;
      let streamId = String(stream.getId());
      addVideoStream(streamId);
      stream.play(streamId);
    });

    // Remove the corresponding view when a remote user unpublishes.
    client.on("stream-removed", function (evt) {
      let stream = evt.stream;
      let streamId = String(stream.getId());
      stream.close();
      removeVideoStream(streamId);
    });

    // Remove the corresponding view when a remote user leaves the channel.
    client.on("peer-leave", function (evt) {
      let stream = evt.stream;
      let streamId = String(stream.getId());
      stream.close();
      removeVideoStream(streamId);
    });
  }, []);

  const handleLeave = () => {
    client.leave();
    if (localStorage.getItem("service")) {
      let formData = new FormData();
      formData.append("Meeting_Status", "Completed");
      fetch(
        `https://api-care-box.click/api/v2/Online_Doctor_Booking/book_doctor/put_view/${localStorage.getItem(
          "service"
        )}/`,
        {
          method: "PUT",
          body: formData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data.message === "successfully updated") {
            window.location.href = `/patientWaitingList`;
          }
        });
      localStorage.removeItem("prescription_id");
    } else {
      openReviewModal();
    }
  };

  const muteVideo = () => {
    var img = document.createElement("img");
    img.src = "http://www.google.com/intl/en_com/images/logo_plain.png";
    const local = document.getElementById("me");
    const remote = document.getElementById("remote-container");

    if (isVideoMuted) {
      setVideoMute(false);
      setStreamState((currentState) => {
        return {
          ...currentState,
          video: localStream.unmuteVideo(),
        };
      });
      // local.removeChild(img);
      // remote.removeChild(img);
    } else {
      localStream.muteVideo();
      setVideoMute(true);
      // local.appendChild(img);
      // remote.appendChild(img);
      console.log("current stream", localStream);
    }
  };

  const muteAudio = () => {
    if (isAudioMuted) {
      setAudioMute(false);
      setStreamState((currentState) => {
        return {
          ...currentState,
          audio: localStream.unmuteAudio(),
        };
      });
    } else {
      setAudioMute(true);
      localStream.muteAudio();
    }
  };

  return (
    <div>
      {localStorage.getItem("service") ? (
        <>
          <div className="row justify-content-center m-auto">
            <div className="col-sm-12 col-md-6 ">
              <div id="me"></div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div id="remote-container"></div>
            </div>
          </div>

          <div className="doctor_prescription_form">
            {localStorage.getItem("prescription_id") ? (
              <form onSubmit={handleUpdate}>
                {presLoading ? (
                  <>
                    <div class="spinner-border text-primary" role="status">
                      <span class="sr-only"></span>
                    </div>
                  </>
                ) : (
                  <>
                    <h5>Patient Vitals:</h5>
                    <textarea
                      name="prescription"
                      id=""
                      cols="50"
                      // rows="4"
                      type="text"
                      required
                      onChange={(e) => setVitals(e.target.value)}
                    ></textarea>

                    <h5>Investigations:</h5>
                    <textarea
                      name="prescription"
                      id=""
                      cols="50"
                      // rows="4"
                      type="text"
                      required
                      onChange={(e) => setInvestigation(e.target.value)}
                    ></textarea>

                    <h5>Prescribed Medicine:</h5>
                    <textarea
                      name="prescription"
                      id=""
                      cols="50"
                      rows="4"
                      type="text"
                      required
                      onChange={(e) => setPres(e.target.value)}
                    ></textarea>

                    {presInfo ? (
                      <>
                        <button
                          type="submit"
                          // onClick={handleUpdate}
                          style={{
                            backgroundColor: "#FFB903",
                            borderRadius: "5px",
                            border: "1px solid #FFB903",
                          }}
                        >
                          আপডেট করুন
                        </button>
                        <PresSuccessModal
                          modalIsOpen={modalIsOpen}
                          closeModalForSuccess={closeModal}
                        ></PresSuccessModal>
                      </>
                    ) : (
                      <>
                        <button
                          type="submit"
                          style={{
                            backgroundColor: "#FFB903",
                            borderRadius: "5px",
                            border: "1px solid #FFB903",
                          }}
                        >
                          সেভ করুন
                        </button>
                      </>
                    )}
                  </>
                )}
              </form>
            ) : (
              <form onSubmit={handleSubmit}>
                {presLoading ? (
                  <>
                    <div class="spinner-border text-primary" role="status">
                      <span class="sr-only"></span>
                    </div>
                  </>
                ) : (
                  <>
                    <h5>Patient Vitals:</h5>
                    <textarea
                      name="prescription"
                      id=""
                      cols="50"
                      // rows="4"
                      type="text"
                      required
                      onChange={(e) => setVitals(e.target.value)}
                    ></textarea>

                    <h5>Investigations:</h5>
                    <textarea
                      name="prescription"
                      id=""
                      cols="50"
                      // rows="4"
                      type="text"
                      required
                      onChange={(e) => setInvestigation(e.target.value)}
                    ></textarea>

                    <h5>Prescribed Medicine:</h5>
                    <textarea
                      name="prescription"
                      id=""
                      cols="50"
                      rows="4"
                      type="text"
                      required
                      onChange={(e) => setPres(e.target.value)}
                    ></textarea>

                    {presInfo ? (
                      <>
                        <button
                          type="submit"
                          // onClick={handleUpdate}
                          style={{
                            backgroundColor: "#FFB903",
                            borderRadius: "5px",
                            border: "1px solid #FFB903",
                          }}
                        >
                          আপডেট করুন
                        </button>
                        <PresSuccessModal
                          modalIsOpen={modalIsOpen}
                          closeModalForSuccess={closeModal}
                        ></PresSuccessModal>
                      </>
                    ) : (
                      <>
                        <button
                          type="submit"
                          style={{
                            backgroundColor: "#FFB903",
                            borderRadius: "5px",
                            border: "1px solid #FFB903",
                          }}
                        >
                          সেভ করুন
                        </button>
                      </>
                    )}
                  </>
                )}
              </form>
            )}
          </div>
          {/* </div> */}

          <div className="footer">
            {/* <VideochatFooter></VideochatFooter> */}
            <div className="meeting-footer">
              <div className={"meeting-icons"} onClick={muteAudio}>
                <FontAwesomeIcon
                  icon={isAudioMuted ? faMicrophoneSlash : faMicrophone}
                  title="Mute"
                />
              </div>
              <div className={"meeting-icons"} onClick={muteVideo}>
                <FontAwesomeIcon icon={isVideoMuted ? faVideoSlash : faVideo} />
              </div>

              <div
                className="meeting-icons"
                style={{ backgroundColor: "#ea4335" }}
                onClick={handleLeave}
              >
                <FontAwesomeIcon icon={faPhone} />
                <ReviewModal
                  modalIsOpen={reviewModalIsOpen}
                  closeModal={closeReviewModal}
                ></ReviewModal>
              </div>
              <ReactTooltip />
            </div>
          </div>
          <br />
          <h3 style={{ textAlign: "center", fontWeight: "400" }}>
            সঠিক ভাবে সেবা দিতে হেডফোন ব্যবহার করুন{" "}
          </h3>
        </>
      ) : (
        <>
          <div className="row justify-content-center m-auto">
            <div className="col-sm-12 col-md-6 ">
              <div id="me"></div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div id="remote-container"></div>
            </div>
          </div>

          <div className="footer">
            {/* <VideochatFooter></VideochatFooter> */}
            <div className="meeting-footer">
              <div className={"meeting-icons"} onClick={muteAudio}>
                <FontAwesomeIcon
                  icon={isAudioMuted ? faMicrophoneSlash : faMicrophone}
                  title="Mute"
                />
              </div>
              <div className={"meeting-icons"} onClick={muteVideo}>
                <FontAwesomeIcon icon={isVideoMuted ? faVideoSlash : faVideo} />
              </div>

              <div
                className="meeting-icons"
                style={{ backgroundColor: "#ea4335" }}
                onClick={handleLeave}
              >
                <FontAwesomeIcon icon={faPhone} />
                <ReviewModal
                  modalIsOpen={reviewModalIsOpen}
                  closeModal={closeReviewModal}
                ></ReviewModal>
              </div>
              <ReactTooltip />
            </div>
          </div>
          <br />
          <h3 style={{ textAlign: "center", fontWeight: "400" }}>
            সঠিক ভাবে সেবা নিতে হেডফোন ব্যবহার করুন{" "}
          </h3>
        </>
      )}
      {/* <VideochatFooter></VideochatFooter> */}
      {/* {abc()} */}
      {/* <StreamPlayer stream={localStream} fit="contain" autoChange={false} /> */}
    </div>
  );
};

export default VideoChat;

import React, { useEffect } from "react";
import { Navbar } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import logo from "../../media/logo.png";
import Footer from "../Pages/Footer";
import "./FooterContent.css";
import "aos/dist/aos.css";
import Aos from "aos";

const ReturnPolicy = () => {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);
  return (
    <div>
      <Navbar className="footer_navbar">
        <Navbar.Brand style={{ margin: "0 0 0 0" }}>
          <img src={logo} onClick={() => history.push("/")} alt="logo" fluid />
        </Navbar.Brand>
      </Navbar>
      <div className="about_us">
        <h2 data-aos="fade-up">Return Policy</h2>
        <p data-aos="fade-up">
          If there are any problems in our website or app, then don’t worry. We
          are here to help.
        </p>
        <br />
        <h3 data-aos="fade-up">For Doctors</h3>
        <br />
        <h4 data-aos="fade-up">Doctor Registration</h4>
        <p data-aos="fade-up">
          We have mentioned in our Terms and Conditions, that the doctor
          registration fee is non-refundable. After you have applied for Doctor
          registration, we will investigate your documents and check if you are
          an enlisted doctor by checking your BMDC number. If you are unable to
          provide your BMDC number, then we will not onboard you as a doctor.
          Moreover, if we find out that your qualifications are not upto our
          required standards, then we will not onboard you as a doctor. In these
          cases, your application to register as a doctor will be rejected and
          we will not refund the money.
        </p>
        <br />
        <h4 data-aos="fade-up">Doctor Renewal</h4>
        <p data-aos="fade-up">
          Every year, a doctor can renew their membership with us. It is
          completely upto a doctor, whether he wants to renew or not. The
          renewal fee is 100Tk. If a doctor renews and then decides that he
          would not continue as a doctor, then he is free to do so. However, we
          will not be refunding the renewal fee. The renewal fee is also
          non-refundable as per our company policy.
        </p>
        <br />
        <h3 data-aos="fade-up">For Patients</h3>
        <p data-aos="fade-up">
          A patient is entitled to refunds in certain cases. If a patient is
          unable to join a session, for any reason, then we will not provide him
          with any refunds or a free session. However, if there is an
          interruption in the service, which is caused by us, then we will
          provide a free session or a refund (Depends on the patient’s choice).
          If, for some reason, a doctor misses a session, then we will refund
          the session fee or schedule another session (free of cost).
        </p>
        <br />
        <h4 data-aos="fade-up">Refunds</h4>
        <p data-aos="fade-up">
          A patient, if eligible for refund, will be given a full 100% refund.
          Moreover, the patient will be given the refund within 7 working days.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default ReturnPolicy;

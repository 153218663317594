import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Navbar } from "react-bootstrap";
import logo from "../../media/logo.png";
import "./Landing.css";
import axios from "axios";
import { useHistory } from "react-router";
import Search from "./Search";

const HeaderBox = () => {
  return (
    <div className="headerBoxHome">
      <Search></Search>
    </div>
  );
};

export default HeaderBox;

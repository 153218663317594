import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Button } from "@material-ui/core";
import { useState } from "react";
import "./profile.css";
import axios from "axios";
import OtpLoader from "../Login/OtpLoader";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className="table_data">
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowDownIcon style={{ color: "black" }} />
            ) : (
              <KeyboardArrowUpIcon style={{ color: "black" }} />
            )}
          </IconButton>
        </TableCell>
        <TableCell align="center">{row.id}</TableCell>
        <TableCell align="center">{row.Patient_Name}</TableCell>
        <TableCell align="center">{row.Patient_Phone}</TableCell>
        <TableCell align="center">{row.Amount}</TableCell>
        <TableCell align="center" className="table_button">
          {row.Order_Status == "pending" ? (
            <Button variant="contained">Pending</Button>
          ) : row.Order_Status == "delivered" ? (
            <Button
              variant="contained"
              color="primary"
              style={{ fontWeight: "bold" }}
            >
              Delivered
            </Button>
          ) : row.Order_Status == "confirmed" ? (
            <Button
              variant="contained"
              style={{
                backgroundColor: "#34eb7a",
                color: "white",
                fontWeight: "bold",
              }}
            >
              Confirmed
            </Button>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              style={{ fontWeight: "bold" }}
            >
              Cancelled
            </Button>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                অর্ডার বিবরণ
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>
                      ঔষধ সমূহ
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{row.Prescribed_Medicines}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function UserHistory() {
  const [loading, setLoading] = useState(true);
  console.log(loading);

  const [myOrder, setMyOrder] = useState(null);
  console.log("jhamela22222", myOrder);

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    };
    fetch(
      `https://api-care-box.click/api/v2/Online_Doctor_Booking/Order_With_Prescription_Get_view/`,
      { headers }
    )
      .then((response) => response.json())
      .then((data) => {
        setMyOrder(data);
        setLoading(false);
      });
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow className="profile_order_header">
            <TableCell />
            <TableCell align="center">অর্ডার আইডি</TableCell>
            <TableCell align="center">রোগীর নাম</TableCell>
            <TableCell align="center">নাম্বার</TableCell>
            <TableCell align="center">মোট টাকা(৳)</TableCell>
            <TableCell align="center">স্ট্যাটাস</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading === false ? (
            <>
              {myOrder.detail ? (
                // <div style={{textAlign:"right"}}></div>
                <TableRow>
                  <TableCell />
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">
                    Please, book an appointment first...
                  </TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              ) : (
                <>
                  {myOrder.message ? (
                    <TableRow>
                      <TableCell />
                      <TableCell align="center"></TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">No Orders Yet...</TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  ) : (
                    myOrder.map((row) => <Row key={row.id} row={row} />)
                  )}
                </>
              )}
            </>
          ) : (
            <OtpLoader></OtpLoader>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import React, { useEffect, useState } from "react";
import font from "../../media/fonts/Poppins-Regular.ttf";
import logo from "../../media/logo.png";
import footerImg from "../../media/docicon.png";

import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  PDFDownloadLink,
  LinearGradient,
  Font,
} from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";

Font.register({
  family: "Poppins",
  src: font,
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    fontSize: 11,
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    lineHeight: 1,
    flexDirection: "column",
    minHeight: "100vh",
    fontFamily: "Poppins",
  },
  row: {
    flexDirection: "row",
    width: "100%",
  },
  column: {
    flexDirection: "column",
    width: "50%",
    paddingRight: 15,
    paddingLeft: 15,
  },
  footerLeft: {
    height: 90,
    width: 220,
    marginTop: 10,
    textAlign: "left",
  },
  footerColumnLeft: {
    flexDirection: "column",
    width: "30%",
    padding: 0,
    margin: 0,
  },
  footerColumnRight: {
    flexDirection: "column",
    width: "70%",
    padding: 0,
    marginTop: 30,
    lineHeight: 1.4,
  },
  header: {
    height: 30,
    paddingLeft: 25,
    paddingRight: 25,
    backgroundColor: "#900C3F",
    color: "#fff",
    fontSize: 24,
    borderRadius: 7,
    // marginTop: 15,
    alignContent: "center",
    alignItems: "center",
    alignSelf: "center",
    textAlign: "center",
  },
  logo: {
    // width: 100,
    height: 80,
    marginLeft: "auto",
    // marginRight: 10,
    marginTop: -10,
    alignContent: "center",
    alignItems: "center",
    alignSelf: "center",
    textAlign: "center",
    // flexDirection: "row",
    // justifyContent: "flex-end",
  },
  section: {
    // margin: 10,
    // padding: 10,
    fontSize: 13,
    // flexGrow: 1,
    lineHeight: 1.5,
  },
  box: {
    border: "2 solid black",
    flexDirection: "row",
    paddingTop: 5,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 10,
  },
  textUnderline: {
    fontSize: 13,
    textDecoration: "underline",
  },
  // footer: {
  //   minHeight: `calc(100vh - 200px)`,
  //   position: "fixed",
  //   bottom: 0,
  // },
});

const PrescriptionPDF = (props) => {
  // const { id } = props;
  console.log("id", props.id);

  const [docData, setDocData] = useState({});

  const [prescriptionDetail, setPrescriptionDetail] = useState([]);
  console.log("pres Detail", prescriptionDetail);

  useEffect(() => {
    fetch(
      "https://api-care-box.click/api/v2/Online_Doctor_Booking/Prescription_Details/Patient/",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => setPrescriptionDetail(data));
  }, []);

  // let data = prescriptionDetail.filter((item) => item.Book_Doctor === id)[0];

  // if (data) {
  //   setDocData(data);
  // }

  const doctorName = docData.Doctor_Name;
  const patientName = docData.Patient_Name;
  const investigations = docData.Investigations;
  const vitals = docData.Patient_Vitals;
  const treatment = docData.Prescribed_Medicines;
  const presId = docData.Book_Doctor;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={{ minHeight: "86vh" }}>
          <View style={styles.row} fixed>
            <View style={styles.header}>
              <Text style={{ paddingTop: "-2px" }}>PRESCRIPTION</Text>
            </View>
            <Image style={styles.logo} src={logo} />
          </View>
          <View style={styles.section} fixed>
            <Text>Prescribed By: {doctorName}</Text>
            <Text>Prescription ID: {presId}</Text>
          </View>

          <View style={{ marginTop: "10px" }} fixed></View>

          <View style={styles.box} fixed>
            <Text style={{ marginLeft: "5px", marginRight: "35px" }}>
              Patient Name: {patientName}
            </Text>
            <Text style={{ marginRight: "35px" }}>Age: 25</Text>
            <Text style={{ marginRight: "35px" }}>Gender: Female</Text>
            <Text style={{ marginRight: "5px" }}>Date: 28-04-2022</Text>
          </View>
          <View style={{ marginTop: "20px" }} fixed></View>

          <View style={{ overflow: "hidden" }}>
            <View style={styles.row}>
              <View style={styles.column}>
                <View style={{ borderRight: "1px solid black" }}>
                  <Text style={styles.textUnderline}>Health Issue:</Text>
                  <Text
                    style={{
                      marginBottom: "10px",
                      padding: "10px",
                      lineHeight: 1.3,
                    }}
                  >
                    {vitals}
                  </Text>

                  <Text style={styles.textUnderline}>Patient Vitals:</Text>
                  <Text
                    style={{
                      marginBottom: "10px",
                      padding: "10px",
                      lineHeight: 1.3,
                    }}
                  >
                    {vitals}
                  </Text>

                  <Text style={styles.textUnderline}>Investigations:</Text>
                  <Text
                    style={{
                      marginBottom: "10px",
                      padding: "10px",
                      lineHeight: 1.3,
                    }}
                  >
                    {investigations}
                  </Text>
                </View>
              </View>
              <View style={styles.column}>
                <Text style={styles.textUnderline}>Treatments:</Text>
                <Text
                  style={{
                    marginBottom: "10px",
                    padding: "10px",
                    lineHeight: 1.3,
                  }}
                >
                  {treatment}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View fixed>
          <View style={styles.row}>
            <View style={styles.footerColumnLeft}>
              <Image style={styles.footerLeft} src={footerImg} />
            </View>
            <View style={styles.footerColumnRight}>
              <View style={{ textAlign: "right" }}>
                <Text style={{ fontSize: "14px", color: "#cf155f" }}>
                  Care-Box Limited
                </Text>
                <Text style={{ fontSize: "9px" }}>
                  149/A, Monipuri para, Baitush Sharaf Complex
                </Text>
                <Text style={{ fontSize: "9px" }}>
                  (Ground floor), Airport road, Farmgate, Tejgaon, Dhaka-1215,
                  Bangladesh
                </Text>
                <Text style={{ fontSize: "9px" }}>
                  +8801886020709, +8801796020709, +880248116644
                </Text>
                <Text style={{ fontSize: "9px" }}>WWW.CARE-BOX.COM</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PrescriptionPDF;

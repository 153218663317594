import React, {useState} from 'react';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import './ScrollButton.css';

const ScrollButton = () =>{

const [visible, setVisible] = useState(false)

const toggleVisible = () => {
	const scrolled = document.documentElement.scrollTop;
	if (scrolled > 500){
		setVisible(true)
	}
	else if (scrolled <= 500){
		setVisible(false)
	}
};

const scrollToTop = () =>{
	window.scrollTo({
	top: 0,
	behavior: 'smooth'
	/* you can also use 'auto' behaviour
		in place of 'smooth' */
	});
};

window.addEventListener('scroll', toggleVisible);

return (
	<div className="back-to-top" onClick={scrollToTop} style={{display: visible ? 'inline' : 'none'}} >
		<button>
			<ArrowUpwardIcon  style={{fontSize: "31px", color: "#6C0534"}} />
		</button>
	</div>
);
}

export default ScrollButton;

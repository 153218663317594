import React, { useEffect } from "react";
import logo from "../../media/logo.png";
import Footer from "../Pages/Footer";
import success from "../../media/succ-01.png";
import check from "../../media/checked.png";
import "./payment.css";

const PaymentSuccess = () => {
  useEffect(() => {
    let interval = null;
    interval = setInterval(function () {
      localStorage.removeItem("ssl");
      window.location.href = "/";
    }, 3000);
    return () => (interval ? clearInterval(interval) : null);
  }, []);
  return (
    <div>
      {/* <Logo /> */}
      <img
        src={logo}
        alt="logo"
        className="loading_logo"
        style={{ height: "100px" }}
      />
      <br></br>
      <div className="schedule_container">
        <div className="success_doctor_left">
          <h1>Congratulations!</h1>
          <img src={success} alt="success_logo" />
        </div>
        <div className="success_doctor_right">
          <img src={check} alt="check sign" />
          <br></br>
          <h6>
            Your application has been successfully placed and
            <br></br>
            one of our executives will call you shortly.
          </h6>
        </div>
      </div>
      <br></br>
      <Footer />
    </div>
  );
};

export default PaymentSuccess;

import { Rating } from "@mui/material";
import React, { useEffect } from "react";
import Stack from "@mui/material/Stack";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import {
  createDoctorReview,
  listDoctorDetails,
} from "../../redux/actions/doctorDetailAction";
import "./ReviewModal.css";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { useRef } from "react";

const ReviewModal = (props) => {
  const { closeModal, modalIsOpen } = props;
  console.log(props);
  // console.log('closed',modalIsOpen);
  // console.log(modalIsOpen)
  // const { id } = useParams();
  const id = localStorage.getItem("id");
  console.log("review id", id);
  const history = useHistory();
  const dispatch = useDispatch();

  const [rate, setRating] = React.useState(0);
  const [comment, setComment] = React.useState("");
  const [hover, setHover] = React.useState(-1);

  // useEffect(() => {
  //   // dispatch(listDoctorDetails(id));
  // }, []);

  const doctorDetail = useSelector((state) => state);
  // const doctorDetail = useSelector((state) => state.doctorDetails);
  const { doctor } = doctorDetail;
  console.log(doctorDetail);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (localStorage.getItem("access_token")) {
      // dispatch(createDoctorReview(id, rate, comment));

      let form_data = new FormData();
      form_data.append("Doctor_Profile", id);
      form_data.append("Review_Description", comment);
      form_data.append("Rating", rate);

      fetch(
        `https://api-care-box.click/api/v2/live_doctor/post_doctor_review/`,
        {
          method: "POST",
          body: form_data,
          headers: {
            "Custom-User-Agent":
              "15!@ejh46)(*%#!@s4h68a4rgsagH&^%%$#@!JKFKVYRDTgjsgakjzghfjJ%$#@#%HFYD32434",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => console.log("review data", data));
      // history.push("/patientPortal");
      window.location.href = "/patientPortal";
    } else {
      window.location.href = "/login";
      // history.push("/login");
    }
  };

  const handleSkipReview = (e) => {
    window.location.href = "/patientPortal";
    // history.push("/patientPortal");
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className="modalStyle"
      contentLabel="Example Modal"
    >
      <div className="col-md-12">
        <div className="d-flex justify-content-center align-items-center">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <h5 className="feedback_text">ফিডব্যাক ফরম পূরণ করুনঃ</h5>
            <form onSubmit={handleSubmit} className="modal_form">
              <Stack spacing={1} className="rating">
                <Rating
                  name="hover-feedback"
                  size="large"
                  // defaultValue={0}
                  value={rate}
                  precision={0.5}
                  onChange={(event, newValue) => setRating(newValue)}
                  onChangeActive={(event, newHover) => {
                    setHover(newHover);
                  }}
                  required
                  // readOnly
                />
              </Stack>
              <h6>বিবরণঃ</h6>
              <textarea
                rows="3"
                type="text"
                required
                onChange={(e) => setComment(e.target.value)}
              ></textarea>
              <div className="d-flex justify-content-center">
                <button type="submit">সেভ করুন</button>
                <button onClick={handleSkipReview}>স্কীপ করুন</button>
              </div>
            </form>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </Modal>
  );
};

export default ReviewModal;

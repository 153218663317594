import React, { useRef, useEffect, useState } from "react";
import MeetingFooter from "../MeetingFooter/MeetingFooter.component";
import Participants from "../Participants/Participants.component";
import "./MainScreen.css";
import { connect, useDispatch, useSelector } from "react-redux";
// import { setMainStream, updateUser } from "../../store/actioncreator";
import {
  setMainStream,
  updateUser,
} from "../../redux/actions/OnlineAppoinmentDoctorAction";
import { getPatientView } from "../../redux/actions/patientDetailAction";
import OrderSuccessModal from "../PatientPortal/OrderSuccessModal";
import PresSuccessModal from "./PresSuccessModal";
import Swal from "sweetalert2";

const MainScreen = (props) => {
  const dispatch = useDispatch();
  const patientList = useSelector((state) => state);
  const { loading, waitingPatient } = patientList;
  console.log("waitingPatient****", patientList);

  let formData = new FormData();
  formData.append("Book_Doctor", localStorage.getItem("service"));
  formData.append("Access_Id", window.location.href);

  useEffect(() => {
    fetch(
      "https://api-care-box.click/api/v2/Patient_profile/PatientQueueUpdateAccessId/",
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Custom-User-Agent":
            "15!@ejh46)(*%#!@s4h68a4rgsagH&^%%$#@!JKFKVYRDTgjsgakjzghfjJ%$#@#%HFYD32434",
        },
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((data) => console.log(data));
  });

  // useEffect(()=>{
  //   dispatch(getPatientView())
  // },[])

  const patientDetails = useSelector((state) => state.patientDetail);
  // const {loading, patientView} = patientDetails;
  // console.log(patientView)
  console.log(patientDetails);

  const participantRef = useRef(props.participants);

  const onMicClick = (micEnabled) => {
    if (props.stream) {
      props.stream.getAudioTracks()[0].enabled = micEnabled;
      props.updateUser({ audio: micEnabled });
    }
  };
  const onVideoClick = (videoEnabled) => {
    if (props.stream) {
      props.stream.getVideoTracks()[0].enabled = videoEnabled;
      props.updateUser({ video: videoEnabled });
    }
  };

  useEffect(() => {
    participantRef.current = props.participants;
  }, [props.participants]);

  const updateStream = (stream) => {
    for (let key in participantRef.current) {
      const sender = participantRef.current[key];
      if (sender.currentUser) continue;
      const peerConnection = sender.peerConnection
        .getSenders()
        .find((s) => (s.track ? s.track.kind === "video" : false));
      peerConnection.replaceTrack(stream.getVideoTracks()[0]);
    }
    props.setMainStream(stream);
  };

  const onScreenShareEnd = async () => {
    const localStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    });

    localStream.getVideoTracks()[0].enabled = Object.values(
      props.currentUser
    )[0].video;

    updateStream(localStream);

    props.updateUser({ screen: false });
  };

  const onScreenClick = async () => {
    let mediaStream;
    if (navigator.getDisplayMedia) {
      mediaStream = await navigator.getDisplayMedia({ video: true });
    } else if (navigator.mediaDevices.getDisplayMedia) {
      mediaStream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
      });
    } else {
      mediaStream = await navigator.mediaDevices.getUserMedia({
        video: { mediaSource: "screen" },
      });
    }

    mediaStream.getVideoTracks()[0].onended = onScreenShareEnd;

    updateStream(mediaStream);

    props.updateUser({ screen: true });
  };

  const [vitals, setVitals] = useState("");
  const [investigation, setInvestigation] = useState("");
  const [pres, setPres] = useState("");
  console.log(vitals, investigation, pres);

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const [presLoading, setPresLoading] = useState(false);
  const [presInfo, setPresInfo] = useState("");

  const handleSubmit = (e) => {
    // setPresLoading(true);
    let form_data = new FormData();
    form_data.append("Book_Doctor", localStorage.getItem("service"));
    form_data.append("Patient_Vitals", vitals);
    form_data.append("Investigations", investigation);
    form_data.append("Prescribed_Medicines", pres);

    fetch(
      `https://api-care-box.click/api/v2/Online_Doctor_Booking/Prescription_Details/Doctor/`,
      {
        method: "POST",
        body: form_data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.mesaage === "Prescription posted successfully") {
          console.log("Prescription data", data);
          setPresLoading(false);
          setPresInfo(data.mesaage);
          localStorage.setItem("prescription_id", data.prescription_id);
          // alert("PRESCRIPTION SUBMITTED!!!");
          Swal.fire({
            position: "top",
            icon: "success",
            title: "Prescription Submitted",
            showConfirmButton: false,
            timer: 2000,
          });

          // openModal();
        } else {
          setPresLoading(false);
          console.log("error", data);
          // alert("PRESCRIPTION NOT SUBMITTED!!!");
          Swal.fire({
            position: "top",
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      });
    e.preventDefault();
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("Patient_Vitals", vitals);
    formData.append("Investigations", investigation);
    formData.append("Prescribed_Medicines", pres);
    fetch(
      `https://api-care-box.click/api/v2/Online_Doctor_Booking/prescription_update/${localStorage.getItem(
        "prescription_id"
      )}/`,
      {
        method: "PUT",
        body: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    )
      .then((response) => {
        response.json();
        // alert("PRESCRIPTION UPDATED!!!");
        Swal.fire({
          position: "top",
          icon: "success",
          title: "Prescription Updated",
          showConfirmButton: false,
          timer: 2000,
        });
      })
      .then((data) => console.log("data", data));
  };

  return (
    <div className="wrapper">
      {localStorage.getItem("service") ? (
        <>
          {/* <div className="d-flex justify-content-center align-items-center"> */}
          {/* <div className="main-screen-doctor"> */}
          <Participants />
          {/* </div> */}
          <div className="doctor_prescription_form">
            {localStorage.getItem("prescription_id") ? (
              <form onSubmit={handleUpdate}>
                {presLoading ? (
                  <>
                    <div class="spinner-border text-primary" role="status">
                      <span class="sr-only"></span>
                    </div>
                  </>
                ) : (
                  <>
                    <h5>Patient Vitals:</h5>
                    <textarea
                      name="prescription"
                      id=""
                      cols="50"
                      // rows="4"
                      type="text"
                      required
                      onChange={(e) => setVitals(e.target.value)}
                    ></textarea>

                    <h5>Investigations:</h5>
                    <textarea
                      name="prescription"
                      id=""
                      cols="50"
                      // rows="4"
                      type="text"
                      required
                      onChange={(e) => setInvestigation(e.target.value)}
                    ></textarea>

                    <h5>Prescribed Medicine:</h5>
                    <textarea
                      name="prescription"
                      id=""
                      cols="50"
                      rows="4"
                      type="text"
                      required
                      onChange={(e) => setPres(e.target.value)}
                    ></textarea>

                    {presInfo ? (
                      <>
                        <button
                          type="submit"
                          // onClick={handleUpdate}
                          style={{
                            backgroundColor: "#FFB903",
                            borderRadius: "5px",
                            border: "1px solid #FFB903",
                          }}
                        >
                          আপডেট করুন
                        </button>
                        <PresSuccessModal
                          modalIsOpen={modalIsOpen}
                          closeModalForSuccess={closeModal}
                        ></PresSuccessModal>
                      </>
                    ) : (
                      <>
                        <button
                          type="submit"
                          style={{
                            backgroundColor: "#FFB903",
                            borderRadius: "5px",
                            border: "1px solid #FFB903",
                          }}
                        >
                          সেভ করুন
                        </button>
                      </>
                    )}
                  </>
                )}
              </form>
            ) : (
              <form onSubmit={handleSubmit}>
                {presLoading ? (
                  <>
                    <div class="spinner-border text-primary" role="status">
                      <span class="sr-only"></span>
                    </div>
                  </>
                ) : (
                  <>
                    <h5>Patient Vitals:</h5>
                    <textarea
                      name="prescription"
                      id=""
                      cols="50"
                      // rows="4"
                      type="text"
                      required
                      onChange={(e) => setVitals(e.target.value)}
                    ></textarea>

                    <h5>Investigations:</h5>
                    <textarea
                      name="prescription"
                      id=""
                      cols="50"
                      // rows="4"
                      type="text"
                      required
                      onChange={(e) => setInvestigation(e.target.value)}
                    ></textarea>

                    <h5>Prescribed Medicine:</h5>
                    <textarea
                      name="prescription"
                      id=""
                      cols="50"
                      rows="4"
                      type="text"
                      required
                      onChange={(e) => setPres(e.target.value)}
                    ></textarea>

                    {presInfo ? (
                      <>
                        <button
                          type="submit"
                          // onClick={handleUpdate}
                          style={{
                            backgroundColor: "#FFB903",
                            borderRadius: "5px",
                            border: "1px solid #FFB903",
                          }}
                        >
                          আপডেট করুন
                        </button>
                        <PresSuccessModal
                          modalIsOpen={modalIsOpen}
                          closeModalForSuccess={closeModal}
                        ></PresSuccessModal>
                      </>
                    ) : (
                      <>
                        <button
                          type="submit"
                          style={{
                            backgroundColor: "#FFB903",
                            borderRadius: "5px",
                            border: "1px solid #FFB903",
                          }}
                        >
                          সেভ করুন
                        </button>
                      </>
                    )}
                  </>
                )}
              </form>
            )}
          </div>
          {/* </div> */}

          <div className="footer">
            <MeetingFooter
              onScreenClick={onScreenClick}
              onMicClick={onMicClick}
              onVideoClick={onVideoClick}
            />
          </div>
          <br />
          <h3 style={{ textAlign: "center", fontWeight: "400" }}>
            সঠিক ভাবে সেবা দিতে হেডফোন ব্যবহার করুন{" "}
          </h3>
        </>
      ) : (
        <>
          <div className="main-screen">
            <Participants />
          </div>

          <div className="footer">
            <MeetingFooter
              onScreenClick={onScreenClick}
              onMicClick={onMicClick}
              onVideoClick={onVideoClick}
            />
          </div>
          <br />
          <h3 style={{ textAlign: "center", fontWeight: "400" }}>
            সঠিক ভাবে সেবা নিতে হেডফোন ব্যবহার করুন{" "}
          </h3>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    stream: state.mainStream,
    participants: state.participants,
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMainStream: (stream) => dispatch(setMainStream(stream)),
    updateUser: (user) => dispatch(updateUser(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainScreen);

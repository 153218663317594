import React, { useEffect } from "react";
import { Navbar } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import logo from "../../media/logo.png";
import Footer from "../Pages/Footer";
import "./FooterContent.css";
import "aos/dist/aos.css";
import Aos from "aos";

const TermsCondition = () => {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);
  return (
    <div>
      <Navbar className="footer_navbar">
        <Navbar.Brand style={{ margin: "0 0 0 0" }}>
          <img src={logo} onClick={() => history.push("/")} alt="logo" fluid />
        </Navbar.Brand>
      </Navbar>
      <div className="about_us">
        <h2 data-aos="fade-up">Terms and Condition</h2>
        <p data-aos="fade-up">
          The following terms and condition ("T&C") govern your access to and
          use of online doctor portal app/website. These terms and conditions
          create a contract between you and the online doctor portal. Please
          read these T&C carefully before you start using this platform. By
          clicking "Agree/Accept" you agree to be bound by these T&C at all
          times. Non-acceptance of the T&Cs shall disallow you to use the
          platform.
        </p>
        <br />
        <h4 data-aos="fade-up">1. Eligibility</h4>
        <p data-aos="fade-up" style={{paddingLeft:"10px"}}>
          1.1 Use of the Website and app is available only to such persons who
          can legally contract under the Contract Act, 1872.
          <br />
          <br />
          1.2 If you are a minor i.e. under the age of 18 years, you shall not
          register as a user of Online Doctor Portal and shall not transact on
          or use the Website. As a minor if you wish to use or transact on the
          website, such use or transaction may be made by your legal guardian or
          parents on the Website. We reserve the right to terminate your
          membership and / or refuse to provide you with access to the website
          if it is brought to Care-Box ’s notice or if it is discovered that you
          are under the age of 18 years.
          <br />
          <br />
          1.3 For Doctors, when you are registering for the first time, you need
          to pay a non-refundable fee of 100Tk. When you register, you need to
          provide the necessary certificates and we will gather your BMDC
          number. If our health officials deem you as a qualified doctor then we
          will take you onboard, or else your registration will be null and
          void.
        </p>
        <br />
        <h4 data-aos="fade-up">2. Registration</h4>
        <p data-aos="fade-up" style={{paddingLeft:"10px"}}>
          2.1 By registering with the website/app you agree to abide by the T&C
          at all times, to use the website/app in accordance with the laws of
          Bangladesh, and refrain from abusing the website/app in any way. You
          further acknowledge and agree that you would not access or use the
          website/app using any automated software.
          <br />
          <br />
          2.2 Attempts to breach security of the website/app on any computer or
          mobile network or access an account that does not belong to you on the
          website/app is strictly prohibited.
          <br />
          <br />
          2.3 To prevent fraud or abuse, only one registered account per device
          is allowed. Any attempt to create additional accounts shall be
          invalid.
          <br />
          <br />
          2.4 To seek a Doctor’s consultation, you would initiate a consultation
          on the App/Website . By initiating the consultation using the
          Services, you consent to the delivery of medical services by the
          Doctors using Telemedicine facilities on the Platform.
        </p>
        <br />
        <h4 data-aos="fade-up">3. Medical Services Disclaimer</h4>
        <p data-aos="fade-up" style={{paddingLeft:"10px"}}>
          3.1 You acknowledge and understand that the Platform is not suitable
          to be used as the sole consultation method in potential or actual
          medical emergencies.
          <br />
          <br />
          3.2 If a medical condition requires a particular protocol to diagnose
          and prescribe as in a case of in-person consult then the same
          prevailing principle will be applicable to a Telemedicine consult.
          <br />
          <br />
          3.3 You agree and acknowledge that any decisions or actions affecting
          your own health, your foetus, your child/ren, or your family’s health
          or safety is based on the advice you received from a Doctor, and the
          Doctor Portal does not guarantee or is responsible for the quality and
          accuracy of such advice.
        </p>
        <br />
        <h4 data-aos="fade-up">4. Copyright</h4>
        <p data-aos="fade-up">
          The material (including the content, and any other content, software
          or services) contained on Doctor Portal are the property of Carebox
          Limited, its subsidiaries, affiliates and/or third party licensors. No
          material on this site may be copied, reproduced, republished,
          installed, posted, transmitted, stored or distributed without written
          permission from Carebox Limited.
        </p>
        <br />
        <h4 data-aos="fade-up">5. Cookies</h4>
        <p data-aos="fade-up">
          This site uses cookies, which means that you must have cookies enabled
          on your computer in order for all functionality on this site to work
          properly. A cookie is a small data file that is written to your hard
          drive when you visit certain Websites. Cookie files contain certain
          information, such as a random number user ID that the site assigns to
          a visitor to track the pages visited. A cookie cannot read data off
          your hard disk or read cookie files created by other sites. Cookies,
          by themselves, cannot be used to find out the identity of any user.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default TermsCondition;

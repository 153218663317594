import React, { useEffect, useCallback, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import DoctorCard from "./DoctorCard";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { listDoctorAction } from "../../redux/actions/doctorActions";
import "./Landing.css";
import "./Arrow.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useHistory } from "react-router-dom";
import OtpLoader from "../Login/OtpLoader";
// import "~slick-carousel/slick/slick.css";
// import "~slick-carousel/slick/slick-theme.css";
import "aos/dist/aos.css";
import Aos from "aos";

const LandingDoctorList = () => {
  const dispatch = useDispatch();
  const doctorList = useSelector((state) => state.doctor);
  const { doctor, error } = doctorList;
  console.log("doctorList ****", doctor);

  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 100);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);

  useEffect(() => {
    dispatch(listDoctorAction);
  }, []);

  console.log(doctor);

  const settings = {
    dots: true,
    // infinite: doctor.data?.length > doctor.data?.length + 1,
    infinite: false,
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 2048,
        width: 400,
        settings: {
          slidesToShow: 3,
          arrows: true,
          slidesToScroll: 1,
          speed: 3000,
          autoplay: true,
          swipeToSlide: true,
          autoplaySpeed: 3000,
        },
      },
      {
        breakpoint: 1368,
        width: 400,
        settings: {
          slidesToShow: 3,
          arrows: true,
          slidesToScroll: 1,
          speed: 1000,
          autoplay: true,
          swipeToSlide: true,
          autoplaySpeed: 3000,
        },
      },
      {
        breakpoint: 1094,
        width: 400,
        settings: {
          slidesToShow: 2,
          arrows: true,
          slidesToScroll: 1,
          speed: 3000,
          autoplay: true,
          swipeToSlide: true,
          autoplaySpeed: 3000,
        },
      },
      {
        breakpoint: 600,
        width: 400,
        settings: {
          slidesToShow: 2,
          arrows: true,
          slidesToScroll: 1,
          speed: 3000,
          autoplay: true,
          swipeToSlide: true,
          autoplaySpeed: 3000,
        },
      },
      {
        breakpoint: 480,
        width: 400,
        settings: {
          slidesToShow: 1,
          arrows: true,
          slidesToScroll: 1,
          speed: 2000,
          autoplay: true,
          swipeToSlide: true,
          autoplaySpeed: 2000,
        },
      },
    ],
  };

  return (
    <div>
      <Container>
        <div className="doctor_title_flex">
          <div
            className="doctor_title_flex_left"
            style={{ fontWeight: "600" }}
            data-aos="fade-right"
          >
            মেডিসিন বিভাগ
          </div>
          <div
            className="doctor_title_flex_right"
            onClick={() => history.push("/medicine")}
            data-aos="fade-left"
          >
            সকল ডাক্তার দেখুন
          </div>
        </div>
        <br />
        {/* <div className="doctor_slider"> */}
        {doctor.data?.length ? (
          <Container className="gynae_container">
            <div className="doctor-main-container">
              <div className="">
                <Slider {...settings}>
                  {doctor.data
                    // ?.filter((doctors) => doctors.id === 5)
                    // ?.filter((doctors) => doctors.Rating === 5)
                    // ?.filter((doctors) => doctors.Department === "মেডিসিন")
                    ?.filter((doctors) => doctors.Department === "Medicine")
                    .map((eachDoctor) => {
                      return (
                        <DoctorCard key={eachDoctor.id} doctor={eachDoctor} />
                      );
                    })}
                </Slider>
              </div>
            </div>
          </Container>
        ) : (
          <OtpLoader></OtpLoader>
        )}
        {/* </div> */}
      </Container>
      <br />
      <br />
      <br />
      <Container>
        <div className="doctor_title_flex">
          <div
            className="doctor_title_flex_left"
            style={{ fontWeight: "600" }}
            data-aos="fade-right"
          >
            শিশুরোগ বিভাগ
          </div>
          <div
            className="doctor_title_flex_right"
            onClick={() => history.push("/Pediatrician")}
            data-aos="fade-left"
          >
            সকল ডাক্তার দেখুন
          </div>
        </div>
        <br />
        {doctor.data?.length ? (
          <Container className="gynae_container">
            <div className="">
              <div className="doctor-main-container">
                <Slider {...settings}>
                  {doctor.data
                    // ?.filter((doctors) => doctors.Department === "পেডিয়াট্রিক")
                    ?.filter((doctors) => doctors.Department === "Paediatrics")
                    .map((eachDoctor) => {
                      return (
                        <DoctorCard key={eachDoctor.id} doctor={eachDoctor} />
                      );
                    })}
                </Slider>
              </div>
            </div>
          </Container>
        ) : (
          <OtpLoader></OtpLoader>
        )}
      </Container>
      <br />
      <br />
      <br />
      <Container>
        <div className="doctor_title_flex">
          <div
            className="doctor_title_flex_left"
            style={{ fontWeight: "600" }}
            data-aos="fade-right"
          >
            গাঈণী বিভাগ
          </div>
          <div
            className="doctor_title_flex_right"
            onClick={() => history.push("/gynae")}
            data-aos="fade-left"
          >
            সকল ডাক্তার দেখুন
          </div>
        </div>
        <br />
        {doctor.data?.length ? (
          <Container className="gynae_container">
            <div className="">
              <div className="doctor-main-container">
                <Slider {...settings}>
                  {doctor.data
                    ?.filter(
                      (doctors) => doctors.Department === "Gynaecologist"
                      // (doctors) => doctors.Department === "স্ত্রীরোগ বিশেষজ্ঞ"
                    )
                    .map((eachDoctor) => {
                      return (
                        <DoctorCard key={eachDoctor.id} doctor={eachDoctor} />
                      );
                    })}
                </Slider>
              </div>
            </div>
          </Container>
        ) : (
          <OtpLoader></OtpLoader>
        )}
      </Container>
      <br />
      <br />
      <br />
      <Container>
        <div className="doctor_title_flex">
          <div
            className="doctor_title_flex_left"
            style={{ fontWeight: "600" }}
            data-aos="fade-right"
          >
            সাইকোলজি বিভাগ
          </div>
          <div
            className="doctor_title_flex_right"
            onClick={() => history.push("/psychology")}
            data-aos="fade-left"
          >
            সকল ডাক্তার দেখুন
          </div>
        </div>
        <br />
        {doctor.data?.length ? (
          <Container className="gynae_container">
            <div className="">
              <div className="doctor-main-container">
                <Slider {...settings}>
                  {doctor.data
                    ?.filter((doctors) => doctors.Department === "Psychologist")
                    // ?.filter((doctors) => doctors.Department === "সাইকোলজি")
                    .map((eachDoctor) => {
                      return (
                        <DoctorCard key={eachDoctor.id} doctor={eachDoctor} />
                      );
                    })}
                </Slider>
              </div>
            </div>
          </Container>
        ) : (
          <OtpLoader></OtpLoader>
        )}
      </Container>
      <br />
      <br />
      <br />
    </div>
  );
};

export default LandingDoctorList;

import React, { useEffect } from "react";
import logo from "../../media/logo.png";
import Footer from "../Pages/Footer";
import success from "../../media/succ-01.png";
import check from "../../media/checked.png";
import "./payment.css";

const BookingPaymentSuccess = () => {
  useEffect(() => {
    let interval = null;
    interval = setInterval(function () {
      localStorage.removeItem("ssl_book");
      //   localStorage.setItem("payment", "successfull");
      window.location.href = "/countdown";
    }, 3000);
    return () => (interval ? clearInterval(interval) : null);
  }, []);
  return (
    <div>
      <img
        src={logo}
        alt="logo"
        className="bookingPaymentSuccess_logo"
      />
      <br></br>
      <div className="bookingPaymentSuccess_schedule_container">
        <div className="bookingPaymentSuccess_doctor_left">
          <h1>Congratulations!</h1>
          <img src={success} alt="success_logo" />
        </div>
        <div className="bookingPaymentSuccess_doctor_right">
          <img src={check} alt="check sign" />
          <br></br>
          <h6>
            Your booking has been successfully placed.
            <br></br>
            Please wait for doctor...
          </h6>
        </div>
      </div>
      <br></br>
      <Footer />
    </div>
  );
};

export default BookingPaymentSuccess;

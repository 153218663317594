import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../Pages/Footer";
import HeaderBox from "../Pages/HeaderBox";
import doctorRegImage from "../../media/doctorRegImage.png";
import { Container, Row, Col, Navbar, Form, Button } from "react-bootstrap";
import logo from "../../media/logo.png";
import banner from "../../media/cover-01.png";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import "../Pages/Landing.css";
import { createDoctor } from "../../redux/actions/doctorRegistrationAction";
import { getUserDetails } from "../../redux/actions/userActions";

const DoctorRegForm = () => {
  const history = useHistory();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [nid, setNid] = useState("");
  const [degree, setDegree] = useState("");
  const [bmdc, setBmdc] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [time, setTime] = useState("");
  const [week, setWeek] = useState("");
  const [referal, setReferal] = useState("");

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { error, user } = userDetails;
  console.log("user", user);
  console.log("errorrrr", error);

  const locationset = useLocation();

  useEffect(() => {
    if (error === "Request failed with status code 401") {
      history.push("/login");
      localStorage.setItem("url", locationset.pathname);
      window.location.reload();
    } else {
    }
  }, [error]);

  const amount = 150;
  const isPostedFrom = "Website";

  useEffect(() => {
    dispatch(getUserDetails());
  }, []);

  if (user) {
    var userId = user.id;
    // var userId = 161;
    console.log(userId);
    localStorage.setItem("name", user.Name);
  }

  // useEffect(() => {
  //   if (localStorage.getItem("registerd") === "done") {
  //     dispatch(getUserDetails());
  //     localStorage.setItem("name", user.Name);
  //   } else {
  //     history.push("/login");
  //   }
  // }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      createDoctor(
        userId,
        name,
        phone,
        email,
        nid,
        degree,
        bmdc,
        speciality,
        time,
        week,
        referal,
        isPostedFrom
      )
    );
  };

  return (
    <div>
      <div className="headerBox_doctor_portal_home">
        <div className="search_design">
          <div>
            <img
              onClick={() => history.push("/")}
              className="landingLogo1"
              src={logo}
              alt="logo"
              fluid
            />
          </div>
          <div></div>
        </div>
      </div>
      <br></br>

      <div className="doctor-reg">
        <div>
          <img src={doctorRegImage}></img>
        </div>
        <div className="doctor-reg-form">
          <form onSubmit={handleSubmit}>
            <h4>ডাক্তার আবেদন ফরমঃ</h4>
            <p>আপনার নামঃ</p>
            <input
              type="text"
              required
              placeholder=""
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <br />
            <p>মোবাইল নাম্বার:</p>
            <input
              type="text"
              placeholder=""
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <br />
            <p>ইমেলঃ</p>
            <input
              type="text"
              placeholder=""
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <br />
            <p>জাতীয় পরিচয়-পত্র / পাসপোর্ট নাম্বারঃ</p>
            <input
              required
              type="text"
              placeholder=""
              value={nid}
              onChange={(e) => setNid(e.target.value)}
            />
            <br />
            <p>শিক্ষাগত যোগ্যতাঃ</p>
            <input
              type="text"
              placeholder=""
              value={degree}
              onChange={(e) => setDegree(e.target.value)}
            />
            <br />
            <p>বি এম ডি সি নাম্বারঃ</p>
            <input
              type="text"
              placeholder=""
              value={bmdc}
              onChange={(e) => setBmdc(e.target.value)}
            />
            <br />
            <p>বিশেষত্বঃ</p>
            <input
              type="text"
              placeholder=""
              value={speciality}
              onChange={(e) => setSpeciality(e.target.value)}
            />
            <br />
            <p>আপনার সেবার সময়সূচীঃ </p>
            <input
              type="text"
              placeholder="I.E. 5pm - 8pm"
              value={time}
              onChange={(e) => setTime(e.target.value)}
            />
            <br />
            <p>সেবার দিন সমূহঃ </p>
            <input
              type="text"
              placeholder="I.E. Sun-Mon-Thurs"
              value={week}
              onChange={(e) => setWeek(e.target.value)}
            />
            <br />
            <p>রেফারেন্সঃ</p>
            <input
              type="text"
              value={referal}
              onChange={(e) => setReferal(e.target.value)}
            />
            <br />
            <Button
              style={{ marginLeft: "100px", fontWeight: "bold" }}
              variant="warning"
              type="submit"
              // onClick={handleDoctorReg}
            >
              আবেদন করুন
            </Button>
          </form>
        </div>
      </div>
      <br></br>
      <Footer></Footer>
    </div>
  );
};

export default DoctorRegForm;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Navbar } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import logo from "../../media/logo.png";
import OtpLoader from "../Login/OtpLoader";
import Footer from "../Pages/Footer";
import ParticularDoctorCard from "./ParticularDoctorCard";

const SearchDoctor = () => {
  const { keyword } = useParams();
  console.log(keyword);
  const exactKey = keyword.slice(9);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [doctors, setDoctors] = useState([]);
  console.log(doctors);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(6);
  const history = useHistory();

  useEffect(() => {
    loadDoctors();
  }, []);

  const loadDoctors = () => {
    setLoading(true);
    axios
      .get(
        `https://api-care-box.click/api/v2/live_doctor/search-doctor-by-department/?keyword=${exactKey}&limit=${limit}&offset=${offset}`
      )
      .then((res) => {
        const newDoctors = res.data.Doctors;
        const hasMore = res.data.has_more;
        setHasMore(hasMore);
        setLoading(false);
        setDoctors([...doctors, ...newDoctors]);
        setOffset(offset + limit);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  };

  window.onscroll = () => {
    if (error || loading || !hasMore)
      return console.log("i am error, loading, has not more");
    else {
      loadDoctors();
    }
  };

  return (
    <div>
      <div className="container d-flex justify-content-start pt-4">
        <div>
          <img
            onClick={() => history.push("/")}
            className="landingLogo1"
            src={logo}
            alt="logo"
            fluid
          />
        </div>
      </div>

      <Container>
        <div className="d-flex justify-content-center particularDoctor_main">
          {doctors.length ? (
            <div className="particularDoctor_main_container row">
              {doctors.map((eachDoctor) => {
                return (
                  <ParticularDoctorCard
                    key={eachDoctor.id}
                    doctors={eachDoctor}
                  />
                );
              })}
            </div>
          ) : (
            <OtpLoader></OtpLoader>
          )}
        </div>
      </Container>
      <br />
      {hasMore && (
        <div
          style={{ fontSize: "18px", fontWeight: "600", textAlign: "center" }}
        >
          scroll to load more
        </div>
      )}
      {error && (
        <div
          style={{ fontSize: "18px", fontWeight: "600", textAlign: "center" }}
        >
          error: {error}
        </div>
      )}
      {loading && (
        <div
          style={{ fontSize: "18px", fontWeight: "600", textAlign: "center" }}
        >
          loading....
        </div>
      )}
      {!hasMore && (
        <div
          style={{ fontSize: "18px", fontWeight: "600", textAlign: "center" }}
        ></div>
      )}
      <Footer />
    </div>
  );
};

export default SearchDoctor;

import React, { useEffect } from "react";
import logo from "../../media/logo.png";
import Footer from "../Pages/Footer";
import fail1 from "../../media/Fail-01.png";
import cross from "../../media/x-button.png";
import "./payment.css";

const BookingPaymentFailure = () => {

    useEffect(() => {
      let interval = null;
      interval = setInterval(function () {
        localStorage.removeItem("ssl_book");
        window.location.href = `/DoctorForm/${localStorage.getItem("id")}`;
      }, 3000);
      return () => (interval ? clearInterval(interval) : null);
    }, []);
  return (
    <div>
      <img
        src={logo}
        alt="logo"
        className="bookingPaymentFailure_logo"
      />
      <br></br>
      <div className="bookingPaymentFailure_schedule_container">
        <div className="bookingPaymentFailure_doctor_left">
          <h1>Opps!</h1>
          <img src={fail1} />
        </div>
        <div className="bookingPaymentFailure_doctor_right">
          <img src={cross} />
          <br></br>
          <h6>
            Your appointment has not placed.
            <br></br>
            Please try the same procedure again.
          </h6>
        </div>
      </div>
      <br></br>
      <Footer />
    </div>
  );
};

export default BookingPaymentFailure;

import React, { useEffect, useState } from "react";
import { Navbar, Container, Row, Col, Form, Button } from "react-bootstrap";
import { useHistory } from "react-router";
import { useParams, useLocation } from "react-router-dom";
import Rating from "@mui/material/Rating";
import Footer from "./Footer";
import Stack from "@mui/material/Stack";
import logo from "../../media/logo.png";
import { useDispatch, useSelector } from "react-redux";
import {
  createDoctorAppointment,
  listDoctorDetails,
  checkIsPatient,
} from "../../redux/actions/doctorDetailAction";
import OtpLoader from "../Login/OtpLoader";
import ReviewModal from "./ReviewModal";
import { getPatientView } from "../../redux/actions/patientDetailAction";
import { getUserDetails } from "../../redux/actions/userActions";

const DoctorForm = (props) => {
  // const {Image, Name, Designation, Department, Appointment_Time_Range, Fees, Background} = props.formDoctor;
  // console.log("props", props);

  const { id } = useParams();

  localStorage.setItem("id", id);

  const [patientName, setPatientName] = useState("");
  const [patientPhone, setPatientPhone] = useState("");
  const [patientAge, setPatientAge] = useState("");
  const [patientGender, setPatientGender] = useState("");
  const [patientHealthIssue, setPatientHealthIssue] = useState("");
  const [patientPrescription, setPatientPrescription] = useState([]);

  const dispatch = useDispatch();
  const doctorDetail = useSelector((state) => state.doctorDetails);
  const { doctor } = doctorDetail;
  console.log("doctorDetailss * * *", doctor);

  // const doctorID = (doctor[0]).id;
  // console.log(doctorID);

  // const userDetails = useSelector((state) => state.userDetails);
  // const { user } = userDetails;
  // const userId = user.id;
  // console.log('user',user);

  useEffect(() => {
    dispatch(listDoctorDetails(id));
  }, []);

  useEffect(() => {
    dispatch(getPatientView);
  }, []);

  const patientDetails = useSelector((state) => state.patientViewGet);
  const { loading, patientView } = patientDetails;
  console.log(patientView);
  console.log(patientDetails);

  const userDetails = useSelector((state) => state.userDetails);
  const { error, user } = userDetails;
  console.log("user", user);
  console.log("errorrr", error);

  const locationset = useLocation();

  useEffect(() => {
    if (error === "Request failed with status code 401") {
      history.push("/login");
      localStorage.setItem("url", locationset.pathname);
      window.location.reload();
    } else {
    }
  }, [error]);

  useEffect(() => {
    dispatch(getUserDetails());
  }, []);

  if (user) {
    var user_id = user.id;
    console.log(user_id);
    localStorage.setItem("name", user.Name);
  }

  // useEffect(() => {
  //   dispatch(getUserDetails());
  //   localStorage.setItem("name", user.Name);
  // }, []);

  // useEffect(() => {
  //   if (localStorage.getItem("registerd") === "done") {
  //     dispatch(getUserDetails());
  //     localStorage.setItem("name", user.Name);
  //   } else {
  //     history.push("/login");
  //   }
  // }, []);

  const siam = useSelector((state) => state);
  console.log("siam", siam);

  // const patientViewName = patientView.Name;

  const history = useHistory();
  const HandleClickSession = (e) => {
    e.preventDefault();
    history.push("/countdown");
  };

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleSubmit = (e) => {
    const docID = doctor.id;
    console.log("docID", docID);
    e.preventDefault();

    var isPostedFrom = "Website";

    dispatch(checkIsPatient(user_id));

    dispatch(
      createDoctorAppointment(
        patientName,
        patientPhone,
        patientAge,
        patientGender,
        docID,
        patientHealthIssue,
        patientPrescription,
        isPostedFrom
      )
    );
  };

  const postDoctorAppointment = useSelector(
    (state) => state.createDoctorAppointment
  );
  // console.log("postDoctorAppointment", postDoctorAppointment.doctorAppointment?.message)

  // if (
  //   postDoctorAppointment.doctorAppointment?.message ===
  //   "Session booked successfully"
  // ) {
  //   history.push("/countdown");
  // } else {
  // }

  const handleFileChange = (e) => {
    const newFile = e.target.files[0];
    setPatientPrescription(newFile);
  };

  return (
    <div>
      <div className="doctorForm_headerbox">
        <Navbar>
          <Container>
            <Navbar.Brand href="/">
              <img src={logo} alt="logo" fluid />
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text>
                <p style={{ fontWeight: "900" }}>
                  স্বাগতম, {localStorage.getItem("name")}
                </p>
              </Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <br />
      <br />
      <br />
      <div>
        <Container>
          <Row>
            <Col className="online_appoinment_form" md={4}>
              <Form onSubmit={handleSubmit}>
                <h3>ফরম পূরণ করুনঃ</h3>
                <br />
                <h4>রোগীর নাম:</h4>
                <input
                  type="text"
                  placeholder=""
                  value={patientName}
                  onChange={(e) => setPatientName(e.target.value)}
                  required
                />
                <br />
                <h4>মোবাইল নাম্বার:</h4>
                <input
                  type="text"
                  placeholder=""
                  value={patientPhone}
                  onChange={(e) => setPatientPhone(e.target.value)}
                  required
                />
                <br />
                <h4>রোগীর বয়স:</h4>
                <input
                  type="text"
                  placeholder=""
                  value={patientAge}
                  onChange={(e) => setPatientAge(e.target.value)}
                  required
                />
                <br />

                <h4>আপনি কি পুরুষ/মহিলা/অন্যান্য: </h4>
                <div className="form-check form-check-inline">
                  <input
                    name="group1"
                    id="Male"
                    type="radio"
                    className="form-check-input"
                    value={patientGender}
                    onChange={(e) => setPatientGender(e.target.id)}
                  />
                  <label title className="form-check-label">
                    পুরুষ
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    name="group1"
                    id="Female"
                    type="radio"
                    className="form-check-input"
                    value={patientGender}
                    onChange={(e) => setPatientGender(e.target.id)}
                  />
                  <label title className="form-check-label">
                    মহিলা
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    name="group1"
                    id="Other"
                    type="radio"
                    className="form-check-input"
                    value={patientGender}
                    onChange={(e) => setPatientGender(e.target.id)}
                  />
                  <label title className="form-check-label">
                    অন্যান্য
                  </label>
                </div>

                <br />
                <h4>সমস্যার বিবরণ:</h4>
                <textarea
                  name=""
                  id=""
                  rows="3"
                  value={patientHealthIssue}
                  onChange={(e) => setPatientHealthIssue(e.target.value)}
                ></textarea>
                <br />
                <h4>প্রেসক্রিপশন/ রিপোর্ট আপলোড করুনঃ</h4>
                <label class="custom-file-upload">
                  <input
                    type="file"
                    // value={patientPrescription}
                    onChange={handleFileChange}
                  />
                  আপলোড
                </label>
                <br />
                <br />
                <button
                  // onClick={HandleClickSession}
                  type="submit"
                >
                  সেশন বুক করুন
                </button>
              </Form>
            </Col>
            <Col style={{ zIndex: "-1" }} md={4}></Col>
            {/* {doctor.length ? ( */}
            <Col className="doctorForm_info" md={4}>
              <br />
              <div>
                <img
                  className="each_doctor_image"
                  src={doctor.Image}
                  alt="doctor name"
                />
              </div>
              <br />
              <h2 className="doctorForm_info_name">{doctor.Name}</h2>
              <p className="doctorForm_info_designation">
                {doctor.Designation}
              </p>

              {doctor.bmdc_no ? (
                <p className="doctorForm_info_designation">
                  বিএমডিসি নম্বর: {doctor.bmdc_no}
                </p>
              ) : (
                <></>
              )}

              <Stack spacing={1} className="doctorForm_rating">
                <Rating
                  name="size-small"
                  size="small"
                  defaultValue={4.5}
                  value={doctor.Rating}
                  precision={0.5}
                  readOnly
                />
              </Stack>
              <p className="doctorForm_info_time">
                সময়ঃ <span>{doctor.Appointment_Time_Range}</span>
              </p>
              <button className="doctorForm_info_fees">
                চার্জঃ {doctor.Fees} টাকা
              </button>
              <h4 className="doctorForm_info_session">
                (প্রতি সেশনঃ ২৫ মিনিট)
              </h4>
              {/* <button onClick={openModal}>Rate the Doctor</button> */}
              <ReviewModal
                modalIsOpen={modalIsOpen}
                closeModal={closeModal}
              ></ReviewModal>
              <br />
            </Col>
            {/* ) : (
              <OtpLoader></OtpLoader>
            )} */}
          </Row>
        </Container>
      </div>
      <br />
      <Footer />
    </div>
  );
};

export default DoctorForm;

import React, { useEffect } from "react";
import { Navbar } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import logo from "../../media/logo.png";
import Footer from "../Pages/Footer";
import "./FooterContent.css";
import "aos/dist/aos.css";
import Aos from "aos";

const PrivacyPolicy = () => {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);
  return (
    <div>
      <Navbar className="footer_navbar">
        <Navbar.Brand style={{ margin: "0 0 0 0" }}>
          <img src={logo} onClick={() => history.push("/")} alt="logo" fluid />
        </Navbar.Brand>
      </Navbar>
      <div className="about_us">
        <h2 data-aos="fade-up">Privacy Policy</h2>
        <p data-aos="fade-up">
          This Privacy Policy summarises the health information the Doctor
          portal and Doctors may collect or receive in connection with your use
          of the portal, and describes how the personal data such as personal
          information, health information, medical records from time to time,
          may be used and shared, and your choices regarding this data.
          <br />
          <br />
          The Online Doctor Portal constitutes a technology platform that
          facilitates healthcare and health-related services by Registered
          Medical the Doctor (“the Doctor” or “Medical Service Provider”) to
          patients, with the assistance of healthcare workers/professional, if
          any, using information and communication technologies (“the
          Services”). The Doctors who deliver Services are independent medical
          practitioners registered with the Bangladesh Medical and Dental
          Council, and they are under professionally responsibility to maintain
          strict confidentiality of the health information. Any health data or
          information related to a patient, such as patient’s history, physical
          findings, information about disease risks and information about the
          actual physiological or biomedical state of an individual independent
          of its source, investigation, medical data, medical images, medical,
          pathological or investigation reports, lab reports and/or radiological
          investigations, medication, diagnosis, health education, counselling,
          treatment clinical progress and supplementary data; handwritten,
          printed or electronically generated or audio and visual recordings
          (“Health Information”) shall be covered by the Privacy Policy.
        </p>
        <br />
        <h4 data-aos="fade-up">How do we use and disclose health information?</h4>
        <p data-aos="fade-up">
          We may use and disclose your health information for normal business
          activities and in case of any emergency situation related to you that
          the law sees as falling in the categories of patient management,
          payment and healthcare operations. Below we provide examples of those
          activities, although not every use or disclosure falling within each
          category is listed:
          <br />
          <br />
          <p data-aos="fade-up" style={{ paddingLeft: "10px" }}>
            1. Patient Management - We keep a record of the Health Information
            you provide us. This record may include your test results,
            diagnoses, medications, your response to medications or other
            therapies, and information we learn about your medical condition
            through the online Services. We may disclose this information so
            that other doctors, nurses, and entities such as laboratories can
            meet your healthcare needs.
            <br />
            <br />
            2. Health care Operations - Health information is used to improve
            the services we provide, to train staff, for business management,
            quality assessment and improvement, and for customer service. For
            example, we may use your health information to review our treatment
            and services and to evaluate the performance of Doctors providing
            services to you.
            <br />
            <br />
            3. Video Call - The consultation is provided by the doctor on a
            video call. The video call is encrypted by 256 bits encryption
            method. Therefore, only the doctor and the patient can see the
            video. We do not record the video call. However, we may take
            screenshots of the video call to keep a proof of the consultation.
            These screenshots are securely stored in our server and will only be
            used if a question is raised by the patient about the quality of the
            consultation, or to prove whether a consultation has happened at
            all.
          </p>
        </p>
        <br />
        <h4 data-aos="fade-up">Medical Records Inspection</h4>
        <p data-aos="fade-up">
          As per the laws prevailing in Bangladesh and if required by Guidelines
          and Code of Conduct, patients may have the following options:
          <br />
          <br />
          <p data-aos="fade-up" style={{ paddingLeft: "10px" }}>
            1. Request amendment of your health information if you feel the
            health information is incorrect or incomplete. However, under
            certain circumstances, we may deny your request.
            <br />
            <br />
            2. Request that we communicate with you at a specific telephone
            number or address.
            <br />
            <br />
            3. Request deletion of personal information through written
            instruction.
          </p>
        </p>
        <br />
        <h4 data-aos="fade-up">Security</h4>
        <p data-aos="fade-up">
          After collecting your data, we store it on a secure server. Moreover,
          the server is protected by firewalls. Our security procedures mean
          that we may occasionally request proof of identity before we disclose
          personal information to you.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import "./Search.css";
import logo from "../../media/logo.png";

const Search = () => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const history = useHistory();
  const [keyword, setKeyword] = useState("");
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(3);

  useEffect(() => {
    SearchData();
  }, [keyword]);

  const handledown = () => {
    setData([]);
    setOffset(0);
  };

  const SearchData = () => {
    setLoading(true);
    if (keyword.length > 1) {
      console.log("outside axios keyword:", keyword);
      axios
        .get(
          `https://api-care-box.click/api/v2/live_doctor/search-doctor-by-department/?keyword=${keyword}&limit=${limit}&offset=${offset}`
        )
        .then((res) => {
          console.log("whole: ", res.data);
          console.log("offset: ", offset);
          console.log("limit: ", limit);
          const newdata = res.data.Doctors;
          const hasMoreapi = res.data.has_more;
          setHasMore(hasMoreapi);
          setLoading(false);
          setData([...data, ...newdata]);
          setOffset(offset + limit);
        })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        });
    }
    if (keyword.length < 1) {
      setData([]);
    }
  };

  const DivScroll = () => {
    if (error || loading || !hasMore)
      return console.log("i am error, loading, has not more");
    else {
      // call some loading method
      console.log("call from documentElement");
      SearchData();
    }
  };

  const SearchSubmit = (e) => {
    e.preventDefault();
    history.push(`/search-doctor/:keyword=${keyword}`);
  };

  // outside click
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setData([]);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <div className="search_design">
      <div>
        <img
          onClick={() => history.push("/")}
          className="landingLogo1"
          src={logo}
          alt="logo"
          fluid
        />
      </div>
      <div></div>
      <div>
        <div className="wrap">
          <div className="search">
            <input
              type="text"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              placeholder="এখানে সার্চ করুন......"
              className="searchTerm"
              onKeyDown={handledown}
            />
            <button
              type="submit"
              className="searchButton1"
              onClick={SearchSubmit}
            >
              <i className="fa fa-search"></i>
            </button>
          </div>
        </div>

        {data.length > 0 && keyword.length > 1 ? (
          <>
            <div
              className="autocomplete-items1"
              onScroll={DivScroll}
              ref={wrapperRef}
            >
              {data.map((each, i) => {
                return (
                  <>
                    <div key={i}>
                      <div
                        onClick={() =>
                          history.push(`/online-doctor/${each.id}`)
                        }
                        className="items_serial1"
                      >
                        <div>
                          <img src={each.Image} alt="" />
                        </div>
                        <div>
                          <span style={{ color: "#BD095A" }}>{each.Name}</span>
                          <br />
                          <h6 style={{ opacity: "75%", color: "black" }}>
                            {each.Designation}
                          </h6>
                          <h6 style={{ opacity: "75%", color: "black" }}>
                            বিভাগ : {each.Department}
                          </h6>

                          <span style={{ color: "black" }}>
                            ফি :{each.Fees}৳
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
              {hasMore && (
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  scroll to load more
                </div>
              )}
              {error && (
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  error: {error}
                </div>
              )}
              {loading && (
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  loading....
                </div>
              )}
              {!hasMore && (
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                ></div>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Search;

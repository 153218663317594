import React, { useEffect } from "react";
import { Dropdown, Container, Row, Col } from "react-bootstrap";
import "./Landing.css";
import { useHistory } from "react-router-dom";
import profile from "../../media/login.png";
import "aos/dist/aos.css";
import Aos from "aos";

const LandingDoctorTitle = () => {
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);
  return (
    <>
      <br />
      <div className="doctorlist">
        <h3>ডাক্তারের তালিকা সমূহ</h3>
      </div>
      <br />
      <br />
      <br />
      <Container>
        <Row>
          <Col xs={6} md={4}>
            <Dropdown className="doctorDivision">
              <Dropdown.Toggle
                variant="Danger"
                id="dropdown-basic"
                data-aos="fade-right"
              >
                ডাক্তারের বিভাগ নির্ধারণ করুন
              </Dropdown.Toggle>
              <Dropdown.Menu className="doctorDivision_dropdownMenu">
                <Dropdown.Item
                  id="dropdown_item"
                  onClick={() => history.push("/medicine")}
                >
                  মেডিসিন
                </Dropdown.Item>
                <Dropdown.Item
                  id="dropdown_item"
                  onClick={() => history.push("/Pediatrician")}
                >
                  শিশুরোগ বিশেষজ্ঞ
                </Dropdown.Item>
                <Dropdown.Item
                  id="dropdown_item"
                  onClick={() => history.push("/psychology")}
                >
                  সাইকোলজি
                </Dropdown.Item>
                <Dropdown.Item
                  id="dropdown_item"
                  onClick={() => history.push("/gynae")}
                >
                  স্ত্রীরোগ বিশেষজ্ঞ
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col xs={6} md={4}></Col>
          <Col xs={6} md={4}>
            <div className="d-flex justify-content-end">
              <Dropdown className="doctorDivisionProfile">
                <Dropdown.Toggle variant="Danger" id="dropdown-basic-profile" data-aos="fade-left">
                  <img src={profile} alt="profile" />
                  প্রোফাইল
                </Dropdown.Toggle>
                <Dropdown.Menu className="doctorDivisionProfile_dropdownMenu">
                  <Dropdown.Item
                    id="dropdown_item"
                    onClick={() => history.push("/patientPortal")}
                  >
                    সেবার পোর্টাল
                  </Dropdown.Item>
                  <Dropdown.Item
                    id="dropdown_item"
                    onClick={() => history.push("/patientProfile")}
                  >
                    রোগীর প্রোফাইল
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LandingDoctorTitle;

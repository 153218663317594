import React, { useEffect } from "react";
import "./Landing.css";
import ssl2 from "../../Assets/ssl2.jpg";
import facebook from "../../Assets/facebook.png";
import instagram from "../../Assets/instagram.png";
import whatsapp from "../../Assets/whatsapp.png";
import email from "../../Assets/email.png";
import { useHistory } from "react-router-dom";
import appIos from "../../Assets/app-ios.png";
import appPlay from "../../Assets/app-play.png";
import "aos/dist/aos.css";
import Aos from "aos";

const Footer = () => {
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);

  return (
    // <div style={{position:"fixed", bottom:"0", right:"0", left:"0", zIndex:"-1"}}>
    <div>
      <div
        className="container8_full_width"
        style={{
          overflowX: "hidden",
          overflowY: "hidden",
        }}
      >
        <div className="container8">
          <div></div>

          {/* <div className="find_us">
            <p>Find Us</p>
            <div className="social-icon">
              <a href="https://www.facebook.com/careboxlimited">
                <img src={facebook} />
              </a>
              <a href="https://api.whatsapp.com/send?phone=+8801796020709">
                <img src={whatsapp} />
              </a>
              <a href="https://www.instagram.com/careboxlimited/">
                <img src={instagram} />
              </a>
              <a href="mailto:my@care-box.com">
                <img src={email} />
              </a>
            </div>
          </div>

          <div className="address">
            <p>Address</p>
            <h6>
              149/A Monipuri Para, (Ground Floor) Airport Road, Farmgate,
              Tejgaon, Dhaka-1215, Bangladesh
            </h6>
          </div> */}

          <div>
            <div className="container9">
              <div className="find_us">
                <p>Find Us</p>
                <div className="social-icon">
                  <a href="https://www.facebook.com/careboxlimited">
                    <img src={facebook} />
                  </a>
                  <a href="https://api.whatsapp.com/send?phone=+8801796020709">
                    <img src={whatsapp} />
                  </a>
                  <a href="https://www.instagram.com/careboxlimited/">
                    <img src={instagram} />
                  </a>
                  <a href="mailto:my@care-box.com">
                    <img src={email} />
                  </a>
                </div>
              </div>

              <div className="address">
                <p>Address</p>
                <h6>
                  149/A Monipuri Para, (Ground Floor) Airport Road, Farmgate,
                  Tejgaon, Dhaka-1215, Bangladesh
                </h6>
              </div>
            </div>

            <div className="app" data-aos="fade-up-right">
              <h6 className="app_text">Download Our App</h6>
              <div>
                <a href="https://play.google.com/store/apps/details?id=care_box.carebox.com">
                  <img src={appPlay} alt="" />
                </a>
                <a href="https://apps.apple.com/us/app/care-box-all-cares-inside/id1610855749">
                  <img src={appIos} alt="" />
                </a>
              </div>
            </div>
          </div>

          <div className="serial-tag">
            <p>About</p>
            <h6 onClick={() => history.push("/about-us")}>About Us</h6>
            {/* <h6 onClick={() => history.push("/career")}>Career</h6> */}
            <h6 onClick={() => history.push("/doctorPortalHome")}>
              Doctor Apply
            </h6>
            {/* <h6 onClick={() => history.push("/doctorLogin")}>Doctor Login</h6> */}
            <h6 onClick={() => history.push("/patientWaitingList")}>
              Doctor Portal
            </h6>

            <h6 onClick={() => history.push("/privacy-policy")}>
              Privacy Policy
            </h6>
            <h6 onClick={() => history.push("/return-policy")}>
              Return Policy
            </h6>
            <h6 onClick={() => history.push("/terms-condition")}>
              Terms and Conditions
            </h6>
            <a
              id="carebox_tech"
              href="https://www.care-box.tech/"
              target="_blank"
              rel="noreferrer"
            >
              <h6>Care-Box Tech Solutions</h6>
            </a>
          </div>

          <div className="POD">
            <p>Payment Methods</p>
            <img src={ssl2} alt="ssl" />
          </div>

          {/* <div className="app" style={{ textAlign: "left" }}>
            <h6
              style={{
                textAlign: "left",
                fontSize: "18px",
                marginTop: "10px",
              }}
            >
              Download Our Apps
            </h6>
            <div style={{ padding: "0" }}>
              <img
                src={appPlay}
                alt=""
                style={{ width: "40%", marginRight: "2%" }}
              />
              <img src={appIos} alt="" style={{ width: "40%" }} />
            </div>
          </div> */}

          {/* <div className="app">
            <p>Download Our App</p>
            <div>
              <img src={appPlay} alt="" style={{ marginBottom: "2%" }} />
              <img src={appIos} alt="" />
            </div>
          </div> */}

          <div></div>
        </div>
        <div className="rights">
          <h5>Trade License No: 289236</h5>
          <p>
            All rights reserved by @ Care-Box Limited,{" "}
            {new Date().getFullYear()}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React, { useEffect } from "react";
import { Container, Row, Col, Navbar } from "react-bootstrap";
import logo from "../../media/logo.png";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import { Button } from "react-bootstrap";
import doc_image from "../../media/img_avatar.png";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import "./Landing.css";
import Footer from "./Footer";
import { useDispatch, useSelector } from "react-redux";
import {
  getDoctorReview,
  listDoctorDetails,
} from "../../redux/actions/doctorDetailAction";
import OtpLoader from "../Login/OtpLoader";
import HeaderBox from "../Pages/HeaderBox";
import StarIcon from "@mui/icons-material/Star";

const EachDoctor = () => {
  const { id } = useParams();
  console.log(id);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listDoctorDetails(id));
  }, []);

  const doctorDetail = useSelector((state) => state.doctorDetails);
  console.log("doctorDetail*", doctorDetail);
  const { loading, doctor } = doctorDetail;
  console.log("doctorDetailss * * *", doctor);

  useEffect(() => {
    dispatch(getDoctorReview(id));
  }, []);

  const doctorReview = useSelector((state) => state.doctorReviewGet);
  const { rating, success } = doctorReview;
  console.log("FEEDBACK*", rating);

  const HandleClickSession = (e) => {
    e.preventDefault();
    history.push(`/DoctorForm/${id}`);
  };
  return (
    <div>
      <div className="page_wrap">
        <Navbar className="headerbox_eachDoctor">
          <Container>
            <Navbar.Brand href="/">
              <img src={logo} alt="logo" fluid />
            </Navbar.Brand>
            <Navbar.Toggle />
            {/* <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              <input type="text" placeholder="এখানে সার্চ করুন......" />
            </Navbar.Text>
          </Navbar.Collapse> */}
          </Container>
        </Navbar>
        <br />
        <br />
        {/* {loading === false ? ( */}
        <Container className="eachDoctor_details">
          <Row className="eachDoctor_row">
            <Col className="eachDoctor_info" md={6} sm={5}>
              <h2>{doctor.Name}</h2>
              <p className="eachDoctor_designation">{doctor.Designation}</p>
              {doctor.bmdc_no ? (
                <p style={{ fontSize: "20px", paddingBottom: "5px" }}>
                  বিএমডিসি নম্বর: {doctor.bmdc_no}
                </p>
              ) : (
                <></>
              )}
              <Stack spacing={1} className="eachDoctor_rating">
                <Rating
                  name="size-small"
                  defaultValue={4.5}
                  size="small"
                  value={doctor.Rating}
                  precision={0.5}
                  readOnly
                />
              </Stack>
              <p className="eachDoctor_time">
                সময়ঃ {doctor.Appointment_Time_Range}
              </p>
              <button
                style={{ cursor: "context-menu" }}
                className="charge_fifty"
              >
                চার্জঃ {doctor.Fees} টাকা
              </button>
              <h4 className="session_25">(প্রতি সেশনঃ ২৫ মিনিট)</h4>

              <div className="eachDoctor_desc">
                <h5 className="eachDoctor_description">ডাক্তারের বিবরণঃ</h5>
                <p>{doctor.Background}</p>
              </div>
              <br />
              <button className="session_button" onClick={HandleClickSession}>
                সেশন বুক করুন
              </button>
            </Col>
            <Col md={1} sm={1}></Col>
            <Col md={5} sm={4}>
              <img
                className="each_doctor_image"
                src={doctor.Image}
                alt="doctor name"
              />
            </Col>
          </Row>
        </Container>
        {/* ) : (
        <OtpLoader></OtpLoader>
      )} */}
        <br />
      </div>
      <Footer />
    </div>
  );
};

export default EachDoctor;

import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophone,
  faVideo,
  faDesktop,
  faVideoSlash,
  faMicrophoneSlash,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import "./MeetingFooter.css";
import ReviewModal from "../Pages/ReviewModal";
import { Link, useHistory } from "react-router-dom";
import firepadRef, { db } from "../../server/firebase";

const MeetingFooter = (props) => {
  const history = useHistory();

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const [streamState, setStreamState] = useState({
    mic: true,
    video: false,
    screen: false,
  });
  const micClick = () => {
    setStreamState((currentState) => {
      return {
        ...currentState,
        mic: !currentState.mic,
      };
    });
  };

  const onVideoClick = () => {
    setStreamState((currentState) => {
      return {
        ...currentState,
        video: !currentState.video,
      };
    });
  };

  const onScreenClick = () => {
    props.onScreenClick(setScreenState);
  };

  const setScreenState = (isEnabled) => {
    setStreamState((currentState) => {
      return {
        ...currentState,
        screen: isEnabled,
      };
    });
  };
  useEffect(() => {
    props.onMicClick(streamState.mic);
  }, [streamState.mic]);
  useEffect(() => {
    props.onVideoClick(streamState.video);
  }, [streamState.video]);

  const handleCancel = () => {
    // setStreamState((currentState) => {
    //   return {
    //     ...currentState,
    //     video: currentState.video === false,
    //   };
    // });
    // setStreamState((currentState) => {
    //   return {
    //     ...currentState,
    //     mic: currentState.mic === false,
    //   };
    // });

    if (localStorage.getItem("service")) {
      let formData = new FormData();
      formData.append("Meeting_Status", "Completed");
      fetch(
        `https://api-care-box.click/api/v2/Online_Doctor_Booking/book_doctor/put_view/${localStorage.getItem(
          "service"
        )}/`,
        {
          method: "PUT",
          body: formData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.message === "successfully updated") {
            window.location.href = `/patientWaitingList`;
          }
        });
      // history.push("/patientWaitingList");
      // window.location.href = `/patientWaitingList`;
      localStorage.removeItem("prescription_id");

      const participantRef = firepadRef.child("participants");
      // ._delegate._repo.onDisconnect_.children.values()
      // .next()
      // .value.children.values()
      // .next()
      // .value.children.keys()
      // .next().value;

      console.log(participantRef);

      participantRef
        .remove()
        .then((res) => console.log("res", res))
        .catch((err) => console.log("err", err));
    } else {
      openModal();

      // const connectedRef = db.database().ref(".info/connected/database");
      // console.log("connectedRef", connectedRef)

      // const abc = connectedRef.on("child_removed", snapshot => {
      //   const deletedPost = snapshot.val();
      //   console.log('The blog post titled \'' + deletedPost.title + '\' has been deleted');
      // }).
      // console.log("%%%%%%%%%", abc)

      // const participantRef = firepadRef.child("participants")._delegate._repo.onDisconnect_.children
      // const participantRef = firepadRef.child("participants");
      // console.log(participantRef)

      // const myId = participantRef._delegate._repo.onDisconnect_.children;
      // // console.log(abc)

      // const Id = myId
      //   .values()
      //   .next()
      //   .value.children.values()
      //   .next()
      //   .value.children.keys()
      //   .next().value;
      // console.log(Id);

      // const participantRef = firepadRef
      //   .child("participants")
      //   ._delegate._repo.onDisconnect_.children.values()
      //   .next()
      //   .value.children.values()
      //   .next()
      //   .value.children.keys()
      //   .next().value;

      const participantRef = firepadRef.child("participants");
      // ._delegate._repo.onDisconnect_.children.values()
      // .next()
      // .value.children.values()
      // .next()
      // .value.children.keys()
      // .next().value;

      console.log(participantRef);

      participantRef
        .remove()
        .then((res) => console.log("res", res))
        .catch((err) => console.log("err", err));

      // const isUserSet = !!props.user;
      // const isStreamSet = !!props.stream;

      // console.log("user and stream", isUserSet, isStreamSet)

      // if(isUserSet === false && isStreamSet === false){
      //   console.log("ami vitore dhuksi")
      // participantRef.on('child_removed', (snap) => {
      //   // props.removeParticipant(snap.key);
      //   const deletePost = snap.val()
      //   console.log(deletePost)
      // });

      // console.log("SNAP",participantRef.key.removeItem())
      // participantRef.key.remove()

      // participantRef.on("child_removed", (snap) => {
      //   console.log("SNAP*#*#", snap);
      //   props.removeParticipant(snap.key);
      // });
    }
  };

  return (
    <div className="meeting-footer">
      <div
        className={"meeting-icons " + (!streamState.mic ? "active" : "")}
        // data-tip={streamState.mic ? "Mute Audio" : "Unmute Audio"}
        onClick={micClick}
      >
        <FontAwesomeIcon
          icon={!streamState.mic ? faMicrophoneSlash : faMicrophone}
          title="Mute"
        />
      </div>
      <div
        className={"meeting-icons " + (!streamState.video ? "active" : "")}
        // data-tip={streamState.video ? "Hide Video" : "Show Video"}
        onClick={onVideoClick}
      >
        <FontAwesomeIcon icon={!streamState.video ? faVideoSlash : faVideo} />
      </div>
      {/* <div
        className={"meeting-icons " + (!streamState.screen ? "active" : "")}
        // data-tip="Share Screen"
        onClick={onScreenClick}
        disabled={streamState.screen}
      >
        <FontAwesomeIcon icon={faDesktop} />
      </div> */}
      <div
        className="meeting-icons"
        // data-tip={streamState.video}
        style={{ backgroundColor: "#ea4335" }}
        onClick={handleCancel}
      >
        <FontAwesomeIcon icon={faPhone} />
        <ReviewModal
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
        ></ReviewModal>
      </div>
      <ReactTooltip />
    </div>
  );
};

export default MeetingFooter;

import React, { useEffect } from "react";
import { Navbar } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import logo from "../../media/logo.png";
import Footer from "../Pages/Footer";
import "./FooterContent.css";
import "aos/dist/aos.css";
import Aos from "aos";

const AboutUs = () => {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);
  return (
    <div>
      <Navbar className="footer_navbar">
        <Navbar.Brand style={{ margin: "0 0 0 0" }}>
          <img src={logo} onClick={() => history.push("/")} alt="logo" fluid />
        </Navbar.Brand>
      </Navbar>
      <div className="about_us">
        <h2 data-aos="fade-up">About Us</h2>
        <p data-aos="fade-up">
          We are an iconic medical, health, and personal care entity, chosen and
          trusted by customers for authentic quality-controlled products and
          services at affordable prices. We started our journey on 5th June 2021
          and from that day on we started serving our customers with utmost
          sincerity. We have developed our own E-Commerce website and app, and
          it is providing service to our customers, without any issues. Right
          after that, we have come forward with our own Online Doctor portal
          website and mobile app. Customers can get top-quality treatment, from
          the palm of their hands. Our motto is to make customers happy and
          healthy, at affordable costs.
          <br />
          <br />
          We are taking skilled and experienced doctors, who have taken degrees
          from the best medical schools in the world so that customers get the
          best service possible. All kinds of payment methods are available
          making the Doctor portal easier to use.
        </p>
        <br />
        <h2 data-aos="fade-up">Address</h2>
        <p data-aos="fade-up">
          149/A <br /> Baitush Sharaf Complex, <br /> Kazi Nazrul Islam Ave,{" "}
          <br /> Dhaka 1215
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;

import React from "react";
import Modal from "react-modal";
import successButton from "../../media/checked.png";

const OrderSuccessModal = ({ modalIsOpen, closeModalForSuccess }) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModalForSuccess}
      className="modalStyle"
      contentLabel="Example Modal"
    >
      <div className="col-md-12">
        <div className="d-flex justify-content-center align-items-center">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <div className="d-flex justify-content-center align-items-center">
              <img src={successButton} style={{ height: "70px" }} alt="" />
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
        <h5
          style={{
            fontFamily: "poppins",
            fontSize: "18px",
            lineHeight: "27px",
            marginTop: "20px",
            textAlign: "center",
            paddingLeft: "12px",
            paddingRight: "12px",
            fontWeight: "bold",
          }}
        >
          Your order placed successfully!! <br /> One of our executive will call
          you for further information.
        </h5>
      </div>
    </Modal>
  );
};

export default OrderSuccessModal;

import React from "react";
// import doc_image from "../../media/img_avatar.png";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import "./doctors.css";

const ParticularDoctorCard = (props) => {
  console.log("props", props);
  const history = useHistory();
  const handleButtonPush = () => {
    history.push(`/online-doctor/${props.doctors.id}`);
  };
  return (
    <div className="col-md-4">
      <div className="particularDoctor_card_container">
        <div
          onClick={handleButtonPush}
          className="particularDoctor_content mt-4"
          style={{ cursor: "pointer" }}
        >
          <div className="particularDoctor_flex">
            <div className="particularDoctor_flex_image">
              <img src={props.doctors.Image} alt="doctor name" />
            </div>
            <div className="particularDoctor_flex_details">
              <h2>{props.doctors.Name}</h2>
              <p className="particularDoctor_flex_details_designation">
                {props.doctors.Designation}
              </p>
              <div className="particularDoctor_fees">
                <h5>চার্জ: {props.doctors.Fees} টাকা</h5>
              </div>
              <Stack spacing={1} className="particularDoctor_rating">
                <Rating
                  name="size-small"
                  // defaultValue={4.5}
                  value={props.doctors.Rating}
                  size="small"
                  precision={0.5}
                  readOnly
                />
              </Stack>
              <p className="particularDoctor_flex_details_time">
                সময়ঃ {props.doctors.Appointment_Time_Range}
              </p>

              <Button
                class="session_bounce"
                variant="warning"
                onClick={handleButtonPush}
              >
                সেশন বুক করুন
              </Button>
            </div>
          </div>
          <div className="particularDoctor_desc">
            <h5 className="particularDoctor_description">বিবরণঃ</h5>
            {props.doctors.Background.length > 320 ? (
              <p>{props.doctors.Background.substring(0, 255)}. . .</p>
            ) : (
              <p>{props.doctors.Background}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParticularDoctorCard;

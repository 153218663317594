import React, { useEffect, useState } from "react";
import logo from "../../media/logo.png";
import Countdown from "react-countdown";
import { useHistory } from "react-router-dom";

const Completionist = () => <span>You are good to go!</span>;

const renderer = ({ minutes, seconds, completed }) => {
  if (completed) {
    return <Completionist />;
  } else {
    return (
      <h1 className="countdown_timer">
        <div className="time">
          {minutes}:{seconds}
        </div>
      </h1>
    );
  }
};

const CountdownTimer = () => {
  const [url, setUrl] = useState();
  console.log(url);

  useEffect(() => {
    setInterval(() => {
      fetch(
        "https://api-care-box.click/api/v2/Patient_profile/CheckPatientAccessId/",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Custom-User-Agent":
              "15!@ejh46)(*%#!@s4h68a4rgsagH&^%%$#@!JKFKVYRDTgjsgakjzghfjJ%$#@#%HFYD32434",
          },
        }
      )
        .then((res) => res.json())
        .then((data) => setUrl(data));
    }, 10000);
  }, [setInterval]);

  return (
    <div className="countdown_container">
      <img src={logo} alt="logo" />
      <br />
      <br />
      <br />
      <h2>অনুগ্রহ করে অপেক্ষা করুন, ডাক্তার অতি শীঘ্রই যুক্ত হবেন</h2>
      <div className="countDown">
        <Countdown date={Date.now() + 600000} renderer={renderer} />
      </div>
      {url ? (
        <>
          {url.Access_Id ? (
            <>
              <div onClick={(window.location.href = `${url.Access_Id}`)}>
                {/* <a
              style={{
                textDecoration: "none",
                color: "black",
              }}
              // href="url.Access_Id"
            > */}
                {/* Join Now */}
                {/* </a> */}
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <div
          // onClick={(window.location.href = `http://localhost:3000/countdown`)}
          ></div>
        </>
      )}
    </div>
  );
};

export default CountdownTimer;

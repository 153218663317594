import React, { useRef, useState } from "react";
import { Navbar, Container } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import logo from "../../media/logo.png";
import footerImg from "../../media/docicon.png";
import dropDownIcon from "../../media/dropdown-icon.png";
import FadeIn from "react-fade-in";
import Footer from "../Pages/Footer";
import Swal from "sweetalert2";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import moment from "moment";
import { useEffect } from "react";
import { getPatientView } from "../../redux/actions/patientDetailAction";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ReviewModal from "../Pages/ReviewModal";
import {
  renderToString,
  renderToStaticMarkup,
  renderToNodeStream,
} from "react-dom/server";
import { getUserDetails, logout } from "../../redux/actions/userActions";
import OrderSuccessModal from "./OrderSuccessModal";
import OtpLoader from "../Login/OtpLoader";
import ScrollButton from "../ScrollButton/ScrollButton";
import font from "../../media/fonts/Poppins-Regular.ttf";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  PDFDownloadLink,
  LinearGradient,
  Font,
  pdf,
  BlobProvider,
} from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { PDFViewer } from "@react-pdf/renderer";
import PrescriptionPDF from "./PrescriptionPDF";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import HandlePdf from "./HandlePdf";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import jsPDF from "jspdf";

Font.register({
  family: "Poppins",
  src: font,
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    fontSize: 11,
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    lineHeight: 1,
    flexDirection: "column",
    minHeight: "100vh",
    fontFamily: "Poppins",
  },
  row: {
    flexDirection: "row",
    width: "100%",
  },
  column: {
    flexDirection: "column",
    width: "50%",
    paddingRight: 15,
    paddingLeft: 15,
  },
  footerLeft: {
    height: 90,
    width: 220,
    marginTop: 10,
    textAlign: "left",
  },
  footerColumnLeft: {
    flexDirection: "column",
    width: "30%",
    padding: 0,
    margin: 0,
  },
  footerColumnRight: {
    flexDirection: "column",
    width: "70%",
    padding: 0,
    marginTop: 30,
    lineHeight: 1.4,
  },
  header: {
    height: 30,
    paddingLeft: 25,
    paddingRight: 25,
    backgroundColor: "#900C3F",
    color: "#fff",
    fontSize: 24,
    borderRadius: 7,
    // marginTop: 15,
    alignContent: "center",
    alignItems: "center",
    alignSelf: "center",
    textAlign: "center",
  },
  logo: {
    // width: 100,
    height: 80,
    marginLeft: "auto",
    // marginRight: 10,
    marginTop: -10,
    alignContent: "center",
    alignItems: "center",
    alignSelf: "center",
    textAlign: "center",
    // flexDirection: "row",
    // justifyContent: "flex-end",
  },
  section: {
    // margin: 10,
    // padding: 10,
    fontSize: 13,
    // flexGrow: 1,
    lineHeight: 1.5,
  },
  box: {
    border: "2 solid black",
    flexDirection: "row",
    paddingTop: 5,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 10,
  },
  textUnderline: {
    fontSize: 13,
    textDecoration: "underline",
  },
  // footer: {
  //   minHeight: `calc(100vh - 200px)`,
  //   position: "fixed",
  //   bottom: 0,
  // },
});

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 100,
    backgroundColor: "#D2D2D2",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      paddingTop: "7px",
      paddingBottom: "0px",
    },
  },
}));

const PatientPortal = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [detailshow, setDetailshow] = useState(true);
  const [show, setShow] = useState(true);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDetails = () => {
    setDetailshow(!detailshow);
  };
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPatientView);
  }, []);

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const userDetails = useSelector((state) => state.userDetails);
  const { error, user } = userDetails;
  console.log("user", user);
  console.log("errorrrr", error);

  const locationset = useLocation();

  useEffect(() => {
    if (error === "Request failed with status code 401") {
      history.push("/login");
      localStorage.setItem("url", locationset.pathname);
      window.location.reload();
    } else {
    }
  }, [error]);

  useEffect(() => {
    dispatch(getUserDetails());
  }, []);

  if (user) {
    localStorage.setItem("name", user.Name);
  }

  const patientDetails = useSelector((state) => state.patientViewGet);
  const { loading, patientView } = patientDetails;
  console.log(patientView);

  const [prescriptionDetail, setPrescriptionDetail] = useState([]);
  console.log("pres Detail", prescriptionDetail);

  useEffect(() => {
    fetch(
      "https://api-care-box.click/api/v2/Online_Doctor_Booking/Prescription_Details/Patient/",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => setPrescriptionDetail(data));
  }, []);

  const container1 = useRef(null);
  const container = useRef(null);

  const handlePdf = (id) => {
    // let data = prescriptionDetail.filter((item) => item.Book_Doctor === id)[0];
    // console.log(data);
    // pdfExportComponent.current.save();
    // const element = document.querySelector(".abc");
    const element = container.current;
    console.log(element);

    savePDF(container.current, { paperSize: "A4" });

    // return (
    //   <div className="abc">
    //     <PDFExport>
    //       <h1>{data.Book_Doctor}</h1>
    //       <h1>{data.Doctor_Name}</h1>
    //       <h1>{data.Patient_Name}</h1>
    //     </PDFExport>
    //   </div>
    // );
  };

  const handlePdf1 = (id) => {
    let data = prescriptionDetail.filter((item) => item.Book_Doctor === id)[0];
    console.log(data);

    // savePDF(pdfExportComponent.current, { paperSize: "A4" });
    // pdfExportComponent.current.save();

    return (
      <div>
        {/* <PDFExport ref={pdfExportComponent} paperSize="A4">
          <h1>{data.Book_Doctor}</h1>
          <h1>{data.Doctor_Name}</h1>
          <h1>{data.Patient_Name}</h1>
        </PDFExport> */}
      </div>
    );
  };

  var [docData, setDocData] = useState({});
  console.log("docdata", docData);

  const doctorName = docData.Doctor_Name;
  const patientName = docData.Patient_Name;
  const investigations = docData.Investigations;
  const vitals = docData.Patient_Vitals;
  const treatment = docData.Prescribed_Medicines;
  const presId = docData.Book_Doctor;

  // var doctorName = () => `${docData.Doctor_Name}`;
  // var patientName = () => `${docData.Patient_Name}`;
  // var investigations = () => `${docData.Investigations}`;
  // var vitals = () => `${docData.Patient_Vitals}`;
  // var treatment = () => `${docData.Prescribed_Medicines}`;
  // var presId = () => `${docData.Book_Doctor}`;

  if (docData) {
    var pdf = (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={{ minHeight: "86vh" }}>
            <View style={styles.row} fixed>
              <View style={styles.header}>
                <Text style={{ paddingTop: "-2px" }}>PRESCRIPTION</Text>
              </View>
              <Image style={styles.logo} src={logo} />
            </View>
            <View style={styles.section} fixed>
              <Text>Prescribed By: {doctorName}</Text>
              <Text>Prescription ID: {presId}</Text>
            </View>

            <View style={{ marginTop: "10px" }} fixed></View>

            <View style={styles.box} fixed>
              <Text style={{ marginLeft: "5px", marginRight: "35px" }}>
                Patient Name: {patientName}
              </Text>
              <Text style={{ marginRight: "35px" }}>Age: 25</Text>
              <Text style={{ marginRight: "35px" }}>Gender: Female</Text>
              <Text style={{ marginRight: "5px" }}>Date: 28-04-2022</Text>
            </View>
            <View style={{ marginTop: "20px" }} fixed></View>

            <View style={{ overflow: "hidden" }}>
              <View style={styles.row}>
                <View style={styles.column}>
                  <View style={{ borderRight: "1px solid black" }}>
                    <Text style={styles.textUnderline}>Health Issue:</Text>
                    <Text
                      style={{
                        marginBottom: "10px",
                        padding: "10px",
                        lineHeight: 1.3,
                      }}
                    >
                      {vitals}
                    </Text>

                    <Text style={styles.textUnderline}>Patient Vitals:</Text>
                    <Text
                      style={{
                        marginBottom: "10px",
                        padding: "10px",
                        lineHeight: 1.3,
                      }}
                    >
                      {vitals}
                    </Text>

                    <Text style={styles.textUnderline}>Investigations:</Text>
                    <Text
                      style={{
                        marginBottom: "10px",
                        padding: "10px",
                        lineHeight: 1.3,
                      }}
                    >
                      {investigations}
                    </Text>
                  </View>
                </View>
                <View style={styles.column}>
                  <Text style={styles.textUnderline}>Treatments:</Text>
                  <Text
                    style={{
                      marginBottom: "10px",
                      padding: "10px",
                      lineHeight: 1.3,
                    }}
                  >
                    {treatment}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View fixed>
            <View style={styles.row}>
              <View style={styles.footerColumnLeft}>
                <Image style={styles.footerLeft} src={footerImg} />
              </View>
              <View style={styles.footerColumnRight}>
                <View style={{ textAlign: "right" }}>
                  <Text style={{ fontSize: "14px", color: "#cf155f" }}>
                    Care-Box Limited
                  </Text>
                  <Text style={{ fontSize: "9px" }}>
                    149/A, Monipuri para, Baitush Sharaf Complex
                  </Text>
                  <Text style={{ fontSize: "9px" }}>
                    (Ground floor), Airport road, Farmgate, Tejgaon, Dhaka-1215,
                    Bangladesh
                  </Text>
                  <Text style={{ fontSize: "9px" }}>
                    +8801886020709, +8801796020709, +880248116644
                  </Text>
                  <Text style={{ fontSize: "9px" }}>WWW.CARE-BOX.COM</Text>
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    );
  }

  // const handlePdf = (data) => {
  //   console.log(data);

  //   return (
  //     <div>
  //       <PDFDownloadLink document={pdf} fileName="presc.pdf">
  //         {({ blob, url, loading, error }) =>
  //           loading ? (
  //             <div class="spinner-border text-primary" role="status">
  //               <span class="sr-only"></span>
  //             </div>
  //           ) : (
  //             <button>প্রেসক্রিপশন</button>
  //           )
  //         }
  //       </PDFDownloadLink>
  //     </div>
  //   );
  // };

  // const handlePdf = (props) => {
  //   const { Patient_Name } = props;
  //   console.log("props", Patient_Name);

  //   return <PrescriptionPDF data={Patient_Name}></PrescriptionPDF>;
  // };

  const MyDocument = (id) => {
    // const MyDocument = (props) => {
    //   console.log(props);
    //   const { Book_Doctor, Patient_Name } = props;
    //   console.log(Patient_Name);

    // let data = prescriptionDetail.filter((item) => item.Book_Doctor === id)[0];
    // console.log(data);

    // if (data) {
    //   // sessionStorage.setItem("data", JSON.stringify(data));
    //   setDocData(data);
    // }

    // if (sessionStorage.length) {
    // var sessionData = JSON.parse(sessionStorage.getItem("data"));
    // console.log(sessionData.Doctor_Name);

    //   var doctorName = sessionData.Doctor_Name;
    //   var patientName = sessionData.Patient_Name;
    //   var investigations = sessionData.Investigations;
    //   var vitals = sessionData.Patient_Vitals;
    //   var treatment = sessionData.Prescribed_Medicines;
    //   var presId = sessionData.Book_Doctor;

    //   // var doctorName = () => `${sessionData.Doctor_Name}`;
    //   // var patientName = () => `${sessionData.Patient_Name}`;
    //   // var investigations = () => `${sessionData.Investigations}`;
    //   // var vitals = () => `${sessionData.Patient_Vitals}`;
    //   // var treatment = () => `${sessionData.Prescribed_Medicines}`;
    //   // var presId = () => `${sessionData.Book_Doctor}`;
    // }

    console.log("docdata", docData);

    const doctorName = docData.Doctor_Name;
    const patientName = docData.Patient_Name;
    const investigations = docData.Investigations;
    const vitals = docData.Patient_Vitals;
    const treatment = docData.Prescribed_Medicines;
    const presId = docData.Book_Doctor;

    // if (data) {
    // var doctorName = data?.Doctor_Name;
    // console.log("doctor name", doctorName);
    // var patientName = data?.Patient_Name;
    // console.log("patient name", patientName);
    // var investigations = data?.Investigations;
    // console.log("investigations", investigations);
    // var vitals = data?.Patient_Vitals;
    // console.log("vitals", vitals);
    // var treatment = data?.Prescribed_Medicines;
    // console.log("treatment", treatment);
    // var presId = data?.Book_Doctor;
    // console.log("presId", presId);

    // const DoctorName = () => `${data?.Doctor_Name}`;
    // const PatientName = () => `${data?.Patient_Name}`;
    // const Investigation = () => `${data?.Investigations}`;
    // const Vitals = () => `${data?.Patient_Vitals}`;
    // const Treatment = () => `${data?.Prescribed_Medicines}`;
    // const PresId = () => `${data?.Book_Doctor}`;
    // }

    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={{ minHeight: "86vh" }}>
            <View style={styles.row} fixed>
              <View style={styles.header}>
                <Text style={{ paddingTop: "-2px" }}>PRESCRIPTION</Text>
              </View>
              <Image style={styles.logo} src={logo} />
            </View>
            <View style={styles.section} fixed>
              <Text>Prescribed By: {doctorName}</Text>
              <Text>Prescription ID: {presId}</Text>
            </View>

            <View style={{ marginTop: "10px" }} fixed></View>

            <View style={styles.box} fixed>
              <Text style={{ marginLeft: "5px", marginRight: "35px" }}>
                Patient Name: {patientName}
              </Text>
              <Text style={{ marginRight: "35px" }}>Age: 25</Text>
              <Text style={{ marginRight: "35px" }}>Gender: Female</Text>
              <Text style={{ marginRight: "5px" }}>Date: 28-04-2022</Text>
            </View>
            <View style={{ marginTop: "20px" }} fixed></View>

            <View style={{ overflow: "hidden" }}>
              <View style={styles.row}>
                <View style={styles.column}>
                  <View style={{ borderRight: "1px solid black" }}>
                    <Text style={styles.textUnderline}>Health Issue:</Text>
                    <Text
                      style={{
                        marginBottom: "10px",
                        padding: "10px",
                        lineHeight: 1.3,
                      }}
                    >
                      {vitals}
                    </Text>

                    <Text style={styles.textUnderline}>Patient Vitals:</Text>
                    <Text
                      style={{
                        marginBottom: "10px",
                        padding: "10px",
                        lineHeight: 1.3,
                      }}
                    >
                      {vitals}
                    </Text>

                    <Text style={styles.textUnderline}>Investigations:</Text>
                    <Text
                      style={{
                        marginBottom: "10px",
                        padding: "10px",
                        lineHeight: 1.3,
                      }}
                    >
                      {investigations}
                    </Text>
                  </View>
                </View>
                <View style={styles.column}>
                  <Text style={styles.textUnderline}>Treatments:</Text>
                  <Text
                    style={{
                      marginBottom: "10px",
                      padding: "10px",
                      lineHeight: 1.3,
                    }}
                  >
                    {treatment}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View fixed>
            <View style={styles.row}>
              <View style={styles.footerColumnLeft}>
                <Image style={styles.footerLeft} src={footerImg} />
              </View>
              <View style={styles.footerColumnRight}>
                <View style={{ textAlign: "right" }}>
                  <Text style={{ fontSize: "14px", color: "#cf155f" }}>
                    Care-Box Limited
                  </Text>
                  <Text style={{ fontSize: "9px" }}>
                    149/A, Monipuri para, Baitush Sharaf Complex
                  </Text>
                  <Text style={{ fontSize: "9px" }}>
                    (Ground floor), Airport road, Farmgate, Tejgaon, Dhaka-1215,
                    Bangladesh
                  </Text>
                  <Text style={{ fontSize: "9px" }}>
                    +8801886020709, +8801796020709, +880248116644
                  </Text>
                  <Text style={{ fontSize: "9px" }}>WWW.CARE-BOX.COM</Text>
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    );
  };

  // const sessionData = JSON.parse(sessionStorage.getItem("data"));
  // console.log(sessionData.Doctor_Name);

  // var doctorName = sessionData.Doctor_Name;
  // var patientName = sessionData.Patient_Name;
  // var investigations = sessionData.Investigations;
  // var vitals = sessionData.Patient_Vitals;
  // var treatment = sessionData.Prescribed_Medicines;
  // var presId = sessionData.Book_Doctor;

  // var pdf = (
  //   <Document>
  //     <Page size="A4" style={styles.page}>
  //       <View style={{ minHeight: "86vh" }}>
  //         <View style={styles.row} fixed>
  //           <View style={styles.header}>
  //             <Text style={{ paddingTop: "-2px" }}>PRESCRIPTION</Text>
  //           </View>
  //           <Image style={styles.logo} src={logo} />
  //         </View>
  //         <View style={styles.section} fixed>
  //           <Text>Prescribed By: {doctorName}</Text>
  //           <Text>Prescription ID: {presId}</Text>
  //         </View>

  //         <View style={{ marginTop: "10px" }} fixed></View>

  //         <View style={styles.box} fixed>
  //           <Text style={{ marginLeft: "5px", marginRight: "35px" }}>
  //             Patient Name: {patientName}
  //           </Text>
  //           <Text style={{ marginRight: "35px" }}>Age: 25</Text>
  //           <Text style={{ marginRight: "35px" }}>Gender: Female</Text>
  //           <Text style={{ marginRight: "5px" }}>Date: 28-04-2022</Text>
  //         </View>
  //         <View style={{ marginTop: "20px" }} fixed></View>

  //         <View style={{ overflow: "hidden" }}>
  //           <View style={styles.row}>
  //             <View style={styles.column}>
  //               <View style={{ borderRight: "1px solid black" }}>
  //                 <Text style={styles.textUnderline}>Health Issue:</Text>
  //                 <Text
  //                   style={{
  //                     marginBottom: "10px",
  //                     padding: "10px",
  //                     lineHeight: 1.3,
  //                   }}
  //                 >
  //                   {vitals}
  //                 </Text>

  //                 <Text style={styles.textUnderline}>Patient Vitals:</Text>
  //                 <Text
  //                   style={{
  //                     marginBottom: "10px",
  //                     padding: "10px",
  //                     lineHeight: 1.3,
  //                   }}
  //                 >
  //                   {vitals}
  //                 </Text>

  //                 <Text style={styles.textUnderline}>Investigations:</Text>
  //                 <Text
  //                   style={{
  //                     marginBottom: "10px",
  //                     padding: "10px",
  //                     lineHeight: 1.3,
  //                   }}
  //                 >
  //                   {investigations}
  //                 </Text>
  //               </View>
  //             </View>
  //             <View style={styles.column}>
  //               <Text style={styles.textUnderline}>Treatments:</Text>
  //               <Text
  //                 style={{
  //                   marginBottom: "10px",
  //                   padding: "10px",
  //                   lineHeight: 1.3,
  //                 }}
  //               >
  //                 {treatment}
  //               </Text>
  //             </View>
  //           </View>
  //         </View>
  //       </View>

  //       <View fixed>
  //         <View style={styles.row}>
  //           <View style={styles.footerColumnLeft}>
  //             <Image style={styles.footerLeft} src={footerImg} />
  //           </View>
  //           <View style={styles.footerColumnRight}>
  //             <View style={{ textAlign: "right" }}>
  //               <Text style={{ fontSize: "14px", color: "#cf155f" }}>
  //                 Care-Box Limited
  //               </Text>
  //               <Text style={{ fontSize: "9px" }}>
  //                 149/A, Monipuri para, Baitush Sharaf Complex
  //               </Text>
  //               <Text style={{ fontSize: "9px" }}>
  //                 (Ground floor), Airport road, Farmgate, Tejgaon, Dhaka-1215,
  //                 Bangladesh
  //               </Text>
  //               <Text style={{ fontSize: "9px" }}>
  //                 +8801886020709, +8801796020709, +880248116644
  //               </Text>
  //               <Text style={{ fontSize: "9px" }}>WWW.CARE-BOX.COM</Text>
  //             </View>
  //           </View>
  //         </View>
  //       </View>
  //     </Page>
  //   </Document>
  // );

  const Prints = (props) => {
    console.log(props.id.Book_Doctor1.Patient_Age);
    return (
      <div
        className="container"
        style={{
          marginTop: "1rem",
          marginLeft: "1rem",
          marginRight: "1rem",
          width: "550px",
        }}
      >
        {/* or 80vh */}
        <div style={{ minHeight: "710px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                height: "30px",
                width: "250px",
                // paddingLeft: "10px",
                paddingRight: "25px",
                backgroundColor: "#900C3F",
                color: "#fff",
                // fontSize: "15px",
                borderRadius: "7px",
                marginTop: "15px",
              }}
            >
              <h4
                style={{
                  // alignContent: "center",
                  alignItems: "center",
                  // alignSelf: "center",
                  textAlign: "center",
                  fontSize: "20px",
                  marginTop: "3px",
                }}
              >
                PRESCRIPTION
              </h4>
            </div>
            <img
              style={{ width: "130px", marginTop: "-15px" }}
              src={logo}
              alt="logo"
            />
          </div>

          <div>
            <p style={{ margin: "0", padding: "0", fontSize: "14px" }}>
              Prescribed &nbsp;By : {props.id.Doctor_Name}
            </p>
            <p style={{ margin: "0", padding: "0", fontSize: "14px" }}>
              Prescription ID : {props.id.Book_Doctor}
            </p>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              border: "1px solid black",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <p
              style={{
                margin: "0 2px 0 2px",
                padding: "3px",
                fontSize: "14px",
              }}
            >
              Name : {props.id.Patient_Name}
            </p>
            <p
              style={{
                margin: "0 2px 0 2px",
                padding: "3px",
                fontSize: "14px",
              }}
            >
              Age : {props.id.Book_Doctor1.Patient_Age}
            </p>
            <p
              style={{
                margin: "0 2px 0 2px",
                padding: "3px",
                fontSize: "14px",
              }}
            >
              Gender : {props.id.Book_Doctor1.Patient_Gender}
            </p>
            <p
              style={{
                margin: "0 2px 0 2px",
                padding: "3px",
                fontSize: "14px",
              }}
            >
              Date :{" "}
              {moment(props.id.Book_Doctor1.Appointment_Date).format(
                "MMM Do, h:mm a"
              )}
            </p>
          </div>

          <div style={{ overflow: "hidden" }}>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <div style={{ borderRight: "1px solid black", width: "50%" }}>
                <div style={{ marginBottom: "20px" }}>
                  <h6>Health Issue : </h6>
                  <p
                    style={{
                      fontSize: "12px",
                      wordSpacing: "5px",
                      lineHeight: "16px",
                      marginRight: "7px",
                    }}
                  >
                    {props.id.Book_Doctor1.Health_Issue}
                  </p>
                </div>
                <div style={{ marginBottom: "20px" }}>
                  <h6>Patient Vitals :</h6>
                  <p
                    style={{
                      fontSize: "12px",
                      wordSpacing: "5px",
                      lineHeight: "16px",
                      marginRight: "7px",
                    }}
                  >
                    {props.id.Patient_Vitals}
                  </p>
                </div>
                <div style={{ marginBottom: "20px" }}>
                  <h6>Investigations :</h6>
                  <p
                    style={{
                      fontSize: "12px",
                      wordSpacing: "5px",
                      lineHeight: "16px",
                      marginRight: "7px",
                    }}
                  >
                    {props.id.Investigations}
                  </p>
                </div>
              </div>
              <div style={{ width: "50%", marginLeft: "20px" }}>
                <div>
                  <h6>Treatments :</h6>
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "justify",
                      lineHeight: "16px",
                      marginRight: "5px",
                      letterSpacing: "0.9px",
                      // wordSpacing: "5px",
                    }}
                  >
                    {props.id.Prescribed_Medicines}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer
          style={{
            display: "flex",
            justifyContent: "space-between",
            // position: "fixed",
            marginTop: "20px",
            // marginBottom:"-20px",
          }}
        >
          <div style={{ width: "30%" }}>
            <img style={{ width: "200px" }} src={footerImg} alt="footerimage" />
          </div>
          <div style={{ width: "70%" }}>
            <p
              style={{
                margin: "0",
                padding: "0",
                textAlign: "right",
                fontSize: "12px",
                letterSpacing: "1px",
                color: "#900C3F",
              }}
            >
              Care-Box Limited
            </p>
            <p
              style={{
                margin: "0",
                padding: "0",
                textAlign: "right",
                letterSpacing: "1px",
                fontSize: "9px",
              }}
            >
              149/A, Monipuri para, Baitush Sharaf Complex
            </p>
            <p
              style={{
                margin: "0",
                padding: "0",
                textAlign: "right",
                letterSpacing: "0.7px",
                fontSize: "9px",
              }}
            >
              (Ground floor), Airport road, Farmgate, Tejgaon, Dhaka-1215,
              Bangladesh
            </p>
            <p
              style={{
                margin: "0",
                padding: "0",
                textAlign: "right",
                letterSpacing: "1px",
                fontSize: "9px",
              }}
            >
              +8801886020709, +8801796020709, +880248116644
            </p>
            <p
              style={{
                margin: "0",
                padding: "0",
                textAlign: "right",
                letterSpacing: "1px",
                fontSize: "9px",
              }}
            >
              WWW.CARE-BOX.COM
            </p>
          </div>
        </footer>
      </div>
    );
  };

  const handlePres = (id) => {
    let data = prescriptionDetail.filter((item) => item.Book_Doctor === id)[0];
    console.log(data);
    const string = renderToString(<Prints id={data}></Prints>);

    const pdf = new jsPDF("p", "pt", "a4");
    pdf.html(string).then(() => pdf.save("a4g.pdf"));
  };

  const [orderInfo, setOrderInfo] = useState("");
  console.log(orderInfo);
  const [orderLoading, setOrderLoading] = useState(false);

  const handleOrder = (id, Book_Doctor) => {
    console.log(id, Book_Doctor);
    setOrderLoading(true);
    let formData = new FormData();
    formData.append("Prescription_Details", id);
    formData.append("Book_Doctor", Book_Doctor);
    fetch(
      `https://api-care-box.click/api/v2/Online_Doctor_Booking/Order_With_Prescription_Post_view/`,
      {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.Success === "Congratulations your data posted.") {
          setOrderLoading(false);
          setOrderInfo(data.Success);
          openModal();

          setTimeout(() => {
            history.push("/patientProfile");
          }, 3000);

          // console.log("vitre dhuke jacche")
          // alert(
          //   "Order Placed Successfully. One of our executive will call you for further information."
          // );
          // return( <ReviewModal
          //   modalIsOpen={modalIsOpen}
          //   closeModal={closeModal}
          // ></ReviewModal>)
        }
        // else {
        //   console.log("vitre dhuke jacche")
        //   alert("Order is not placed!!!!");
        // }
      })
      .catch((error) => {
        setOrderLoading(false);
        console.log(error);
        // alert("Order is not placed!!!!");
        Swal.fire({
          position: "top",
          icon: "error",
          title: "Oops...",
          text: "Order is not placed!",
          showConfirmButton: false,
          timer: 2000,
        });
      });
  };

  const logoutHandler = () => {
    console.log("logout");
    dispatch(logout());
    history.push("/");
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      <div className="headerBox2">
        <Navbar>
          <Container>
            <Navbar.Brand>
              <img
                src={logo}
                onClick={() => history.push("/")}
                alt="logo"
                fluid
                className="headerBox2_logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text>
                <p style={{ fontWeight: "bold" }}>
                  স্বাগতম, {localStorage.getItem("name")}{" "}
                  <img
                    src={dropDownIcon}
                    alt="dropdown"
                    // style={{ height: "17px", marginTop: "-5px" }}
                    fluid
                    id="drowdownImg"
                    // id="demo-customized-button"
                    // aria-controls="demo-customized-menu"
                    // aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    // variant="contained"
                    // disableElevation
                    onClick={handleClick}
                  />
                  <StyledMenu
                    className="dropdown_container"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleClose} disableRipple>
                      {/* <EditIcon /> */}
                      <button
                        onClick={() => history.push("/patientProfile")}
                        className="menu_button"
                      >
                        প্রোফাইল
                      </button>
                    </MenuItem>

                    <MenuItem onClick={handleClose} disableRipple>
                      <button className="menu_button">
                        প্রাপ্ত সেবার ডাটা
                      </button>
                    </MenuItem>
                    <MenuItem onClick={handleClose} disableRipple>
                      <button onClick={logoutHandler} className="menu_button">
                        লগ আউট
                      </button>
                    </MenuItem>
                  </StyledMenu>
                </p>
              </Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

      <br></br>
      <br></br>

      <div className="doctor_portal_login">
        <h3>প্রাপ্ত সেবার বিবরণ</h3>
      </div>

      <br></br>
      <br></br>
      <br></br>

      <div className="d-flex justify-content-center">
        <div className="col-md-9 waiting_patient_text">
          <h6 style={{ textAlign: "left", fontWeight: "bold" }}>
            ডাক্তার সেবা ও ঔষদের বিবরণ
          </h6>
          <div className="patient_waitingList_card_container">
            <div className="patient_waitingList_card_content">
              <div className="col-12">
                <div className="d-flex justify-content-between align-items-center treatment_desc_header">
                  <div id="id" className="col-1">
                    <h6>আইডি</h6>
                  </div>
                  <div id="name" className="col-2">
                    <h6>ডাক্তারের নাম</h6>
                  </div>
                  <div id="remove" className="col-2">
                    <h6>সমস্যার বিবরণ</h6>
                  </div>
                  <div id="date" className="col-2">
                    <h6>নির্ণেয় রোগ</h6>
                  </div>
                  <div id="action" className="col-4">
                    <h6>সেবা এর বিবরণ</h6>
                  </div>
                </div>
              </div>

              {prescriptionDetail.length ? (
                <>
                  {prescriptionDetail.map((data) => (
                    <Accordion
                      style={{ borderRadius: "10px" }}
                      className="treatment_desc"
                    >
                      <AccordionSummary>
                        <div className="d-flex justify-content-between w-100">
                          <div className="col-1">
                            <h6>{data.Book_Doctor}</h6>
                          </div>
                          <div className="col-2">
                            <h6>{data.Doctor_Name}</h6>
                          </div>
                          <div className="col-2" id="remove">
                            <h6>রোগীর সমস্যার বিবরণ</h6>
                          </div>
                          <div className="col-2">
                            <h6>নির্ণেয় রোগ</h6>
                          </div>
                          <div className="col-4">
                            <div className="d-flex justify-content-around desc_button">
                              <h6>ঔষধ সমূহ</h6>
                              <img src={dropDownIcon} alt="" />
                            </div>
                          </div>
                        </div>
                      </AccordionSummary>

                      <AccordionDetails>
                        <div className="d-flex justify-content-center">
                          <div className="treatment_desc_detailshow">
                            <div className="col-12">
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="col-md-1 col-sm-2">
                                  <button
                                    value={data.Book_Doctor}
                                    onClick={() => handlePres(data.Book_Doctor)}
                                  >
                                    প্রেসক্রিপশন
                                  </button>

                                  {/* <button
                                    value={data.Book_Doctor}
                                    onClick={() => setDocData(data)}
                                    title="Load Prescription to Download"
                                  >
                                    প্রেসক্রিপশন
                                  </button>

                                  {docData ? (
                                    <BlobProvider document={pdf}>
                                      {({ blob, url, loading, error }) => {
                                        return (
                                          <div>
                                            <button
                                              title="Click Here to Download"
                                              onClick={() => {
                                                // setDocData(data);
                                                window.open(
                                                  URL.createObjectURL(blob)
                                                );
                                                // window.open(url);
                                              }}
                                            >
                                              ডাউনলোড
                                            </button>
                                          </div>
                                        );
                                      }}
                                    </BlobProvider>
                                  ) : (
                                    <></>
                                  )} */}

                                  {/* <PDFDownloadLink
                                    document={<PrescriptionPDF id={data} />}
                                    // document={<MyDocument />}
                                    // document={pdf}
                                    fileName="presc.pdf"
                                  >
                                    {({ blob, url, loading, error }) =>
                                      loading ? (
                                        <div
                                          class="spinner-border text-primary"
                                          role="status"
                                        >
                                          <span class="sr-only"></span>
                                        </div>
                                      ) : (
                                        <button
                                          value={data.Book_Doctor}
                                          onClick={() => {
                                            setDocData(data);
                                            // MyDocument(data.Book_Doctor, data.Doctor_Name, data.Patient_Name);
                                          }}
                                          // onClick={
                                          // handlePdf(data);
                                          // }
                                        >
                                          প্রেসক্রিপশন
                                        </button>
                                      )
                                    }
                                  </PDFDownloadLink> */}

                                  {/* <div className="abc"> */}
                                  {/* <PDFExport ref={container1} paperSize="A4">
                                      <div ref={container}>
                                        <p style={{background:"black", color:"white"}}>sadlk;fj</p>
                                        <p>sadlk;fj</p>
                                        <p>sadlk;fj</p>
                                        <div>sjkadfkl;s</div>
                                        <div>sjkadfkl;s</div>
                                        <div>sjkadfkl;s</div>
                                      </div>
                                    </PDFExport> */}
                                  {/* </div> */}

                                  {/* <button
                                    value={data.Book_Doctor}
                                    onClick={() => {
                                      handlePdf();
                                    }}
                                  >
                                    প্রেসক্রিপশন
                                  </button> */}
                                </div>
                                <div className="col-2">
                                  {orderLoading ? (
                                    <>
                                      {/* <OtpLoader></OtpLoader> */}
                                      {/* <div class="spinner-grow" role="status">
                                        <span class="sr-only"></span>
                                      </div> */}
                                      <div
                                        class="spinner-border text-primary"
                                        role="status"
                                      >
                                        <span class="sr-only"></span>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {orderInfo ? (
                                        <>
                                          <button disabled>অর্ডার করুন</button>
                                          <OrderSuccessModal
                                            modalIsOpen={modalIsOpen}
                                            closeModalForSuccess={closeModal}
                                          ></OrderSuccessModal>
                                        </>
                                      ) : (
                                        <>
                                          <button
                                            onClick={() =>
                                              handleOrder(
                                                data.id,
                                                data.Book_Doctor
                                              )
                                            }
                                          >
                                            অর্ডার করুন
                                          </button>
                                        </>
                                      )}
                                    </>
                                  )}

                                  {/* {orderInfo?(
                                     <ReviewModal
                                      modalIsOpen={modalIsOpen}
                                      closeModal={closeModal}
                                    ></ReviewModal>
                                  ):(<></>)} */}
                                </div>
                                <div className="col-2" id="remove">
                                  <div className="patient_problem_detailshow">
                                    <h6>{data.Patient_Vitals}</h6>
                                  </div>
                                </div>

                                <div className="col-2">
                                  <div className="patient_problem_detailshow">
                                    <h6>{data.Investigations}</h6>
                                  </div>
                                </div>

                                <div className="col-4">
                                  {/* <div className="prescription_textarea"> */}
                                  <div className="patient_problem_detailshow">
                                    <h6>{data.Prescribed_Medicines}</h6>
                                  </div>
                                  {/* <textarea
                                        style={{
                                          border: "1px solid white",
                                          borderRadius: "10px",
                                        }}
                                        // placeholder={data.Prescribed_Medicines}
                                        value={data.Prescribed_Medicines}
                                        name="prescription"
                                        id="prescription"
                                        cols="26"
                                        rows="2"
                                      ></textarea> */}
                                  {/* </div> */}
                                  {/* <div className="d-flex justify-content-end">
                                      <button>আপডেট</button>
                                      <button>সেভ করুন</button>
                                    </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* </div> */}
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </>
              ) : (
                <div className="waitingList_info_container">
                  <div className="col-12">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="col-12 mt-2">
                        <h3>আপাতত সেবা নেয়া হয় নাই. . .</h3>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <Footer></Footer>
      <ScrollButton />
    </div>
  );
};

export default PatientPortal;

import React, { useEffect } from "react";
import logo from "../../media/logo.png";
import Footer from "../Pages/Footer";
import fail1 from "../../media/Fail-01.png";
import cross from "../../media/x-button.png";
import "./payment.css";

const PaymentFailure = () => {
  useEffect(() => {
    let interval = null;
    interval = setInterval(function () {
      localStorage.removeItem("ssl");
      window.location.href = "/doctorRegistrationForm";
    }, 3000);
    return () => (interval ? clearInterval(interval) : null);
  }, []);
  return (
    <div>
      <img
        src={logo}
        alt="logo"
        className="loading_logo"
        style={{ height: "100px" }}
      />
      <br></br>
      <div className="schedule_container">
        <div className="success_doctor_left">
          <h1>Opps!</h1>
          <img src={fail1} style={{ width: "45%" }} />
        </div>
        <div className="success_doctor_right">
          <img src={cross} />
          <br></br>
          <h6>
            Your application is not completed.
            <br></br>
            Please try the same procedure again.
          </h6>
        </div>
      </div>
      <br></br>
      <Footer />
    </div>
  );
};

export default PaymentFailure;

import { useEffect } from "react";
import axios from "axios";
import React from "react";
import Loader from "../Pages/Loader";
import "./payment.css";
import logo from "../../media/logo.png";

const PaymentLoading = () => {
  useEffect(() => {
    if (localStorage.getItem("ssl_book") === "patient_applied") {
      axios
        .get(
          "https://api-care-box.click/api/v2/Online_Doctor_Booking/doctorBookingPaymentRequest/",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        )
        .then((res) => {
          // should be change in here
          console.log("url:", res.data.message);
          if (res.data.message) {
            if (window.confirm(res.data.message)) {
              window.location.href = "/profile";
            } else {
              window.location.href = "/profile";
            }
          } else {
            window.location.href = res.data;
          }
        })
        .catch((err) => console.log("an issue", err));
    }
  }, [localStorage.getItem("ssl_book")]);
  return (
    <div>
      <br />
      <img src={logo} alt="logo" className="bookingPaymentLoading_logo" />
      <br />
      <Loader />
      <h3 className="bookingPaymentLoading_message">
        Your booking is shifting into payment getway.
      </h3>
    </div>
  );
};

export default PaymentLoading;

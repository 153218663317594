// online doctor appoinment -> doctor list
export const ONLINE_DOCTOR_LIST_REQUEST = "ONLINE_DOCTOR_LIST_REQUEST";
export const ONLINE_DOCTOR_LIST_SUCCESS = "ONLINE_DOCTOR_LIST_SUCCESS";
export const ONLINE_DOCTOR_LIST_FAILURE = "ONLINE_DOCTOR_LIST_FAILURE";

// online doctor appoinment -> doctor detail
export const ONLINE_DOCTOR_DETAIL_REQUEST = "ONLINE_DOCTOR_DETAIL_REQUEST";
export const ONLINE_DOCTOR_DETAIL_SUCCESS = "ONLINE_DOCTOR_DETAIL_SUCCESS";
export const ONLINE_DOCTOR_DETAIL_FAILURE = "ONLINE_DOCTOR_DETAIL_FAILURE";

// online doctor appoinment -> category list
export const ONLINE_DOCTOR_CATEGORY_REQUEST = "ONLINE_DOCTOR_CATEGORY_REQUEST";
export const ONLINE_DOCTOR_CATEGORY_SUCCESS = "ONLINE_DOCTOR_CATEGORY_SUCCESS";
export const ONLINE_DOCTOR_CATEGORY_FAILURE = "ONLINE_DOCTOR_CATEGORY_FAILURE";

// online doctor appoinment -> doctor profile
export const ONLINE_DOCTOR_PROFILE_REQUEST = "ONLINE_DOCTOR_PROFILE_REQUEST";
export const ONLINE_DOCTOR_PROFILE_SUCCESS = "ONLINE_DOCTOR_PROFILE_SUCCESS";
export const ONLINE_DOCTOR_PROFILE_FAILURE = "ONLINE_DOCTOR_PROFILE_FAILURE";

// online doctor appoinment -> doctor career
export const ONLINE_DOCTOR_CAREER_POST_REQUEST =
  "ONLINE_DOCTOR_CAREER_POST_REQUEST";
export const ONLINE_DOCTOR_CAREER_POST_SUCCESS =
  "ONLINE_DOCTOR_CAREER_POST_SUCCESS";
export const ONLINE_DOCTOR_CAREER_POST_FAILURE =
  "ONLINE_DOCTOR_CAREER_POST_FAILURE";

// online doctor appoinment -> patient profile
export const ONLINE_DOCTOR_PATIENT_PROFILE_REQUEST =
  "ONLINE_DOCTOR_PATIENT_PROFILE_REQUEST";
export const ONLINE_DOCTOR_PATIENT_PROFILE_SUCCESS =
  "ONLINE_DOCTOR_PATIENT_PROFILE_SUCCESS";
export const ONLINE_DOCTOR_PATIENT_PROFILE_FAILURE =
  "ONLINE_DOCTOR_PATIENT_PROFILE_FAILURE";

// video chat ->
export const SET_MAIN_STREAM = "SET_MAIN_STREAM";
export const ADD_PARTICIPANT = "ADD_PARTICIPANT";
export const REMOVE_PARTICIPANT = "REMOVE_PARTICIPANT";
export const SET_USER = "SET_USER";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_PARTICIPANT = "UPDATE_PARTICIPANT";

// user login and registration
export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";

// doctor apply registration
export const DOCTOR_APPLY_REGISTRATION_REQUEST =
  "DOCTOR_APPLY_REGISTRATION_REQUEST";
export const DOCTOR_APPLY_REGISTRATION_SUCCESS =
  "DOCTOR_APPLY_REGISTRATION_SUCCESS";
export const DOCTOR_APPLY_REGISTRATION_FAIL = "DOCTOR_APPLY_REGISTRATION_FAIL";

// patient waiting list
export const PATIENT_WAITINGLIST_REQUEST = "PATIENT_WAITINGLIST_REQUEST";
export const PATIENT_WAITINGLIST_SUCCESS = "PATIENT_WAITINGLIST_SUCCESS";
export const PATIENT_WAITINGLIST_FAILURE = "PATIENT_WAITINGLIST_FAILURE";

// Post Doctor Review
export const DOCTOR_CREATE_REVIEW_REQUEST = "DOCTOR_CREATE_REVIEW_REQUEST";
export const DOCTOR_CREATE_REVIEW_SUCCESS = "DOCTOR_CREATE_REVIEW_SUCCESS";
export const DOCTOR_CREATE_REVIEW_FAILURE = "DOCTOR_CREATE_REVIEW_FAILURE";
export const DOCTOR_CREATE_REVIEW_RESET = "DOCTOR_CREATE_REVIEW_RESET";

// Get Doctor Review
export const DOCTOR_GET_REVIEW_REQUEST = "DOCTOR_GET_REVIEW_REQUEST";
export const DOCTOR_GET_REVIEW_SUCCESS = "DOCTOR_GET_REVIEW_SUCCESS";
export const DOCTOR_GET_REVIEW_FAILURE = "DOCTOR_GET_REVIEW_FAILURE";

// Post Doctor Appointment
export const DOCTOR_CREATE_APPOINTMENT_REQUEST = "DOCTOR_CREATE_APPOINTMENT_REQUEST";
export const DOCTOR_CREATE_APPOINTMENT_SUCCESS = "DOCTOR_CREATE_APPOINTMENT_SUCCESS";
export const DOCTOR_CREATE_APPOINTMENT_FAILURE = "DOCTOR_CREATE_APPOINTMENT_FAILURE";

// Patient Get Detail View
export const PATIENT_GET_USERVIEW_REQUEST = 'PATIENT_GET_USERVIEW_REQUEST'
export const PATIENT_GET_USERVIEW_SUCCESS = 'PATIENT_GET_USERVIEW_SUCCESS'
export const PATIENT_GET_USERVIEW_FAILURE = 'PATIENT_GET_USERVIEW_FAILURE'

// check is patient
export const CHECK_IS_PATIENT_REQUEST = 'CHECK_IS_PATIENT_REQUEST';
export const CHECK_IS_PATIENT_SUCCESS = 'CHECK_IS_PATIENT_SUCCESS';
export const CHECK_IS_PATIENT_FAILURE = 'CHECK_IS_PATIENT_FAILURE';
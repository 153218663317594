import MainScreen from "./MainScreen/MainScreen.component";
import firepadRef, { db } from "../server/firebase";
// import firepadRef, { db, userName } from "../server/firebase";
// import firepadRef, { db, usePrompt } from "../server/firebase";
// import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
// import {
//   setMainStream,
//   addParticipant,
//   setUser,
//   removeParticipant,
//   updateParticipant,
// } from "../store/actioncreator";

import {
  setMainStream,
  addParticipant,
  setUser,
  removeParticipant,
  updateParticipant,
  getPatientView,
} from "../redux/actions/OnlineAppoinmentDoctorAction";

import { connect } from "react-redux";
import { doctorDetailReducers } from "../redux/reducers/doctorDetailReducer";

const Main = (props) => {
  const userReducer = useSelector((state) => state.patientDetail);
  console.log("patient detail ***", userReducer);
  const dispatch = useDispatch();

  //   useEffect(async () => {
  //     // dispatch(SliderAction())
  //     await dispatch(getPatientView)
  // }, []);
  const patientName = localStorage.getItem("name");
  // const patientName = userReducer.Name;

  // var constraints = { audio: true, video: true };
  // var video = document.querySelector("video");

  // function successCallback(stream) {
  //   video.srcObject = stream;
  //   video.play();
  // }

  // function errorCallback(error) {
  //   console.log("navigator.getUserMedia error: ", error);
  // }

  // const getUserStream = async () => {
  //   const localStream = await navigator.mediaDevices
  //     .getUserMedia(constraints)
  //     .then(successCallback)
  //     .catch(errorCallback);

  //   return localStream;
  // };

  const getUserStream = async () => {
    navigator.getUserMedia =
      navigator.getUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.webkitGetUserMedia;
    const localStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    });

    return localStream;
  };

  useEffect(async () => {
    navigator.getUserMedia =
      navigator.getUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.webkitGetUserMedia;
    // const devices = await navigator.mediaDevices.enumerateDevices();

    // if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
    //   console.log("enumerateDevices() not supported.");
    //   return;
    // } else {
    //   const stream = await navigator.mediaDevices.getUserMedia({
    //     audio: true,
    //     video: true,
    //   });
    //   stream.getVideoTracks()[0].enabled = false;
    //   props.setMainStream(stream);
    //   console.log("working properly", stream.getVideoTracks()[0])
    // }

    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    });
    stream.getVideoTracks()[0].enabled = false;
    props.setMainStream(stream);
    // props.addParticipant(stream);
    // props.setUser(stream);
    // props.updateParticipant(stream);

    // const videoCameras = devices.filter((device) => device.kind === "videoinput");
    // console.log("Cameras:", videoCameras);

    // const microphones = devices.filter((device) => device.kind === "audioinput");
    // console.log("Microphones:", microphones);

    // const userName = prompt("What's your name?");

    connectedRef.on("value", (snap) => {
      if (snap.val()) {
        const defaultPreference = {
          audio: true,
          video: false,
          screen: false,
        };

        // const userName = prompt("What's ydddour name?");
        // const userName = "zihad";
        const userName = patientName;

        // const userName = "PatientsName";

        const userStatusRef = participantRef.push({
          userName,
          preferences: defaultPreference,
        });

        props.setUser({
          [userStatusRef.key]: { name: userName, ...defaultPreference },
        });
        userStatusRef.onDisconnect().remove();
      }
    });
  }, []);

  const connectedRef = db.database().ref(".info/connected");
  const participantRef = firepadRef.child("participants");

  const isUserSet = !!props.user;
  const isStreamSet = !!props.stream;

  useEffect(() => {
    if (isStreamSet && isUserSet) {
      participantRef.on("child_added", (snap) => {
        const preferenceUpdateEvent = participantRef
          .child(snap.key)
          .child("preferences");
        preferenceUpdateEvent.on("child_changed", (preferenceSnap) => {
          props.updateParticipant({
            [snap.key]: {
              [preferenceSnap.key]: preferenceSnap.val(),
            },
          });
        });
        const { userName: name, preferences = {} } = snap.val();
        props.addParticipant({
          [snap.key]: {
            name,
            ...preferences,
          },
        });
      });
      participantRef.on("child_removed", (snap) => {
        props.removeParticipant(snap.key);
      });
    }
  }, [isStreamSet, isUserSet]);

  // useEffect(async () => {
  //   const stream = await getUserStream();
  //   stream.getVideoTracks()[0].enabled = false;
  //   props.setMainStream(stream);

  //   connectedRef.on("value", (snap) => {
  //     if (snap.val()) {
  //       const defaultPreference = {
  //         audio: true,
  //         video: false,
  //         screen: false,
  //       };
  //       const userStatusRef = participantRef.push({
  //         usePrompt,
  //         preferences: defaultPreference,
  //       });
  //       props.setUser({
  //         [userStatusRef.key]: { name: usePrompt, ...defaultPreference },
  //       });
  //       userStatusRef.onDisconnect().remove();
  //     }
  //   });
  // }, []);

  // const connectedRef = db.database().ref(".info/connected");
  // const participantRef = firepadRef.child("participants");

  // const isUserSet = !!props.user;
  // const isStreamSet = !!props.stream;

  // useEffect(() => {
  //   if (isStreamSet && isUserSet) {
  //     participantRef.on("child_added", (snap) => {
  //       const preferenceUpdateEvent = participantRef
  //         .child(snap.key)
  //         .child("preferences");
  //       preferenceUpdateEvent.on("child_changed", (preferenceSnap) => {
  //         props.updateParticipant({
  //           [snap.key]: {
  //             [preferenceSnap.key]: preferenceSnap.val(),
  //           },
  //         });
  //       });
  //       const { usePrompt: name, preferences = {} } = snap.val();
  //       props.addParticipant({
  //         [snap.key]: {
  //           name,
  //           ...preferences,
  //         },
  //       });
  //     });
  //     participantRef.on("child_removed", (snap) => {
  //       props.removeParticipant(snap.key);
  //     });
  //   }
  // }, [isStreamSet, isUserSet]);

  return (
    <div>
      <MainScreen />
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log(state);
  return {
    stream: state.mainStream,
    user: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMainStream: (stream) => dispatch(setMainStream(stream)),
    addParticipant: (user) => dispatch(addParticipant(user)),
    setUser: (user) => dispatch(setUser(user)),
    removeParticipant: (userId) => dispatch(removeParticipant(userId)),
    updateParticipant: (user) => dispatch(updateParticipant(user)),
    // doctorDetail: (user) => dispatch(doctorDetailReducers(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);

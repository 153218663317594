import MainScreen from "./components/MainScreen/MainScreen.component";
import EachDoctor from "./components/Pages/EachDoctor";
import DoctorForm from "./components/Pages/DoctorForm";
import Countdown from "./components/Pages/CountdownTimer";
import Landing from "./components/Pages/Landing";
import firepadRef, { db, userName } from "./server/firebase";
import "./App.css";
import { useEffect } from "react";
import { connect, Provider } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Main from "./components/Main";
import Register from "./components/Login/Register";
import Login from "./components/Login/Login";
import MedicineDoctor from "./components/Doctors/MedicineDoctor";
import PediatricDoctor from "./components/Doctors/PediatricDoctor";
import PsychologyDoctor from "./components/Doctors/PsychologyDoctor";
import GynaeDoctor from "./components/Doctors/GynaeDoctor";
import DoctorRegForm from "./components/DoctorRegistration/DoctorRegForm";
import DoctorPortalHome from "./components/DoctorRegistration/DoctorPortalHome";
import PrivateRoute from "./components/Pages/PrivateRoute";
import DoctorLogin from "./components/DoctorPortal/DoctorLogin";
import PatientWaitingList from "./components/DoctorPortal/PatientWaitingList";
import TreatmentDescription from "./components/DoctorPortal/TreatmentDescription";
import PatientPortal from "./components/PatientPortal/PatientPortal";
import PaymentLoading from "./components/Payment/PaymentLoading";
import BookingPaymentLoading from "./components/Payment/BookingPaymentLoading";
import {
  getPatientViewReducer,
  userReducer,
} from "./redux/reducers/OnlineAppoinmentDoctorReducers";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { store2 } from "./index";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import PaymentSuccess from "./components/Payment/PaymentSuccess";
import PaymentFailure from "./components/Payment/PaymentFailure";
import PaymentCancelled from "./components/Payment/PaymentCancelled";
import BookingPaymentSuccess from "./components/Payment/BookingPaymentSuccess";
import BookingPaymentFailure from "./components/Payment/BookingPaymentFailure";
import BookingPaymentCancelled from "./components/Payment/BookingPaymentCancelled";
import PatientProfile from "./components/PatientPortal/PatientProfile";
import SearchDoctor from "./components/Doctors/SearchDoctor";
import pageNotFound from "./media/pageNotFound.jpg";
import PageNotFound from "./components/Pages/PageNotFound";
import AboutUs from "./components/FooterContent/AboutUs";
import PrivacyPolicy from "./components/FooterContent/PrivacyPolicy";
import ReturnPolicy from "./components/FooterContent/ReturnPolicy";
import TermsCondition from "./components/FooterContent/TermsCondition";
import VideoChat from "./components/VideoChat/VideoChat";

export const store = createStore(userReducer);

function App(props) {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/terms-condition">
            <TermsCondition></TermsCondition>
          </Route>
          <Route path="/return-policy">
            <ReturnPolicy></ReturnPolicy>
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicy></PrivacyPolicy>
          </Route>
          <Route path="/about-us">
            <AboutUs></AboutUs>
          </Route>
          <Route path="/search-doctor/:keyword">
            <SearchDoctor></SearchDoctor>
          </Route>
          <Route path="/bookingPaymentCancelled">
            <BookingPaymentCancelled></BookingPaymentCancelled>
          </Route>
          <Route path="/bookingPaymentFailure">
            <BookingPaymentFailure></BookingPaymentFailure>
          </Route>
          <Route path="/bookingPaymentSuccess">
            <BookingPaymentSuccess></BookingPaymentSuccess>
          </Route>
          <Route path="/bookingPaymentLoading">
            <BookingPaymentLoading></BookingPaymentLoading>
          </Route>
          <Route path="/paymentCancelled">
            <PaymentCancelled></PaymentCancelled>
          </Route>
          <Route path="/paymentFailure">
            <PaymentFailure></PaymentFailure>
          </Route>
          <Route path="/PaymentSuccess">
            <PaymentSuccess></PaymentSuccess>
          </Route>
          <Route path="/PaymentLoading">
            <PaymentLoading></PaymentLoading>
          </Route>
          <PrivateRoute path="/patientProfile">
            <PatientProfile></PatientProfile>
          </PrivateRoute>
          <PrivateRoute path="/patientPortal">
            <PatientPortal></PatientPortal>
          </PrivateRoute>
          <PrivateRoute path="/treatmentDescription">
            <TreatmentDescription></TreatmentDescription>
          </PrivateRoute>
          <PrivateRoute path="/patientWaitingList">
            <PatientWaitingList></PatientWaitingList>
          </PrivateRoute>
          {/* <PrivateRoute path="/doctorLogin">
            <DoctorLogin></DoctorLogin>
          </PrivateRoute> */}
          <Route path="/doctorPortalHome">
            <DoctorPortalHome></DoctorPortalHome>
          </Route>
          <PrivateRoute path="/doctorRegistrationForm">
            <DoctorRegForm></DoctorRegForm>
          </PrivateRoute>
          <Route path="/medicine">
            <MedicineDoctor></MedicineDoctor>
          </Route>
          <Route path="/Pediatrician">
            <PediatricDoctor></PediatricDoctor>
          </Route>
          <Route path="/psychology">
            <PsychologyDoctor></PsychologyDoctor>
          </Route>
          <Route path="/gynae">
            <GynaeDoctor></GynaeDoctor>
          </Route>
          <Route path="/online-doctor/:id">
            <EachDoctor></EachDoctor>
          </Route>
          <Route exact path="/">
            <Landing />
          </Route>
          <PrivateRoute path="/DoctorForm/:id">
            <DoctorForm />
          </PrivateRoute>
          <PrivateRoute path="/countdown">
            <Countdown />
          </PrivateRoute>
          <Route path="/register">
            <Register />
          </Route>
          <Route path="/login">
            <Login />
          </Route>

          <Route path="/videochat">
            <VideoChat></VideoChat>
          </Route>

          {/* <Provider store={store}>
            <PrivateRoute path="/videochat">
              <Main />
            </PrivateRoute>
          </Provider> */}

          {/* <Route path="*">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                fontFamily: "poppins",
              }}
            >
              <img src={pageNotFound} alt="" style={{ height: "500px" }} />
            </div>
          </Route> */}
          {/* <Route exact>
            <PageNotFound></PageNotFound>
          </Route> */}
        </Switch>
      </Router>
    </div>
  );
}
export default App;

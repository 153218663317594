import React, { useEffect, useState } from "react";
import { Navbar, Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import logo from "../../media/logo.png";
import footerImg from "../../media/docicon.png";
import dropDownIcon from "../../media/dropdown-icon.png";
import FadeIn from "react-fade-in";
import Footer from "../Pages/Footer";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";
import { jsPDF } from "jspdf";
import {
  renderToString,
  renderToStaticMarkup,
  renderToNodeStream,
} from "react-dom/server";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/userActions";
// import cross from "../../media/x-button.png"
import cross from "../../media/close.png";
import ScrollButton from "../ScrollButton/ScrollButton";
import "aos/dist/aos.css";
import Aos from "aos";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  PDFDownloadLink,
  LinearGradient,
  Font,
  pdf,
  BlobProvider,
} from "@react-pdf/renderer";
import font from "../../media/fonts/Poppins-Regular.ttf";

Font.register({
  family: "Poppins",
  src: font,
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    fontSize: 11,
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    lineHeight: 1,
    flexDirection: "column",
    minHeight: "100vh",
    fontFamily: "Poppins",
  },
  row: {
    flexDirection: "row",
    width: "100%",
  },
  column: {
    flexDirection: "column",
    width: "50%",
    paddingRight: 15,
    paddingLeft: 15,
  },
  footerLeft: {
    height: 90,
    width: 220,
    marginTop: 10,
    textAlign: "left",
  },
  footerColumnLeft: {
    flexDirection: "column",
    width: "30%",
    padding: 0,
    margin: 0,
  },
  footerColumnRight: {
    flexDirection: "column",
    width: "70%",
    padding: 0,
    marginTop: 30,
    lineHeight: 1.4,
  },
  header: {
    height: 30,
    paddingLeft: 25,
    paddingRight: 25,
    backgroundColor: "#900C3F",
    color: "#fff",
    fontSize: 24,
    borderRadius: 7,
    // marginTop: 15,
    alignContent: "center",
    alignItems: "center",
    alignSelf: "center",
    textAlign: "center",
  },
  logo: {
    // width: 100,
    height: 80,
    marginLeft: "auto",
    // marginRight: 10,
    marginTop: -10,
    alignContent: "center",
    alignItems: "center",
    alignSelf: "center",
    textAlign: "center",
    // flexDirection: "row",
    // justifyContent: "flex-end",
  },
  section: {
    // margin: 10,
    // padding: 10,
    fontSize: 13,
    // flexGrow: 1,
    lineHeight: 1.5,
  },
  box: {
    border: "2 solid black",
    flexDirection: "row",
    paddingTop: 5,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 10,
  },
  textUnderline: {
    fontSize: 13,
    textDecoration: "underline",
  },
  // footer: {
  //   minHeight: `calc(100vh - 200px)`,
  //   position: "fixed",
  //   bottom: 0,
  // },
});

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 100,
    backgroundColor: "#D2D2D2",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      paddingTop: "7px",
      paddingBottom: "0px",
    },
  },
}));

const accordionSummary = withStyles({
  root: {
    backgroundColor: "#21CFFF",
    borderBottom: "1px solid #12738E",
    marginBottom: -1,
    color: "#666666",
    borderRadius: "30px 0px 30px 0",
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const TreatmentDescription = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [detailshow, setDetailshow] = useState(true);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDetails = () => {
    setDetailshow(!detailshow);
  };
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);

  var [docData, setDocData] = useState({});
  console.log("docdata", docData);

  const doctorName = docData.Doctor_Name;
  const patientName = docData.Patient_Name;
  const investigations = docData.Investigations;
  const vitals = docData.Patient_Vitals;
  const treatment = docData.Prescribed_Medicines;
  const presId = docData.Book_Doctor;

  if (docData) {
    var pdf = (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={{ minHeight: "86vh" }}>
            <View style={styles.row} fixed>
              <View style={styles.header}>
                <Text style={{ paddingTop: "-2px" }}>PRESCRIPTION</Text>
              </View>
              <Image style={styles.logo} src={logo} />
            </View>
            <View style={styles.section} fixed>
              <Text>Prescribed By: {doctorName}</Text>
              <Text>Prescription ID: {presId}</Text>
            </View>

            <View style={{ marginTop: "10px" }} fixed></View>

            <View style={styles.box} fixed>
              <Text style={{ marginLeft: "5px", marginRight: "35px" }}>
                Patient Name: {patientName}
              </Text>
              <Text style={{ marginRight: "35px" }}>Age: 25</Text>
              <Text style={{ marginRight: "35px" }}>Gender: Female</Text>
              <Text style={{ marginRight: "5px" }}>Date: 28-04-2022</Text>
            </View>
            <View style={{ marginTop: "20px" }} fixed></View>

            <View style={{ overflow: "hidden" }}>
              <View style={styles.row}>
                <View style={styles.column}>
                  <View style={{ borderRight: "1px solid black" }}>
                    <Text style={styles.textUnderline}>Health Issue:</Text>
                    <Text
                      style={{
                        marginBottom: "10px",
                        padding: "10px",
                        lineHeight: 1.3,
                      }}
                    >
                      {vitals}
                    </Text>

                    <Text style={styles.textUnderline}>Patient Vitals:</Text>
                    <Text
                      style={{
                        marginBottom: "10px",
                        padding: "10px",
                        lineHeight: 1.3,
                      }}
                    >
                      {vitals}
                    </Text>

                    <Text style={styles.textUnderline}>Investigations:</Text>
                    <Text
                      style={{
                        marginBottom: "10px",
                        padding: "10px",
                        lineHeight: 1.3,
                      }}
                    >
                      {investigations}
                    </Text>
                  </View>
                </View>
                <View style={styles.column}>
                  <Text style={styles.textUnderline}>Treatments:</Text>
                  <Text
                    style={{
                      marginBottom: "10px",
                      padding: "10px",
                      lineHeight: 1.3,
                    }}
                  >
                    {treatment}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View fixed>
            <View style={styles.row}>
              <View style={styles.footerColumnLeft}>
                <Image style={styles.footerLeft} src={footerImg} />
              </View>
              <View style={styles.footerColumnRight}>
                <View style={{ textAlign: "right" }}>
                  <Text style={{ fontSize: "14px", color: "#cf155f" }}>
                    Care-Box Limited
                  </Text>
                  <Text style={{ fontSize: "9px" }}>
                    149/A, Monipuri para, Baitush Sharaf Complex
                  </Text>
                  <Text style={{ fontSize: "9px" }}>
                    (Ground floor), Airport road, Farmgate, Tejgaon, Dhaka-1215,
                    Bangladesh
                  </Text>
                  <Text style={{ fontSize: "9px" }}>
                    +8801886020709, +8801796020709, +880248116644
                  </Text>
                  <Text style={{ fontSize: "9px" }}>WWW.CARE-BOX.COM</Text>
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    );
  }

  const [prescriptionDetail, setPrescriptionDetail] = useState([]);
  console.log("pres Detail", prescriptionDetail);
  useEffect(() => {
    fetch(
      "https://api-care-box.click/api/v2/Online_Doctor_Booking/Prescription_Details/Doctor/",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => setPrescriptionDetail(data));
  }, []);

  const Prints = (props) => {
    console.log(props.id.Book_Doctor1.Patient_Age);
    return (
      <div
        className="container"
        style={{
          marginTop: "1rem",
          marginLeft: "1rem",
          marginRight: "1rem",
          width: "550px",
        }}
      >
        {/* or 80vh */}
        <div style={{ minHeight: "710px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                height: "30px",
                width: "250px",
                // paddingLeft: "10px",
                paddingRight: "25px",
                backgroundColor: "#900C3F",
                color: "#fff",
                // fontSize: "15px",
                borderRadius: "7px",
                marginTop: "15px",
              }}
            >
              <h4
                style={{
                  // alignContent: "center",
                  alignItems: "center",
                  // alignSelf: "center",
                  textAlign: "center",
                  fontSize: "20px",
                  marginTop: "3px",
                }}
              >
                PRESCRIPTION
              </h4>
            </div>
            <img
              style={{ width: "130px", marginTop: "-15px" }}
              src={logo}
              alt="logo"
            />
          </div>

          <div>
            <p style={{ margin: "0", padding: "0", fontSize: "14px" }}>
              Prescribed &nbsp;By : {props.id.Doctor_Name}
            </p>
            <p style={{ margin: "0", padding: "0", fontSize: "14px" }}>
              Prescription ID : {props.id.Book_Doctor}
            </p>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              border: "1px solid black",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <p
              style={{
                margin: "0 2px 0 2px",
                padding: "3px",
                fontSize: "14px",
              }}
            >
              Name : {props.id.Patient_Name}
            </p>
            <p
              style={{
                margin: "0 2px 0 2px",
                padding: "3px",
                fontSize: "14px",
              }}
            >
              Age : {props.id.Book_Doctor1.Patient_Age}
            </p>
            <p
              style={{
                margin: "0 2px 0 2px",
                padding: "3px",
                fontSize: "14px",
              }}
            >
              Gender : {props.id.Book_Doctor1.Patient_Gender}
            </p>
            <p
              style={{
                margin: "0 2px 0 2px",
                padding: "3px",
                fontSize: "14px",
              }}
            >
              Date :{" "}
              {moment(props.id.Book_Doctor1.Appointment_Date).format(
                "MMM Do, h:mm a"
              )}
            </p>
          </div>

          <div style={{ overflow: "hidden" }}>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <div style={{ borderRight: "1px solid black", width: "50%" }}>
                <div style={{ marginBottom: "20px" }}>
                  <h6>Health Issue : </h6>
                  <p
                    style={{
                      fontSize: "12px",
                      wordSpacing: "5px",
                      lineHeight: "16px",
                      marginRight: "7px",
                    }}
                  >
                    {props.id.Book_Doctor1.Health_Issue}
                  </p>
                </div>
                <div style={{ marginBottom: "20px" }}>
                  <h6>Patient Vitals :</h6>
                  <p
                    style={{
                      fontSize: "12px",
                      wordSpacing: "5px",
                      lineHeight: "16px",
                      marginRight: "7px",
                    }}
                  >
                    {props.id.Patient_Vitals}
                  </p>
                </div>
                <div style={{ marginBottom: "20px" }}>
                  <h6>Investigations :</h6>
                  <p
                    style={{
                      fontSize: "12px",
                      wordSpacing: "5px",
                      lineHeight: "16px",
                      marginRight: "7px",
                    }}
                  >
                    {props.id.Investigations}
                  </p>
                </div>
              </div>
              <div style={{ width: "50%", marginLeft: "20px" }}>
                <div>
                  <h6>Treatments :</h6>
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "justify",
                      lineHeight: "16px",
                      marginRight: "5px",
                      letterSpacing: "0.9px",
                      // wordSpacing: "5px",
                    }}
                  >
                    {props.id.Prescribed_Medicines}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer
          style={{
            display: "flex",
            justifyContent: "space-between",
            // position: "fixed",
            marginTop: "20px",
            // marginBottom:"-20px",
          }}
        >
          <div style={{ width: "30%" }}>
            <img style={{ width: "200px" }} src={footerImg} alt="footerimage" />
          </div>
          <div style={{ width: "70%" }}>
            <p
              style={{
                margin: "0",
                padding: "0",
                textAlign: "right",
                fontSize: "12px",
                letterSpacing: "1px",
                color: "#900C3F",
              }}
            >
              Care-Box Limited
            </p>
            <p
              style={{
                margin: "0",
                padding: "0",
                textAlign: "right",
                letterSpacing: "1px",
                fontSize: "9px",
              }}
            >
              149/A, Monipuri para, Baitush Sharaf Complex
            </p>
            <p
              style={{
                margin: "0",
                padding: "0",
                textAlign: "right",
                letterSpacing: "0.7px",
                fontSize: "9px",
              }}
            >
              (Ground floor), Airport road, Farmgate, Tejgaon, Dhaka-1215,
              Bangladesh
            </p>
            <p
              style={{
                margin: "0",
                padding: "0",
                textAlign: "right",
                letterSpacing: "1px",
                fontSize: "9px",
              }}
            >
              +8801886020709, +8801796020709, +880248116644
            </p>
            <p
              style={{
                margin: "0",
                padding: "0",
                textAlign: "right",
                letterSpacing: "1px",
                fontSize: "9px",
              }}
            >
              WWW.CARE-BOX.COM
            </p>
          </div>
        </footer>
      </div>
    );
  };

  const handlePres = (id) => {
    let data = prescriptionDetail.filter((item) => item.Book_Doctor === id)[0];
    console.log(data);
    const string = renderToString(<Prints id={data}></Prints>);

    const pdf = new jsPDF("p", "pt", "a4");
    pdf.html(string).then(() => pdf.save("a4g.pdf"));
  };

  // const handlePres = async (id) => {
  //   console.log(id);
  //   const string = renderToString(<Prints></Prints>);
  //   let data = prescriptionDetail.filter((item) => item.Book_Doctor === id)[0];
  //   console.log(data);
  //   const pdf = new jsPDF(
  //     "p",
  //     "pt"
  //     //   {
  //     //   orientation: "vertical",
  //     //   unit: "in",
  //     //   format: [8, 4],
  //     // }
  //   );
  //   let pageWidth = pdf.internal.pageSize.getWidth();
  //   pdf.setFont("poppins");
  //   pdf.setFontSize(16);
  //   pdf.setTextColor("green");

  //   // pdf.text("Patient Name: ", 230, 25);
  //   // pdf.text("Investigations: ", 20, 80);
  //   // pdf.text("Vitals: ", 20, 175);
  //   // pdf.text("Medicines: ", 20, 280);

  //   // pdf.text(data.Patient_Name, 330, 25);
  //   // pdf.text(data.Investigations, 135, 80);
  //   // pdf.text(data.Patient_Vitals, 135, 175);
  //   // pdf.text(data.Prescribed_Medicines, 135, 280);

  //   pdf.text(
  //     `Patient Name:- ${data.Patient_Name}`,
  //     pageWidth / 2,
  //     50,
  //     "center"
  //   );

  //   pdf.text("Investigations: ", pageWidth / 6.5, 100, "left");

  //   pdf.text(`${data.Investigations}`, pageWidth / 6.5, 120, "left");

  //   pdf.text("Vitals: ", pageWidth / 1.5, 100, "left");

  //   pdf.text(`${data.Patient_Vitals}`, pageWidth / 1.5, 120, "left");

  //   pdf.text("Medicines: ", pageWidth / 2.4, 250, "left");

  //   pdf.text(`${data.Prescribed_Medicines}`, pageWidth / 2.4, 270, "left");

  //   // pdf.text(string, pageWidth/2, 400, "center")

  //   var splitTitle = pdf.splitTextToSize(data.Patient_Name, 600);
  //   var splitTitle1 = pdf.splitTextToSize(data.Patient_Vitals, 600);
  //   var splitTitle2 = pdf.splitTextToSize(data.Investigations, 600);
  //   var splitTitle3 = pdf.splitTextToSize(data.Prescribed_Medicines, 600);
  //   // doc.text(15, 20, splitTitle);

  //   // pdf.text("Patient Name:- ", (pageWidth / 2)-70, 40, "center");
  //   // pdf.text("Vitals:- ", (pageWidth / 2)-70, 70, "center");
  //   // pdf.text("Investigations:- ", (pageWidth / 2)-70, 100, "center");
  //   // pdf.text("Medicines:- ", (pageWidth / 2)-70, 130, "center");
  //   // pdf.text(splitTitle, pageWidth / 2, 40, "center");
  //   // pdf.text(splitTitle1, pageWidth / 2, 70, "center");
  //   // pdf.text(splitTitle2, pageWidth / 2, 100, "center");
  //   // pdf.text(splitTitle3, pageWidth / 2, 130, "center");

  //   // pdf.text(string, pageWidth / 2, 20, "center");

  //   pdf.save("a4.pdf");
  // };

  const dispatch = useDispatch();

  const checkDoctor = useSelector((state) => state.userLogin);
  const { userInfo } = checkDoctor;
  console.log("isDoctor", userInfo.isDoctor);

  const logoutHandler = () => {
    console.log("logout");
    dispatch(logout());
    history.push("/");
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      {userInfo.isDoctor === true ? (
        <>
          <div className="headerBox2">
            <Navbar>
              <Container>
                <Navbar.Brand>
                  <img
                    src={logo}
                    onClick={() => history.push("/")}
                    alt="logo"
                    fluid
                    className="headerBox2_logo"
                    // style={{ height: "80px", cursor:"pointer" }}
                  />
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                  <Navbar.Text>
                    <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                      স্বাগতম, {localStorage.getItem("name")}
                      <img
                        src={dropDownIcon}
                        alt="dropdown"
                        style={{ height: "17px", marginTop: "-5px" }}
                        fluid
                        onClick={handleClick}
                      />
                      <StyledMenu
                        className="dropdown_container"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                      >
                        {/* <MenuItem onClick={handleClose} disableRipple>
                          <button className="menu_button">প্রোফাইল</button>
                        </MenuItem>
                        <MenuItem onClick={handleClose} disableRipple>
                          <button className="menu_button">ড্যাশবোর্ড</button>
                        </MenuItem> */}
                        <MenuItem onClick={handleClose} disableRipple>
                          <button
                            onClick={() => history.push("/patientWaitingList")}
                            className="menu_button"
                          >
                            অপেক্ষমান রোগী
                          </button>
                        </MenuItem>
                        <MenuItem onClick={handleClose} disableRipple>
                          <button className="menu_button">
                            প্রদত্ত সেবার ডাটা
                          </button>
                        </MenuItem>
                        <MenuItem onClick={handleClose} disableRipple>
                          <button
                            onClick={logoutHandler}
                            className="menu_button"
                          >
                            লগ আউট
                          </button>
                        </MenuItem>
                      </StyledMenu>
                    </p>
                  </Navbar.Text>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </div>

          <br></br>
          <br></br>

          <div className="doctor_portal_login">
            <h3>প্রদত্ত সেবার বিবরণ</h3>
          </div>

          <br></br>
          <br></br>
          <br></br>

          <div className="d-flex justify-content-center">
            <div className="col-md-9 waiting_patient_text">
              <h6 style={{ textAlign: "left", fontWeight: "bold" }}>
                ডাক্তার সেবা ও ঔষদের বিবরণ
              </h6>
              <div className="patient_waitingList_card_container">
                <div className="patient_waitingList_card_content">
                  <div className="col-12">
                    <div className="d-flex justify-content-between align-items-center treatment_desc_header2">
                      <div id="id2" className="col-1">
                        <h6>আইডি</h6>
                      </div>
                      <div id="name2" className="col-2">
                        <h6>নাম</h6>
                      </div>
                      <div id="remove" className="col-2">
                        <h6>সমস্যার বিবরণ</h6>
                      </div>
                      <div id="date2" className="col-2">
                        <h6>রোগ</h6>
                      </div>
                      <div id="action2" className="col-4">
                        <h6>সেবা এর বিবরণ</h6>
                      </div>
                    </div>
                  </div>

                  {prescriptionDetail.length ? (
                    <>
                      {prescriptionDetail.map((data) => (
                        <Accordion
                          style={{ borderRadius: "10px" }}
                          className="treatment_desc"
                          // data-aos="fade-up"
                        >
                          <AccordionSummary>
                            <div className="d-flex justify-content-between w-100 summary">
                              <div id="id3" className="col-1">
                                <h6>{data.Book_Doctor}</h6>
                              </div>
                              <div id="name3" className="col-2">
                                <h6>{data.Patient_Name}</h6>
                              </div>
                              <div id="remove" className="col-2">
                                <h6>রোগীর সমস্যার বিবরণ</h6>
                              </div>
                              <div id="date3" className="col-2">
                                <h6>নির্ণেয় রোগ</h6>
                              </div>
                              <div id="action3" className="col-4">
                                <div className="d-flex justify-content-around desc_button">
                                  <h6>ঔষধ সমূহ</h6>
                                  <img src={dropDownIcon} alt="" />
                                </div>
                              </div>
                            </div>
                          </AccordionSummary>

                          <AccordionDetails>
                            <div className="d-flex justify-content-center">
                              <div className="treatment_desc_detailshow">
                                <div className="col-md-12">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div className="col-md-1">
                                      {/* <button
                                        value={data.Book_Doctor}
                                        onClick={() => setDocData(data)}
                                        title="Load Prescription to Download"
                                      >
                                        প্রেসক্রিপশন
                                      </button> */}
                                    </div>
                                    <div className="col-md-2">
                                      <button
                                        title="Click here to download"
                                        value={data.Book_Doctor}
                                        onClick={() =>
                                          handlePres(data.Book_Doctor)
                                        }
                                      >
                                        প্রেসক্রিপশন
                                      </button>

                                      {/* {docData ? (
                                        <BlobProvider document={pdf}>
                                          {({ blob, url, loading, error }) => {
                                            return (
                                              <div>
                                                <button
                                                  title="Click here to Download"
                                                  onClick={() => {
                                                    setDocData(data);
                                                    window.open(
                                                      URL.createObjectURL(blob)
                                                    );
                                                    // window.open(url);
                                                  }}
                                                >
                                                  ডাউনলোড
                                                </button>
                                              </div>
                                            );
                                          }}
                                        </BlobProvider>
                                      ) : (
                                        <></>
                                      )} */}
                                    </div>
                                    <div id="remove" className="col-md-2">
                                      <div className="patient_problem_detailshow">
                                        <h6>{data.Patient_Vitals}</h6>
                                      </div>
                                    </div>

                                    <div className="col-md-2">
                                      <div className="patient_problem_detailshow">
                                        <h6>{data.Investigations}</h6>
                                      </div>
                                    </div>

                                    <div className="col-md-4">
                                      <div className="prescription_textarea">
                                        <h6>{data.Prescribed_Medicines}</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </>
                  ) : (
                    <div className="waitingList_info_container">
                      <div className="col-md-12">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="col-md-12 mt-2">
                            <h3>আপাতত সেবা দেয়া হয় নাই. . .</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <Footer></Footer>
          <ScrollButton />
        </>
      ) : (
        <>
          <Navbar>
            <Container>
              <Navbar.Brand>
                <img
                  src={logo}
                  onClick={() => history.push("/")}
                  alt="logo"
                  fluid
                  style={{ height: "80px", cursor: "pointer" }}
                />
              </Navbar.Brand>
              <Navbar.Toggle />
              <Navbar.Collapse className="justify-content-end">
                <Navbar.Text>
                  <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                    স্বাগতম, {localStorage.getItem("name")}
                    <img
                      src={dropDownIcon}
                      alt="dropdown"
                      style={{ height: "17px", marginTop: "-5px" }}
                      fluid
                      onClick={handleClick}
                    />
                    <StyledMenu
                      className="dropdown_container"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={handleClose} disableRipple>
                        <button onClick={logoutHandler} className="menu_button">
                          লগ আউট
                        </button>
                      </MenuItem>
                    </StyledMenu>
                  </p>
                </Navbar.Text>
              </Navbar.Collapse>
            </Container>
          </Navbar>

          <br />
          <br />
          <div style={{ marginTop: "50px" }}>
            <img
              src={cross}
              alt="cross"
              style={{ height: "100px", marginBottom: "20px" }}
            />
            <h1>
              আপনি নিবন্ধিত ডাক্তার না
              <br /> দয়া করে ডাক্তার হিসেবে{" "}
              <span
                style={{ color: "blue", cursor: "pointer" }}
                onClick={() => history.push("/doctorPortalHome")}
              >
                নিবন্ধন
              </span>{" "}
              করুন
            </h1>
          </div>
        </>
      )}
    </div>
  );
};

export default TreatmentDescription;

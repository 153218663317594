import React, { useEffect, useRef } from "react";
import "./Participants.css";
import { connect, useDispatch, useSelector } from "react-redux";
import { Participant } from "./Participant/Participant.component";
import { getPatientView } from "../../redux/actions/patientDetailAction";

const Participants = (props) => {
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getPatientView())
  // }, [])
  const state = useSelector((state) => state);
  // console.log((Object.values(state.currffentUser)[0]).name)

  const videoRef = useRef(null);
  let participantKey = Object.keys(props.participants);
  // console.log(participantKey)
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.srcObject = props.stream;
      videoRef.current.muted = true;
    }
  }, [props.currentUser, props.stream]);

  const currentUser = props.currentUser
    ? Object.values(props.currentUser)[0]
    : null;

  let gridCol =
    participantKey.length === 1 ? 1 : participantKey.length <= 4 ? 2 : 4;
  const gridColSize = participantKey.length <= 4 ? 1 : 2;
  let gridRowSize =
    participantKey.length <= 4
      ? participantKey.length
      : Math.ceil(participantKey.length / 2);

  const screenPresenter = participantKey.find((element) => {
    const currentParticipant = props.participants[element];
    return currentParticipant.screen;
  });

  if (screenPresenter) {
    gridCol = 1;
    gridRowSize = 2;
  }
  const participants = participantKey.map((element, index) => {
    const currentParticipant = props.participants[element];
    const isCurrentUser = currentParticipant.currentUser;
    if (isCurrentUser) {
      return null;
    }
    const pc = currentParticipant.peerConnection;
    const remoteStream = new MediaStream();
    let curentIndex = index;
    if (pc) {
      pc.ontrack = (event) => {
        event.streams[0].getTracks().forEach((track) => {
          remoteStream.addTrack(track);
        });
        const videElement = document.getElementById(
          `participantVideo${curentIndex}`
        );
        if (videElement) videElement.srcObject = remoteStream;
      };
    }

    return (
      <Participant
        key={curentIndex}
        currentParticipant={currentParticipant}
        curentIndex={curentIndex}
        hideVideo={screenPresenter && screenPresenter !== element}
        showAvatar={
          !currentParticipant.video &&
          !currentParticipant.screen &&
          currentParticipant.name
        }
      />
    );
  });
  return (
    <div>
      {
        localStorage.getItem("service") ? (
          <>
            {/* <div className="doctor_patient_title">
        <div>
          <h2>ডাঃ সালমান</h2>
          <p>মেডিসিন বিশেষজ্ঞ</p>
        </div>
        <div>
          <h2>সিফাত</h2>
          <p>রোগী</p>
        </div>
      </div> */}
      <br />
      <br />
      <div
        // style={{
        //   "--grid-size": gridCol,
        //   "--grid-col-size": gridColSize,
        //   "--grid-row-size": gridRowSize,
        // }}
        className={`participants`}
      >
        {participants}
        <Participant
          currentParticipant={currentUser}
          curentIndex={participantKey.length}
          hideVideo={screenPresenter && !currentUser.screen}
          videoRef={videoRef}
          showAvatar={currentUser && !currentUser.video && !currentUser.screen}
          currentUser={true}
        />
      </div>
          </>
        ) : (
          <>
            {/* <div className="doctor_patient_title">
        <div>
          <h2>ডাঃ সালমান</h2>
          <p>মেডিসিন বিশেষজ্ঞ</p>
        </div>
        <div>
          <h2>সিফাত</h2>
          <p>রোগী</p>
        </div>
      </div> */}
      <br />
      <br />
      <div
        style={{
          "--grid-size": gridCol,
          "--grid-col-size": gridColSize,
          "--grid-row-size": gridRowSize,
        }}
        className={`participants`}
      >
        {participants}
        <Participant
          currentParticipant={currentUser}
          curentIndex={participantKey.length}
          hideVideo={screenPresenter && !currentUser.screen}
          videoRef={videoRef}
          showAvatar={currentUser && !currentUser.video && !currentUser.screen}
          currentUser={true}
        />
      </div>
          </>
        )
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    participants: state.participants,
    currentUser: state.currentUser,
    stream: state.mainStream,
  };
};

export default connect(mapStateToProps)(Participants);

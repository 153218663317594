import React, { useEffect } from "react";
import { Container, Row, Col, Navbar, Form, Button } from "react-bootstrap";
import logo from "../../media/logo.png";
import doctorRegImage from "../../media/doctorRegImage.png";
import doctorRegImage2 from "../../media/doctorRegImage2.png";
import onlineDoctor1 from "../../media/online-doctor1.PNG";
import onlineDoctor2 from "../../media/online-doctor2.jpg";
import onlineDoctor3 from "../../media/online-doctor3.PNG";
import cover from "../../media/cover-doctor.png";
import Footer from "../Pages/Footer";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import "aos/dist/aos.css";
import Aos from "aos";

const DoctorPortalHome = () => {
  const history = useHistory();

  const doctorRegHandler = () => {
    history.push("/doctorRegistrationForm");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);
  return (
    <div>
      <div className="headerBox_doctor_portal_home">
        <div className="search_design">
          <div>
            <img
              onClick={() => history.push("/")}
              className="landingLogo1"
              src={logo}
              alt="logo"
              fluid
            />
          </div>
          <div></div>
        </div>
      </div>
      <br></br>

      <div className="doctor_home">
        <img
          className="doctor_home_img"
          src={doctorRegImage}
          alt="doctor reg img"
        ></img>
        <div className="doctor_reg_card">
          <div className="doctor_reg_card_container">
            <div className="doctor_reg_card_content">
              <img src={doctorRegImage2} alt="doctor reg img"></img>
              <p>
                অনলাইনে ডাক্তার সেবা দিতে আজই <br />
                যুক্ত হন কেয়ার-বক্সের সাথে।
              </p>
              <Button variant="warning" onClick={doctorRegHandler}>
                নিবন্ধন করুন
              </Button>
            </div>
          </div>
        </div>
      </div>

      <br></br>
      <br></br>
      <br></br>
      <div className="doctor_benefit">
        <h3>ডাক্তারের সুবিধা সমূহ</h3>
      </div>
      <br></br>
      <br></br>
      <br></br>

      <section className="d-flex justify-content-center doctor_reg_section">
        <div className="w-75 row">
          <div className="col-md-4" data-aos="fade-right">
            <div className="col-md-10">
              <div className="doctor_reg_card_container">
                <div className="doctor_reg_card_content">
                  <img
                    style={{
                      marginTop: "20px",
                      marginBottom: "8px",
                      borderRadius: "50%",
                    }}
                    src={onlineDoctor1}
                    alt="doctor register img"
                  ></img>
                  <p>
                    ঘরে বসে রোগীদের সেবা দিন এবং সহজেই আয় করুন। প্রতি রোগীর জন্য
                    ৮০ শতাংশ টাকা আয় এর সুযোগ।
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="col-md-10">
              <div className="doctor_reg_card_container">
                <div className="doctor_reg_card_content">
                  <img
                    style={{
                      marginTop: "20px",
                      marginBottom: "8px",
                      borderRadius: "50%",
                    }}
                    src={onlineDoctor2}
                    alt="doctor register img"
                  ></img>
                  <p>
                    রেজিস্ট্রেশন করুন মাত্র ১৫০ টাকা দিয়ে এবং প্রতি বছর রিনিউ
                    করতে পারবেন মাত্র ১০০ টাকায়।
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4" data-aos="fade-left">
            <div className="col-md-10">
              <div className="doctor_reg_card_container">
                <div className="doctor_reg_card_content">
                  <img
                    style={{
                      marginTop: "20px",
                      marginBottom: "8px",
                      borderRadius: "50%",
                    }}
                    src={onlineDoctor3}
                    alt="doctor register img"
                  ></img>
                  <p>
                    প্রতি বছর পাচ্ছেন সার্টিফিকেট অর্জন এর সুযোগ। সার্টিফিকেট এ
                    উল্লেখ করা থাকবে আপনি কয়জন রোগী কে সেবা দিয়েছেন।
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <br></br>
      <br></br>

      <div className="d-flex justify-content-center">
        <div className="col-md-10">
          <div className="row">
          <div className="col-md-4">
            <div className="col-md-10">
              <div className="doctor_reg_card_container">
                <div className="doctor_reg_card_content">
                  <img
                    style={{ marginTop: "20px", marginBottom: "8px" }}
                    src={doctorRegImage2}
                  ></img>
                  <p>
                    অনলাইনে ডাক্তার সেবা দিতে আজই <br />
                    যুক্ত হন কেয়ার-বক্সের সাথে।
                  </p>
                  <Button style={{ fontWeight: "bold" }} variant="warning">
                    রেজিস্টার করুন
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="col-md-10">
              <div className="doctor_reg_card_container">
                <div className="doctor_reg_card_content">
                  <img
                    style={{ marginTop: "20px", marginBottom: "8px" }}
                    src={doctorRegImage2}
                  ></img>
                  <p>
                    অনলাইনে ডাক্তার সেবা দিতে আজই <br />
                    যুক্ত হন কেয়ার-বক্সের সাথে।
                  </p>
                  <Button style={{ fontWeight: "bold" }} variant="warning">
                    রেজিস্টার করুন
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="col-md-10">
              <div className="doctor_reg_card_container">
                <div className="doctor_reg_card_content">
                  <img
                    style={{ marginTop: "20px", marginBottom: "8px" }}
                    src={doctorRegImage2}
                  ></img>
                  <p>
                    অনলাইনে ডাক্তার সেবা দিতে আজই <br />
                    যুক্ত হন কেয়ার-বক্সের সাথে।
                  </p>
                  <Button style={{ fontWeight: "bold" }} variant="warning">
                    রেজিস্টার করুন
                  </Button>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div> */}

      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <Footer></Footer>
    </div>
  );
};

export default DoctorPortalHome;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listDoctorAction } from "../../redux/actions/doctorActions";
import ParticularDoctorCard from "./ParticularDoctorCard";
import { Container, Row, Col, Navbar } from "react-bootstrap";
import logo from "../../media/logo.png";
import Footer from "../Pages/Footer";
import OtpLoader from "../Login/OtpLoader";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Search from "../Pages/Search";

const GynaeDoctor = () => {
  const dispatch = useDispatch();
  const doctorList = useSelector((state) => state.doctor);
  const { doctor } = doctorList;
  console.log("doctorList ****", doctor);

  useEffect(() => {
    dispatch(listDoctorAction);
  }, []);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const history = useHistory();
  const [keyword, setKeyword] = useState("");
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(3);

  useEffect(() => {
    SearchData();
  }, [keyword]);

  const handledown = () => {
    setData([]);
    setOffset(0);
  };

  const SearchData = () => {
    setLoading(true);
    if (keyword.length > 1) {
      console.log("outside axios keyword:", keyword);
      axios
        .get(
          `https://api-care-box.click/api/v2/live_doctor/search-doctor-by-department/?keyword=${keyword}&limit=${limit}&offset=${offset}`
        )
        .then((res) => {
          console.log("whole: ", res.data);
          console.log("offset: ", offset);
          console.log("limit: ", limit);
          const newdata = res.data.Doctors;
          const hasMoreapi = res.data.has_more;
          setHasMore(hasMoreapi);
          setLoading(false);
          setData([...data, ...newdata]);
          setOffset(offset + limit);
        })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        });
    }
    if (keyword.length < 1) {
      setData([]);
    }
  };

  const DivScroll = () => {
    if (error || loading || !hasMore)
      return console.log("i am error, loading, has not more");
    else {
      // call some loading method
      console.log("call from documentElement");
      SearchData();
    }
  };

  const SearchSubmit = (e) => {
    e.preventDefault();
    history.push(`/search-doctor/:keyword=${keyword}`);
  };

  // outside click
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setData([]);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <div>
      <div className="particularDoctor_navbar">
        <Search></Search>
      </div>
      <Container>
        <div className="d-flex justify-content-center particularDoctor_main">
          {doctor.data?.length ? (
            <div className="particularDoctor_main_container row">
              {doctor.data
                ?.filter(
                  (doctors) => doctors.Department === "Gynaecologist"
                  // (doctors) => doctors.Department === "স্ত্রীরোগ বিশেষজ্ঞ"
                )
                .map((eachDoctor) => {
                  return (
                    <ParticularDoctorCard
                      key={eachDoctor.id}
                      doctors={eachDoctor}
                    />
                  );
                })}
            </div>
          ) : (
            <OtpLoader></OtpLoader>
          )}
        </div>
      </Container>
      <br />
      <Footer />
    </div>
  );
};

export default GynaeDoctor;

import React, { useEffect } from "react";
import HeaderBox from "../Pages/HeaderBox";
import LandingDoctorTitle from "../Pages/LandingDoctorTitle";
import LandingDoctorList from "../Pages/LandingDoctorList";
import Footer from "../Pages/Footer";
import ScrollButton from "../ScrollButton/ScrollButton";
import "aos/dist/aos.css";
import Aos from "aos";

const Landing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);
  return (
    <div>
      <HeaderBox />
      <br />
      <LandingDoctorTitle />
      <br />
      <LandingDoctorList />
      <br />
      <Footer />
      <ScrollButton />
    </div>
  );
};

export default Landing;

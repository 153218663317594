import React, { useState } from "react";
import { Navbar, Container } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import logo from "../../media/logo.png";
import dropDownIcon from "../../media/dropdown-icon.png";
import Footer from "../Pages/Footer";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { getUserDetails, logout } from "../../redux/actions/userActions";
import "./profile.css";
import UserHistory from "./UserHistory";
import ScrollButton from "../ScrollButton/ScrollButton";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 100,
    backgroundColor: "#D2D2D2",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      paddingTop: "7px",
      paddingBottom: "0px",
    },
  },
}));

const PatientProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    dispatch(getUserDetails());
  }, []);

  const userDetails = useSelector((state) => state.userDetails);
  const { error, user } = userDetails;
  console.log("user Details * * *", user);
  console.log("errorrrr * * *", error);

  const locationset = useLocation();

  useEffect(() => {
    if (error === "Request failed with status code 401") {
      history.push("/login");
      localStorage.setItem("url", locationset.pathname);
      window.location.reload();
    } else {
    }
  }, [error]);

  useEffect(() => {
    dispatch(getUserDetails());
  }, []);

  if (user) {
    localStorage.setItem("name", user.Name);
    var phone = user.Phone;
  }

  const logoutHandler = () => {
    console.log("logout");
    dispatch(logout());
    history.push("/");
  };

  // const ws = new WebSocket("wss://api-care-box.click:8001/orderplaced/post_order/");

  // ws.onopen=()=>{
  //   console.log("opeeeen")
  // }

  // ws.onmessage=(e)=>{
  //   console.log(e.data)
  // }

  return (
    <div>
      <div className="page_wrap">
        <div className="headerBox2">
          <Navbar>
            <Container>
              <Navbar.Brand>
                <img
                  src={logo}
                  onClick={() => history.push("/")}
                  alt="logo"
                  fluid
                  // style={{ height: "80px", cursor: "pointer" }}
                  className="headerBox2_logo_patientProfile"
                />
              </Navbar.Brand>
              <Navbar.Toggle />
              <Navbar.Collapse className="justify-content-end">
                <Navbar.Text>
                  <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                    স্বাগতম, {localStorage.getItem("name")}{" "}
                    <img
                      src={dropDownIcon}
                      alt="dropdown"
                      style={{ height: "17px", marginTop: "-5px" }}
                      fluid
                      onClick={handleClick}
                    />
                    <StyledMenu
                      className="dropdown_container_patientProfile"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={handleClose} disableRipple>
                        <button className="menu_button_patientProfile">
                          প্রোফাইল
                        </button>
                      </MenuItem>
                      {/* <MenuItem onClick={handleClose} disableRipple>
                      <button className="menu_button_patientProfile">
                        ড্যাশবোর্ড
                      </button>
                    </MenuItem> */}
                      {/* <MenuItem onClick={handleClose} disableRipple>
                      <button
                        onClick={() => history.push("/patientWaitingList")}
                        className="menu_button_patientProfile"
                      >
                        অপেক্ষমান রোগী
                      </button>
                    </MenuItem> */}
                      <MenuItem onClick={handleClose} disableRipple>
                        <button
                          onClick={() => history.push("/patientPortal")}
                          className="menu_button_patientProfile"
                        >
                          প্রদত্ত সেবার ডাটা
                        </button>
                      </MenuItem>
                      <MenuItem onClick={handleClose} disableRipple>
                        <button
                          onClick={logoutHandler}
                          className="menu_button_patientProfile"
                        >
                          লগ আউট
                        </button>
                      </MenuItem>
                    </StyledMenu>
                  </p>
                </Navbar.Text>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>

        <br></br>
        <br></br>

        <div className="profile_page">
          <div></div>
          <form>
            <div className="profile_form">
              <h2 className="profile_status_title">প্রোফাইল</h2>
              <div className="profile_status">
                <div className="profile_status_flex">
                  <h4>নাম : </h4>
                  <h4>{localStorage.getItem("name")}</h4>
                </div>
              </div>
              <br></br>
              <div className="profile_status">
                <div className="profile_status_flex">
                  <h4>মোবাইল : </h4>
                  <h4>{phone}</h4>
                </div>
              </div>
              <br></br>
            </div>
          </form>
          <div>
            <h2 className="profile_order_title">ঔষুধের অর্ডার সমূহ</h2>
            <div>
              <UserHistory></UserHistory>
            </div>
          </div>
          <div></div>
        </div>
        <br></br>
      </div>
      <Footer />
      <ScrollButton />
    </div>
  );
};

export default PatientProfile;
